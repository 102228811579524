<script setup>
import Header from '@/components/creator/Header.vue'
import { useRoute } from 'vue-router'
import {
  TourDialog,
} from '@/components/creator'

const route = useRoute()
</script>

<template>
  <div class="main">
    <Header class="header" />
    <router-view class="view"></router-view>
    <div class="footer" id="footer"></div>

    <TourDialog
      v-if="route.query['show-tour'] == 1"
    />
  </div>
</template>

<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  flex-shrink: 0;
  margin-bottom: 40px;
}

.view {
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.footer {
  margin: -10px 0 24px;
}
</style>
