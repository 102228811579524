<script setup>
import { useRoute } from 'vue-router'
import creatorItemViews from '@/helpers/creatorItemViews'

const props = defineProps(['defaultView'])

const route = useRoute()

function isSelected (item) {
  return (
    route.query.view === item.queryParam ||
    (!route.query.view && props.defaultView?.queryParam === item.queryParam)
  )
}

defineExpose({ isSelected })
</script>

<template>
  <div class="view-mode-switcher-wrapper">
    <template v-for="(item, key) in creatorItemViews" :key="key">
      <v-btn
        class="btn"
        :class="[`icon-${item.icon}`, { selected: isSelected(item) }]"
        variant="outlined"
        :to="{ name: '', query: { ...route.query, view: item.queryParam } }"
        :icon="item.icon"
        :active="false"
      >
      </v-btn>
    </template>
  </div>
</template>

<style scoped lang="scss">
.btn {
  border-color: transparent !important;
  /* Remove as per W3-840 */
  /* display: inline-flex; */
  display: none;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
}

:deep(.v-btn__content) {
  color: #acc2d2 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon-mdi-view-grid :deep(.v-btn__content) {
  font-size: 14px !important;
}

.icon-mdi-view-list :deep(.v-btn__content) {
  font-size: 18.8px !important;
  i {
    margin-left: -10%;
  }
}

.selected :deep(.v-btn__content) {
  color: #00d7ff !important;
}
</style>
