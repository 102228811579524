<script setup>
import { ref } from 'vue'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import emitter from '@/helpers/eventBus.js'

emitter.$on('showUpgradeDialog', () => {
  dialog.value.show()
})

const dialog = ref(null)

const dialogButtons = ref([
  [
    DialogButtons.Cancel,
    {
      ...DialogButtons.Ok,
      title: 'Choose plan',
      onClick: () => { console.log('ok pressed') },
    },
  ],
])

</script>

<template>
  <Dialog
    title="Upgrade billing plan required"
    ref="dialog"
    :buttons="dialogButtons"
    class="export-dialog__wrapper"
  >
    <p class="desc">
      Upgrade plan
    </p>
  </Dialog>
</template>

<style lang="scss" scoped>

.export-dialog__wrapper > :deep(.wrapper) {
  min-width: 339px;
}

</style>
