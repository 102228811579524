<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  title: String,
  icon: String,
  buttons: [Array, Object],
  cardWidth: String,
  cardHeight: String,
})

const router = useRouter()

const buttonsArray = computed(() => {
  if (Array.isArray(props.buttons)) {
    return props.buttons
  }

  return [props.buttons]
})

const wrapperStyle = computed(
  () => `width:${props.cardWidth}; height:${props.cardHeight}`
)

function cardContentClick (buttonData) {
  if (buttonData.name) {
    router.push({
      name: buttonData.name,
      params: buttonData.params || {},
      query: buttonData.query || {},
    })
  }

  if (buttonData.click) {
    buttonData.click(buttonData)
  }

  emit('click', buttonData)
}

const emit = defineEmits(['click'])
</script>

<template>
  <div class="card-wrapper" :style="wrapperStyle">
    <template v-for="(buttonData, index) in buttonsArray" :key="index">
      <div
        class="card-content__wrapper"
        :class="{ 'card-content__wrapper--multi': buttonsArray.length > 1}"
        @click="cardContentClick(buttonData)"
      >
        <div class="card-icon"></div>
        <div class="card-title">{{ buttonData.title }}</div>
      </div>

      <div class="button-separator" v-if="index < buttonsArray.length - 1"></div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.card-wrapper {
  background: linear-gradient(
    158.84deg,
    rgba(255, 255, 255, 0.24) -52.29%,
    rgba(255, 255, 255, 0) 103.57%
  );
  filter: drop-shadow(-5.88385px 6.53761px 3.92256px rgba(0, 0, 0, 0.08));
  // backdrop-filter: blur(29.4192px);
  border-radius: 8.49889px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding-bottom: 8px;

  &:hover {
    outline: 2px solid #00d7ff;
  }
}

.card-content__wrapper {
  flex: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  flex-direction: column;
  cursor: pointer;
}

body:after {
 display: none;
 content: url('~@/images/logo-add-hovered.svg');
}

.card-icon {
  background: url('~@/images/logo-add-hovered.svg') no-repeat center center;
  display: inline-block;
  width: 66px;
  height: 66px;
}

.card-content__wrapper:hover .card-icon {
  background: url('~@/images/logo-add-hovered.svg') no-repeat center center;
}

.card-content__wrapper--multi .card-icon {
  background: url('~@/images/logo-add.svg') no-repeat center center;
  width: 35px;
  height: 35px;
  background-size: 35px, 35px !important;
}

.card-title {
  font: normal 12px 'Poppins';
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;
}

.card-content__wrapper:hover .card-title {
  color: #00d7ff;
}

.button-separator {
  border: 1px solid #000000;
}
</style>
