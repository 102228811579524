<script setup>
import { ref, computed } from 'vue'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { standardRules } from '@/helpers/validationRules'
import { TextInput } from '@/components/creator'

const dialog = ref(null)
const email = ref()
const formValid = ref(false)

const props = defineProps({
  title: String,
})

const dialogButtons = ref([
  [
    DialogButtons.Cancel,
    {
      ...DialogButtons.Ok,
      onClick: () => { save() },
      variant: computed(() => {
        return formValid.value ? 'primary' : 'primary disabled'
      }),
    },
  ],
])

const show = async (initEmail) => {
  email.value = initEmail

  const result = await dialog.value.show()
  if (result === true) {
    return {
      result: true,
      email: email.value,
    }
  } else {
    return {
      result: false,
    }
  }
}

async function save () {
  dialog.value.close(true)
}

defineExpose({ show })
</script>

<template>
  <Dialog
    :title="props.title || 'Enter email'"
    ref="dialog"
    :buttons="dialogButtons"
    class="dlg-email"
  >
    <v-form v-model="formValid" ref="form">
      <TextInput
        v-model="email"
        class="input-email"
        :rules="[standardRules.required, standardRules.email]"
        label="Email"
      />
    </v-form>

  </Dialog>
</template>

<style lang="scss" scoped>
.input-email {
  margin-top: 20px;
  margin-bottom: 30px;
}
</style>
