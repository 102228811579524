<script setup>
import { ref, computed, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { TextInput, Select } from '@/components/creator'
import { standardRules } from '@/helpers/validationRules'
import { ProfileRoles } from '@/helpers/api/models'

let editMode
let emailSrc
const email = ref('')
const role = ref('')
const fullName = ref('')
const formValid = ref(false)

const dialog = ref(null)
const form = ref(null)

// const emailExistsErrorText = ref('')
const emailExistsError = ref(false)
const emailNotFoundError = ref(false)

const rules = {
  emailExists: (value) => !emailExistsError.value || 'Email already added',
  emailNotFound: (value) => !emailNotFoundError.value || 'User not found',
}

watch(() => email.value, () => {
  emailExistsError.value = false
  emailNotFoundError.value = false
})

let accessList

const store = useStore()
const { useActions } = useVuex(null, store)
const { apiUsersGet } = useActions([
  'apiUsersGet',
])

const roles = []
for (const [key, title] of Object.entries(ProfileRoles)) {
  roles.push({ value: key, text: title })
}

const profileCreateButtons = ref([
  [
    {
      ...DialogButtons.Ok,
      variant: computed(() => {
        let result = 'primary'

        if (!formValid.value || !role.value) {
          result += ' disabled'
        }

        return result
      }),
    },
  ],
])

const show = async (_accessList, editEmail, editRole) => {
  emailSrc = editEmail
  email.value = editEmail
  role.value = editRole
  accessList = _accessList
  editMode = email.value || role.value

  const showPromise = dialog.value.show()
  await nextTick()

  if (editMode) {
    form.value.validate()
  }

  const result = await showPromise

  if (result === 'ok') {
    return { email: email.value, role: role.value, fullName: fullName.value }
  }

  return false
}

async function dialogOnClose (result) {
  if (result === 'ok') {
    const itemFound = accessList.find((x) => x.email.toLowerCase() === email.value.toLowerCase())

    if (itemFound && !(editMode && emailSrc.toLowerCase() === email.value.toLowerCase())) {
      emailExistsError.value = true
      form.value.validate()
      return false
    }

    let userApiResult
    try {
      userApiResult = (await apiUsersGet({ email: email.value }))
    } catch (e) {
    }

    if (!userApiResult.success) {
      emailNotFoundError.value = true
      form.value.validate()
      return false
    }

    fullName.value = userApiResult.content.full_name
  }
}

defineExpose({ show })
</script>

<template>
  <Dialog
    title="Provide access"
    ref="dialog"
    :buttons="profileCreateButtons"
    class="dlg-profile-provide-access"
    :onclose="dialogOnClose"
  >
    <v-form v-model="formValid" ref="form">
      <TextInput
        v-model="email"
        class="input-email"
        :rules="[standardRules.required, standardRules.email, rules.emailExists, rules.emailNotFound]"
        label="Email"
      />

      <Select
        v-model="role"
        :items="roles"
        class="input-role"
        placeHolder="Role"
      />

      <div class="notice" :style="role === 'Administrator' ? 'visibility: visible': 'visibility: hidden'">
        Administrator can create worlds, assign content to slots, etc...
        SuperUser has full access to all the profile content, worlds, AR, ...
      </div>
    </v-form>

  </Dialog>
</template>

<style lang="scss" scoped>
.dlg-select-profile :deep(.wrapper) {
  min-width: 339px;
}

.notice {
  font: 400 12px 'Inter';
  color: #909EAF;
  width: 325px;
  margin-top: 25px;
  margin-bottom: 26px;
}
</style>
