<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import Dialog from '@/components/common/dialogs/Dialog'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { apiCallWithState, getLoadingState } from '@/utils/common'
import { LoadingIndicator } from '@/components/creator'
import { processError } from '@/helpers/errors'
import ItemsTable from '@/components/ItemsTable.vue'

const state = ref(getLoadingState())

const dialog = ref(null)
const invoices = ref([])

const props = defineProps({
  title: String,
})

const store = useStore()
const { useActions } = useVuex(null, store)
const { apiInvoicesList } = useActions({
  apiInvoicesList: 'apiInvoicesList',
})

async function loadInvoices () {
  const result = []

  const apiResponse = await apiCallWithState(apiInvoicesList, null, state, 'isLoading')
  const raw = apiResponse.content

  for (const invoice of raw) {
    result.push({
      created_at: invoice.created_at,
      state: invoice.state,
      amount: `$${invoice.amount}`,
      id: invoice.id,
      link_pdf: invoice.download_url,
    })
  }

  return result
}

const show = async () => {
  try {
    const dialogPromise = dialog.value.show()

    invoices.value = await loadInvoices()

    await dialogPromise
  } catch (error) {
    processError(error)
    dialog.value.close(false)
  }
}

defineExpose({ show })
</script>

<template>
  <Dialog
    :title="props.title || 'Invoices'"
    ref="dialog"
    :buttons="[]"
    class="dlg-invoices"
  >
    <LoadingIndicator loading :state="state" />

    <ItemsTable
        :items="invoices"
        class="invoices-table"
        :showHeader="false"
        :columns="[
          {
            sortKey: 'created_at',
            title: 'Date',
            field: 'created_at',
            type: 'timestamp',
            class: 'col-created'
          },
          {
            sortKey: 'state',
            title: 'Status',
            field: 'state',
            class: 'col-status'
          },
          {
            sortKey: 'amount',
            title: 'amount',
            field: 'amount',
            class: 'col-amount'
          },
          {
            sortKey: '',
            title: '',
            type: 'link',
            options: { hrefField: 'link_pdf', text: 'View invoice', target: '_blank' },
          },
        ]"
      />
  </Dialog>
</template>

<style lang="scss" scoped>
.invoices-table {
  max-height: 562px;
  max-width: 510px;
  min-height: 200px;
}

.invoices-table :deep(.col-created) {
  width: 200px;
}

.invoices-table :deep(.col-status),
.invoices-table :deep(.col-amount) {
  width: 100px;
}
</style>
