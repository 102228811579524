<script setup>
import { ref } from 'vue'

const props = defineProps({
  maxWidth: {
    type: String,
    default: 'none',
  },
})

const hovered = ref(false)
const isVisible = ref(false)
let timer
let timerHovered
const left = ref(0)
const top = ref(0)
let timeout

function show (_left, _top, _timeout = 300) {
  timeout = _timeout

  if (timer) {
    clearTimeout(timer)
    timer = undefined
  }

  left.value = _left
  top.value = _top
  isVisible.value = true
}

function hide () {
  timer = setTimeout(() => {
    isVisible.value = false
  }, timeout)
}

function onHover (_hovered) {
  if (_hovered) {
    if (timerHovered) {
      clearTimeout(timerHovered)
      timerHovered = undefined
    }

    hovered.value = true
  } else {
    timerHovered = setTimeout(() => {
      hovered.value = false
    }, timeout)
  }
}

defineExpose({
  show,
  hide,
})
</script>

<template>
  <Teleport to="body">
    <div
      class="wrapper"
      v-if="isVisible || hovered"
      :style="{
        left: `${left}px`,
        top: `${top}px`,
        'max-width': props.maxWidth
      }"
      @mouseover="() => onHover(true)"
      @mouseleave="() => onHover(false)"
    >

      <div class="slot-wrapper">
        <slot></slot>
      </div>
      <div class="arrow"></div>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
.wrapper {
  position: absolute;
  transform: translateX(-50%) translateY(8px);
}

.slot-wrapper {
  background: #091520;
  border: 0.849287px solid #334656;
  border-radius: 6.7943px;
  padding: 8px 16px;

  font: 400 12px/15px'Inter';
  text-align: center;
  color: #ACC2D2;
}

.arrow {
  display: block;
  content: '';
  background: #091520;
  transform: rotate(45deg) translateX(-50%);
  width: 12px;
  height: 12px;
  position: absolute;
  left: 50%;
  top: -2px;
  border-left: 0.849287px solid #334656;
  border-top: 0.849287px solid #334656;
}
</style>
