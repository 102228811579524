<script setup>
import { ref, defineProps, defineExpose, onMounted } from 'vue'
import { loadStripe } from '@stripe/stripe-js'
import { getLoadingState } from '@/utils/common'

let stripe
let elements

const props = defineProps({
  clientSecret: {
    type: String,
  },
})

const state = ref(getLoadingState())

async function initStripeElements () {
  const appearance = {
    theme: 'night',

    variables: {

    },

    rules: {
      '.Input--invalid': {
        // color: '#00FF00',
      },
      '.Input': {
        // color: '#0000FF',
      },
    },
  }

  // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
  const clientSecret = props.clientSecret
  elements = stripe.elements({ clientSecret, appearance })
  // Create and mount the Payment Element
  const paymentElement = elements.create('payment')
  paymentElement.on('ready', () => {
    state.value.isLoading = false
    state.value.requestInProgress = false

    emit('ready')
  })
  paymentElement.on('loaderror', () => {
    state.value.isLoading = false
    state.value.requestInProgress = false

    emit('loaderror')
  })

  paymentElement.mount('#payment-element')
}

async function formSubmit () {
  const response = await stripe.confirmSetup({
    elements,
    confirmParams: {
      return_url: window.location.href,
    },
    redirect: 'if_required',
  })

  if (response.error) {
    throw Error('payment error : ' + response.error.message)
  }

  const stripeIntent = await getSetupIntentInLoop(10)

  if (stripeIntent?.status === 'succeeded') {
    return {
      result: true,
      paymentMethod: response.setupIntent.payment_method,
    }
  }

  throw Error('Setup intent failed')
}

async function getSetupIntentInLoop (retryCount) {
  for (let i = 0; i < retryCount; i++) {
    const { setupIntent } = await stripe.retrieveSetupIntent(props.clientSecret)
    if (setupIntent.status === 'succeeded' ||
        setupIntent.status === 'requires_payment_method') {
      return setupIntent
    }

    await new Promise(resolve => setTimeout(resolve, 1000))
  }
  return undefined
}

onMounted(async () => {
  state.value.requestInProgress = true
  state.value.isLoading = true
  stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)

  initStripeElements()
})

defineExpose({ formSubmit, state })
const emit = defineEmits(['ready', 'loaderror'])
</script>

<template>
  <div class="payment-method">
    <v-form
      id="payment-form"
      @submit.prevent="formSubmit"
    >
      <div id="payment-element">
          <!-- Elements will create form elements here -->
      </div>

      <div id="error-message">
          <!-- Display error message to your customers here -->
      </div>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
</style>
