<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { TextInput } from '@/components/creator'
import { standardRules } from '@/helpers/validationRules'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { Profile } from '@/helpers/api/models'
import { deepClone } from '@/utils/common'
import { notify } from '@kyvg/vue3-notification'
import { processError } from '@/helpers/errors'

const router = useRouter()

const profile = ref(deepClone(Profile))
const createProfileDialog = ref(null)
const createProfileFormValid = ref(null)

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const store = useStore()
const { useActions } = useVuex(null, store)
const { apiProfilesCreate, apiSwitchProfile } = useActions({
  apiProfilesCreate: 'apiProfilesCreate',
  apiSwitchProfile: 'apiSwitchProfile',
})

const profileCreateButtons = ref([
  [
    {
      ...DialogButtons.Cancel,
    },
  ],
  [
    {
      ...DialogButtons.CreatePrimary,
      variant: computed(() => {
        let result = 'primary'
        result += state.value.requestInProgress
          ? ' loading'
          : ''
        result += createProfileFormValid.value ? '' : ' disabled'

        return result
      }),
    },
  ],
])

const show = async () => {
  const result = await createProfileDialog.value.show()
  if (result === 'create') {
    try {
      state.value.requestInProgress = true

      const createResult = await apiProfilesCreate({ data: profile.value })
      await apiSwitchProfile({ profile: createResult.content })
      router.go()

      notify({
        type: 'success',
        title: 'Profile created',
      })
    } catch (e) {
      processError(e)
    } finally {
      state.value.requestInProgress = false
    }
  }
}

defineExpose({ show })
</script>

<template>
  <Dialog
    title="Create profile"
    ref="createProfileDialog"
    :buttons="profileCreateButtons"
    class="dlg-create-profile"
  >
    <v-form v-model="createProfileFormValid">
      <TextInput
        v-model="profile.name"
        label="Profile name"
        :rules="[standardRules.required]"
      />
    </v-form>
  </Dialog>
</template>

<style lang="scss" scoped>
.dlg-create-profile :deep(.wrapper) {
  min-width: 339px;
}
</style>
