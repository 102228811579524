<script setup>
import { ref, computed } from 'vue'
import { TextInput, RichTextEditor } from '@/components/creator'
import { deepClone } from '@/utils/common'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import Dialog from '@/components/common/dialogs/Dialog'

const dialog = ref(null)
const dialogTitle = computed(() => {
  return propertyKeySource.value ? 'Edit property' : 'Add property'
})

const propertyKeySource = ref('')
const property = ref({})

const dialogButtons = ref([
  [
    {
      ...DialogButtons.Ok,
      title: computed(() => propertyKeySource.value ? 'Update' : 'Add'),
      variant: computed(() => {
        let result = 'primary'

        if (!property.value.key || !property.value.value) {
          result += ' disabled'
        }

        return result
      }),
    },
  ],
])

async function show (_property = undefined) {
  if (_property) {
    propertyKeySource.value = _property.key
    property.value = deepClone(_property)
  } else {
    propertyKeySource.value = ''
    property.value = { key: '', value: '' }
  }

  const result = await dialog.value.show()

  return (result === 'ok' ? { key: propertyKeySource.value, property: property.value } : undefined)
}

defineExpose({ show })
</script>

<template>
  <Dialog
    :title="dialogTitle"
    ref="dialog"
    :buttons="dialogButtons"
    class="dlg-profile-provide-access"
    :onclose="cancel"
  >
    <div class="wrapper">
      <TextInput v-model="property.key" type="text" label="Name" />

      <RichTextEditor
        v-model="property.value"
        label="Value"
        max-height="60vh"
      />
    </div>
  </Dialog>
</template>

<style scoped lang="scss">
.wrapper {
  width: 374px;
  margin-bottom: 10px;
}

.g-dlg-close {
  z-index: 10;
}
</style>
