const entityTypes = {
  space: {
    name: 'space',
  },
  ar: {
    name: 'ar',
  },
}

export default entityTypes
