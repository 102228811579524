const UserRef = {
  id: null,
  email: null,
  full_name: null,
}

const ProfileRef = {
  id: null,
  name: null,
}

const CampaignRef = {
  id: null,
  name: null,
}

const ContentItemRef = {
  id: null,
  name: null,
  type: null,
}

const Entity = {
  id: null,
  created_at: null,
  updated_at: null,
  author: {
    ...UserRef,
  },
  deleted: null,
}

const ProfileEntity = {
  profile: {
    ...ProfileRef,
  },
}

const ProfileRoles = {
  OWNER: 'OWNER',
  MANAGER: 'MANAGER',
}

const ProfileAccessRecord = {
  user: {
    ...UserRef,
  },
  role: null,
}

const Profile = {
  ...Entity,

  name: null,
  acl: Array(0).fill({ ...ProfileAccessRecord }),
}

const UserStats = {
  first_login: null,
  first_space_created: null,
}

const User = {
  ...Entity,

  email: null,
  role: null,
  full_name: null,
  first_name: null,
  last_name: null,
  password: null,
  is_service_account: false,
  default_profile: { ...ProfileRef },
  last_profile: { ...ProfileRef },
  stats: { ...UserStats },
}

const MediaLink = {
  title: null,
  href: null,
  button: null,
  banner: null,
  use_instead_ar: null,
}

const Media = {
  glbmodel: null,
  usdzmodel: null,
  sound: null,
  poster: null,
  video: null,
  image: null,
  link: { ...MediaLink },
}

const ContentItemType = {
  AR: 'AR',
  AVATAR: 'AVATAR',
  GLB: 'GLB',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  SOUND: 'SOUND',
}

const ContentItem = {
  ...Entity,
  ...ProfileEntity,

  name: null,
  creator: null,
  description: null,
  link: null,
  type: ContentItemType.AR,
  media: { ...Media },
  assigned: [],
  thumbnail: null,
  price: null,
}

const SpaceTemplate = {
  ...Entity,
  ...ProfileEntity,

  name: null,
  glbmodel: null,
  glbmodel_mobile: null,
  hide_slots_on_mobile: null,
  thumbnail: null,
}

const SpaceTemplateRef = {
  id: null,
  name: null,
}

const Space = {
  ...Entity,
  ...ProfileEntity,

  name: null,
  description: null,
  template: { ...SpaceTemplateRef },
  preview: null,
  environment: {
    sound: null,
  },
  thumbnail: null,
  mobile_disable_avatar: null,
  voicechat_disabled: false,
  custom_nicknames_disabled: false,
  customLoadingBackground: null,
  max_users_per_room: null,
  nickname_dictionaries: [],
}

const SpaceRef = {
  id: null,
  name: null,
}

const SpaceAvatar = {
  ...Entity,
  space: SpaceRef,
  name: null,
  content_item: ContentItemRef,
}

const Loader = {
  enabled: false,
  background_image: String,
}

const Theme = {
  enabled: false,
  loader: { ...Loader },
}

const ViewerStyle = {
  logo: null,
  button: {
    background: null,
    color: null,
    text: null,
  },
}

const Campaign = {
  ...Entity,
  ...ProfileEntity,

  name: null,
  branding: { ...ViewerStyle },
}

const Timezone = {
  name: null,
  offset: {
    repr: null,
    seconds: null,
  },
}

const Schedule = {
  date_start: null,
  date_end: null,
}

const Content = {
  priority: null,
  content_item: { ...ContentItem },
  schedule: { ...Schedule },
}

const GoogleAnalytics = {
  page_views: null,
  unique_users: null,
}

const ProjectMetadataItem = {
  nft: null,
  key: null,
  value: null,
}

const Project = {
  ...Entity,
  ...ProfileEntity,

  schema_version: 2,

  name: null,
  description: null,
  timezone: null,
  campaign: { ...CampaignRef },
  content: Array(0).fill({ ...Content }),
  ga: { ...GoogleAnalytics },
  metadata: Array(0).fill({ ...ProjectMetadataItem }),
}

const RequestEdits = {
  file: null,
  content_id: null,
  description: null,
}

const UserRoles = {
  SUPERUSER: 'SuperUser',
  CONTENTCREATOR: 'ContentCreator',
  MANAGER: 'Manager',
  CREATOR: 'Creator',
}

const SupportTicketType = {
  ContentEdit: 'ContentEdit',
}

const SupportTicket = {
  ...Entity,
  ...ProfileEntity,

  ticket_type: null,

  name: null,
  description: null,

  campaign: { ...CampaignRef },
  content_item: { ...ContentItem },

  watchers: [],

  metadata: {},
}

const CustomDomain = {
  ...Entity,
  ...ProfileEntity,

  link: null,
  state: null,
  confirmation_dns_records: null,
  favicon: null,
}

export {
  User,
  ProfileRoles,
  ProfileAccessRecord,
  Profile,
  ProfileRef,
  MediaLink,
  Media,
  ContentItem,
  ContentItemType,
  Campaign,
  Project,
  Timezone,
  Schedule,
  Content,
  ViewerStyle,
  RequestEdits,
  UserRoles,
  GoogleAnalytics,
  ProjectMetadataItem,
  SupportTicketType,
  SupportTicket,
  SpaceTemplate,
  Space,
  SpaceRef,
  Loader,
  Theme,
  CustomDomain,
  ContentItemRef,
  SpaceAvatar,
}
