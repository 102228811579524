<script setup>
import { computed, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import SelectList from '@/components/creator/SelectList.vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { ProfileRef } from '@/helpers/api/models'
import { deepClone } from '@/utils/common'
import { processError } from '@/helpers/errors'

const selectedProfile = ref(null)
const selectProfileDialog = ref(null)
const items = ref([])

const router = useRouter()
const route = useRoute()

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const store = useStore()

const currentProfile = computed(() => store.state.auth.profile)
const currentUser = computed(() => store.state.auth.user)

const { useActions } = useVuex(null, store)
const { apiProfilesList, apiSwitchProfile } = useActions({
  apiProfilesList: 'apiProfilesList',
  apiSwitchProfile: 'apiSwitchProfile',
})

const profileCreateButtons = ref([
  [
    {
      ...DialogButtons.Cancel,
    },
  ],
  [
    {
      ...DialogButtons.SelectPrimary,
      variant: computed(() => {
        let result = 'primary'
        result += state.value.requestInProgress
          ? ' loading'
          : ''

        if (!selectedProfile.value || selectedProfile.value.value === currentProfile.value.id) {
          result += ' disabled'
        }

        return result
      }),
    },
  ],
])

const show = async () => {
  items.value = await getProfiles()
  const result = await selectProfileDialog.value.show()
  if (result === 'select') {
    try {
      state.value.requestInProgress = true

      const profileRef = deepClone(ProfileRef)
      profileRef.id = selectedProfile.value.value
      await apiSwitchProfile({ profile: profileRef })

      if (route.name === 'Worlds') {
        router.go()
      } else {
        router.push({ name: 'Home' })
      }
    } catch (e) {
      processError(e)
    } finally {
      state.value.requestInProgress = false
    }
  }
}

async function getProfiles () {
  function sortProfiles (profiles) {
    profiles.sort((a, b) => {
      if (b.value === currentUser.value.default_profile.id) {
        return 1
      }
      if (a.value === currentUser.value.default_profile.id) {
        return -1
      }
      if (a.title < b.title) {
        return -1
      }
      if (a.title > b.title) {
        return 1
      }
      return 0
    })
  }

  const result = []

  try {
    state.value.requestInProgress = true
    const rawList = (await apiProfilesList()).content

    for (const item of rawList) {
      const title = item.name + (item.id === currentProfile.value.id ? ' *' : '')
      result.push({ title, value: item.id })
    }

    sortProfiles(result)
  } catch (e) {
    processError(e)
  } finally {
    state.value.requestInProgress = false
  }

  return result
}

defineExpose({ show })
</script>

<template>
  <Dialog
    title="Select profile"
    ref="selectProfileDialog"
    :buttons="profileCreateButtons"
    class="dlg-select-profile"
  >
    <SelectList
      v-model="selectedProfile"
      :items="items"
      class="select-list-profiles"
    />

  </Dialog>
</template>

<style lang="scss" scoped>
.dlg-select-profile :deep(.wrapper) {
  min-width: 339px;
}

.select-list-profiles {
  height: 175px;
}
</style>
