<script setup>
import * as DialogButtons from './DialogButtons'
import { ref, nextTick, watch } from 'vue'
import Button from '@/components/creator/Button'

const props = defineProps({
  blurred: {
    type: Boolean,
    default: true,
  },
  autohide: {
    type: [Boolean, Array],
    default: true,
  },
  title: {
    type: String,
    default: null,
  },
  titleAlign: {
    type: String,
    default: 'center',
  },
  buttons: {
    type: Array,
    default: () => [DialogButtons.Close],
  },
  boundElement: {
    type: Object,
    default: null,
  },
  boundType: {
    type: String,
    default: 'left bottom',
  },
  boundOffsetX: {
    type: Number,
    default: 0,
  },
  boundOffsetY: {
    type: Number,
    default: 0,
  },
  style: {
    type: String,
    default: '',
  },
  class: {
    type: String,
    default: '',
  },
  onclose: {
    type: Function,
    default: null,
  },
  noPaddings: {
    type: Boolean,
    default: false,
  },
})

const overlay = ref(null)
const wrapper = ref(null)
const state = ref('hidden')
const buttons = ref(
  props.buttons?.length > 0 && Array.isArray(props.buttons[0])
    ? props.buttons
    : [props.buttons]
)

watch(
  () => props.buttons,
  (newButtons) => {
    buttons.value = newButtons?.length > 0 && Array.isArray(newButtons[0])
      ? newButtons
      : [newButtons]
  },
  { deep: true }
)

let promiseResolve = null

const offsetToBody = (elem) => {
  let offsetTop = 0
  let offsetLeft = 0
  const offsetWidth = elem.offsetWidth
  const offsetHeight = elem.offsetHeight

  while (elem !== document.body) {
    offsetTop += elem.offsetTop
    offsetLeft += elem.offsetLeft
    elem = elem.offsetParent
  }

  return {
    offsetTop,
    offsetLeft,
    offsetWidth,
    offsetHeight,
  }
}

const setRelativePosition = () => {
  const boundType = props.boundType
    .split(' ')
    .map((s) => s.trim().toLowerCase())

  const boundOffset = offsetToBody(props.boundElement)

  if (boundType.includes('left')) {
    overlay.value.style.left = `${
      boundOffset.offsetLeft + props.boundOffsetX
    }px`
  }

  if (boundType.includes('right')) {
    overlay.value.style.left = `${
      boundOffset.offsetLeft +
      boundOffset.offsetWidth -
      overlay.value.offsetWidth +
      props.boundOffsetX
    }px`
  }

  if (boundType.includes('bottom')) {
    overlay.value.style.top = `${
      boundOffset.offsetTop + boundOffset.offsetHeight + props.boundOffsetY
    }px`
  }

  if (boundType.includes('top')) {
    overlay.value.style.top = `${
      boundOffset.offsetTop - overlay.value.offsetHeight + props.boundOffsetY
    }px`
  }
}

const show = async () => {
  if (state.value === 'showing' || state.value === 'visible') return
  state.value = 'showing'

  await nextTick()

  overlay.value.addEventListener('transitionend', function showHandler () {
    overlay.value.removeEventListener('transitionend', showHandler)
    wrapper.value.focus()
  })
  overlay.value.style.display = 'flex'
  setTimeout(() => {
    state.value = 'visible'
    overlay.value.classList.add('visible')
    if (!props.blurred && props.boundElement) {
      setRelativePosition()
    }
  }, 10)

  return new Promise((resolve, reject) => {
    promiseResolve = resolve
  })
}

const focusClosestFocusableDialog = async () => {
  let parent = overlay.value.parentElement
  while (parent) {
    if (parent.classList.contains('ah-dialog') || parent === document.body) {
      parent.focus()
      return
    }
    parent = parent.parentElement
  }
}

const hide = async () => {
  overlay.value.addEventListener('transitionend', function hideHandler () {
    if (overlay.value) {
      overlay.value.removeEventListener('transitionend', hideHandler)
      overlay.value.style.display = 'none'
    }
    state.value = 'hidden'
  })
  overlay.value.classList.remove('visible')
  await focusClosestFocusableDialog()
}

const close = async (result = 'close') => {
  if (state.value === 'hiding' || state.value === 'hidden') return

  if (props.onclose) {
    const onCloseResult = await props.onclose(result)
    if (onCloseResult === false) {
      return
    }
  }

  state.value = 'hiding'

  promiseResolve(result)

  if (
    props.autohide === true ||
    (Array.isArray(props.autohide) && props.autohide.includes(result))
  ) {
    await hide()
  }
}

const overlayClicked = () => {
  close()
}

const buttonClicked = async (button) => {
  if (button.onClick) {
    await button.onClick()
  } else {
    close(button.event)
  }
}

const focusOut = (event) => {
  if (!props.blurred) {
    let isFocusInside = false
    let targetElement = event.relatedTarget
    while (targetElement) {
      if (targetElement === overlay.value) {
        isFocusInside = true
        break
      }
      targetElement = targetElement.parentElement
    }
    if (!isFocusInside) close()
  }
}

const focusIn = (event) => {
}

defineExpose({ show, hide, close })
</script>

<template>
  <div
    class="overlay"
    ref="overlay"
    :class="props.class + (props.blurred ? ' blurred' : '')"
    style="display: none"
    @click.stop="overlayClicked"
    v-if="state === 'showing' || state === 'visible'"
  >
    <div
      ref="wrapper"
      class="ah-dialog wrapper"
      :class="{ 'with-paddings': !props.noPaddings }"
      @click.stop=""
      @focusout.stop="focusOut"
      @focusin.stop="focusIn"
      :style="props.style"
      tabindex="-1"
    >
      <div v-if="props.title" class="title" :class="`title--${props.titleAlign}`">{{ props.title }}</div>

      <div class="btn-close" :class="{ 'btn-close--no-title': !props.title }" @click="close()">
        <v-icon>mdi-close</v-icon>
      </div>

      <div class="content"><slot /></div>

      <div class="controls" v-if="buttons?.some((group) => group.length > 0)">
        <div
          class="control-group"
          :class="buttons.length < 2 && 'only-child'"
          v-for="group in buttons"
          v-bind:key="buttons.indexOf(group)"
        >
          <Button
            v-for="button in group"
            v-bind:key="button"
            @click="buttonClicked(button)"
            v-bind="
              button.variant
                ?.split(' ')
                .reduce((a, v) => ({ ...a, [v]: true }), {})
            "
            small
          >
            {{ button.title }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.overlay {
  position: fixed;

  font-family: 'Poppins';

  opacity: 0;
  transition: opacity 0.2s linear;

  &.visible {
    opacity: 1;
  }

  &.blurred {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    align-items: center;
    justify-content: center;

    background: rgba(black, 0.9);
    z-index: 10;

    backdrop-filter: blur(4px);
    background: #09152080;
  }

  & > .wrapper {
    display: flex;
    position: relative;
    flex-direction: column;

    background: #091520;
    border: 1px solid #334656;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;

    &:focus {
      outline: none;
    }

    &.with-paddings {
      padding: 23px 25px;
    }

    & > .btn-close {
      position: absolute;
      right: 17px;
      top: 15px;
      cursor: pointer;
    }

    & > .btn-close--no-title {
      top: 4px;
      right: 4px;
    }

    & > .title {
      font: normal 20px 'Poppins';
      text-align: center;
      color: #ffffff;
      margin: 14px 0;
    }

    & > .title--left {
      text-align: left;
      margin: 2px 0 20px;
    }

    & > .content {
      width: 100%;
      flex-grow: 1;
      color: #acc2d2;
      display: flex;
      flex-direction: column;
      min-height: 0;
    }

    & > .controls {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 20px;
      gap: 20px;

      & > .control-group {
        display: flex;
        flex-direction: row;
        gap: 20px;

        flex: 1 1 0px;

        &:first-child {
          justify-content: flex-start;
        }
        &:last-child {
          justify-content: flex-end;
        }
        &.only-child {
          justify-content: space-evenly;
        }
      }
    }
  }
}
</style>
