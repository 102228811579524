// TODO composition API

import _get from 'lodash-es/get'

const initialSortOrder = 'asc'

export function getSortKeyComputed (defSortKey) {
  return {
    get () {
      return _get(this.$store.state.table[this.tableName], 'sortKey', defSortKey)
    },
    set (sortKey) {
      this.$store.commit('setTableProp', [this.tableName, 'sortKey', sortKey])
    },
  }
}

export const sortOrderComputed = {
  get () {
    return _get(this.$store.state.table[this.tableName], 'sortOrder', initialSortOrder)
  },
  set (sortOrder) {
    this.$store.commit('setTableProp', [this.tableName, 'sortOrder', sortOrder])
  },
}

export function handleSortClick (sortKey) {
  if (sortKey === this.sortKey) {
    if (this.sortOrder === 'desc') {
      this.sortOrder = 'asc'
    } else {
      this.sortOrder = 'desc'
    }
  } else {
    this.sortOrder = initialSortOrder
  }
  this.sortKey = sortKey
}

export function searchData (data, keys, search) {
  if (!search) return data
  search = search.toLowerCase()
  data = data.filter((item) => {
    for (const key of keys) {
      const prop = _get(item, key)
      if (prop && (prop.toLowerCase().indexOf(search) > -1)) return true
    }
    return false
  })
  return data
}
