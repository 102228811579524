export async function loadFonts () {
  const webFontLoader = await import(/* webpackChunkName: "webfontloader" */ 'webfontloader')

  webFontLoader.load({
    google: {
      families: [
        'Roboto:100,300,400,500,700,900',
        'Inter:100,200,300,400,500,600,700',
        'Poppins:100,200,300,400,500,600,700',
      ],
    },
  })
}
