import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'
import CreatorView from '@/views/CreatorView.vue'
import WorldsCreatorView from '@/views/creator/WorldsCreatorView.vue'
import WorldsNewCreatorView from '@/views/creator/WorldsNewCreatorView.vue'
import CollectiblesCreatorView from '@/views/creator/CollectiblesCreatorView.vue'
import StorageCreatorView from '@/views/creator/StorageCreatorView.vue'
import StorageNewCreatorView from '@/views/creator/StorageNewCreatorView.vue'
import ArList from '@/views/creator/ArList.vue'
import ArNew from '@/views/creator/ArNew.vue'
import ArItem from '@/views/creator/ArItem.vue'
import LoginView from '@/views/LoginView.vue'
import ErrorView from '@/views/ErrorView.vue'
import ForbiddenErrorView from '@/views/error/ForbiddenErrorView.vue'
import NotFoundErrorView from '@/views/error/NotFoundErrorView.vue'
// import { UserRoles } from '@/helpers/api/models'
import WorldsItem from '@/views/creator/WorldsItem.vue'
import StorageItem from '@/views/creator/StorageItem.vue'
import UsersAccount from '@/views/creator/UsersAccount.vue'
import UsersProfile from '@/views/creator/UsersProfile.vue'
import PlatformSettings from '@/views/creator/PlatformSettings.vue'
import UsersItem from '@/views/creator/UsersItem.vue'
import ComponentsDemo from '@/views/creator/ComponentsDemo.vue'
import ViewerBranding from '@/views/creator/ViewerBranding.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: to => ({ name: 'Worlds' }),
  },
  {
    path: '/creator',
    name: 'Creator',
    component: CreatorView,
    redirect: to => ({ name: 'Worlds' }),
    children: [
      {
        path: 'worlds',
        name: 'Worlds',
        component: WorldsCreatorView,
        meta: {
          title: 'Your worlds',
        },
      }, {
        path: 'worlds/new',
        name: 'WorldsNew',
        component: WorldsNewCreatorView,
        meta: {
          title: 'Create your new world',
        },
      }, {
        path: 'worlds/:id',
        name: 'WorldsItem',
        component: WorldsItem,
        meta: {
          title: 'World settings',
        },
      }, {
        path: 'collectibles',
        name: 'Collectibles',
        component: CollectiblesCreatorView,
        meta: {
          title: 'Your collectibles',
        },
      }, {
        path: 'ar',
        name: 'ArList',
        component: ArList,
        meta: {
          title: 'Your AR',
        },
      }, {
        path: 'ar/new',
        name: 'ArNew',
        component: ArNew,
        meta: {
          title: 'Create your new augmented reality',
        },
      }, {
        path: 'ar/:id',
        name: 'ArItem',
        component: ArItem,
      }, {
        path: 'storage',
        name: 'Storage',
        component: StorageCreatorView,
        meta: {
          title: 'Your storage',
        },
      }, {
        path: 'storage/new',
        name: 'StorageNew',
        component: StorageNewCreatorView,
        meta: {
          title: 'New media item',
        },
      }, {
        path: 'storage/:id',
        name: 'StorageItem',
        component: StorageItem,
        meta: {
          title: 'Storage item',
        },
      }, {
        path: 'account',
        name: 'Account',
        component: UsersAccount,
        meta: {
          title: 'Account settings',
        },
      }, {
        path: 'profile',
        name: 'Profile',
        component: UsersProfile,
        meta: {
          title: 'Profile settings',
        },
      }, {
        path: 'admin',
        name: 'PlatformSettings',
        component: PlatformSettings,
        meta: {
          title: 'Platform settings',
        },
      }, {
        path: 'admin/users/:id',
        name: 'UsersItem',
        component: UsersItem,
        meta: {
          title: 'User edit',
        },
      }, {
        path: 'componentsdemo',
        name: 'ComponentsDemo',
        component: ComponentsDemo,
        meta: {
          title: 'Components demo',
        },
      }, {
        path: 'branding/:campaign_id',
        name: 'CreatorViewerBranding',
        component: ViewerBranding,
        meta: {
          title: 'Viewer branding',
        },
      },
    ],
  }, {
    path: '/login',
    meta: { protected: false },
    component: LoginView,
    children: [
      {
        path: '',
        name: 'Login',
        meta: {
          protected: false,
        },
        component: () => import(/* webpackChunkName: "Login" */ '@/views/login/LoginLoginView.vue'),
      }, {
        path: 'resetpassword',
        name: 'ResetPassword',
        meta: {
          protected: false,
        },
        component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/login/ResetPasswordLoginView.vue'),
      }, {
        path: 'resetpassword/:link',
        name: 'SetPassword',
        meta: {
          protected: false,
        },
        component: () => import(/* webpackChunkName: "SetPassword" */ '@/views/login/SetPasswordLoginView.vue'),
      }, {
        path: 'checkemail',
        name: 'ResetPasswordCheckEmail',
        meta: {
          protected: false,
        },
        component: () => import(/* webpackChunkName: "ResetPasswordCheckEmail" */ '@/views/login/ResetPasswordCheckEmailLoginView.vue'),
      },
      {
        path: 'signup',
        name: 'Signup',
        meta: {
          protected: false,
        },
        component: () => import(/* webpackChunkName: "Signup" */ '@/views/login/Signup.vue'),
      },
      {
        path: 'signup-complete',
        name: 'SignupComplete',
        meta: {
          protected: false,
        },
        component: () => import(/* webpackChunkName: "SignupComplete" */ '@/views/login/SignupComplete.vue'),
      },
    ],
  }, {
    path: '/:pathMatch(.*)*',
    component: ErrorView,
    meta: { protected: false },
    children: [
      {
        path: ':pathMatch(.*)*',
        name: 'NotFound',
        meta: {
          title: 'Page not found',
          protected: false,
        },
        component: NotFoundErrorView,
      },
      {
        path: '/403',
        name: 'Forbidden',
        meta: {
          title: 'Access denied',
          protected: false,
        },
        component: ForbiddenErrorView,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const isAuthenticated = () => store.state.auth?.authenticated && store.state.auth?.exp > Date.now() / 1000

const isRouteProtected = (to) => !to.meta || to.meta.protected !== false

const isRouteAllowed = (to) => !to.meta.allowedRoles || to.meta.allowedRoles.includes(store.state.auth.user.role)

router.beforeEach((to, from, next) => {
  if (isRouteProtected(to) && !isAuthenticated()) {
    next({ name: 'Login', query: { from: to.fullPath } })
  } else {
    switch (to.name) {
      case 'Login':
        isAuthenticated() ? next({ name: 'Home' }) : next()
        break
      default:
        isRouteAllowed(to) ? next() : next({ name: 'Forbidden' })
        break
    }
  }
})

router.afterEach((to) => {
  store.commit('setTitle', to.meta?.title)
})

export default router
