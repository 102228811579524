<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { TextInput, FileInput, FileDrop, Button, LoadingIndicator } from '@/components/creator'
import { Space, SpaceTemplate } from '@/helpers/api/models'
import { deepClone, getFileExtension, readFileToBase64 } from '@/utils/common'
import creatorItemTypes from '@/helpers/creatorItemTypes'
import { processError } from '@/helpers/errors'

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const store = useStore()
const { useActions } = useVuex(null, store)
const { apiSpacesCreate, apiSpaceTemplatesCreate } = useActions([
  'apiSpacesCreate',
  'apiSpaceTemplatesCreate',
])

const router = useRouter()

const space = ref(deepClone(Space))
const spaceTemplate = ref(deepClone(SpaceTemplate))
const fileUpload = ref()

const nameRule = (v) => !!v || 'Required'

const textInputName = ref(null)
const fileInputGlb = ref(null)
const isValid = ref(false)

const formValid = computed(() => isValid.value && fileInputGlb.value?.valid)

async function createWorld () {
  try {
    state.value.requestInProgress = true
    state.value.isUpdating = true

    let spaceCreateResult

    const extension = getFileExtension(fileInputGlb.value.original_filename.toLowerCase())

    if (extension === '.ahw') {
      const exportedSpace = await readFileToBase64(fileInputGlb.value.original_file)

      spaceCreateResult = (await apiSpacesCreate({
        fromExported: {
          name: space.value.name,
          data: exportedSpace,
        },
      })).content
    } else {
      spaceTemplate.value.glbmodel_original = fileUpload.value

      spaceTemplate.value.name = space.value.name

      const spaceTemplateCreateResult = (
        await apiSpaceTemplatesCreate({
          data: spaceTemplate.value,
        })
      ).content

      space.value.template.id = spaceTemplateCreateResult.id

      space.value.mobile_disable_avatar = false

      spaceCreateResult = (await apiSpacesCreate({
        space: space.value,
      })).content
    }

    router.push({
      name: 'WorldsItem',
      params: { id: spaceCreateResult.id },
    })
  } catch (error) {
    processError(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isUpdating = false
  }
}
</script>

<template>
  <div class="world-new-wrapper">
    <div class="g-title-wrapper">
      <h1 class="g-title">New World</h1>
    </div>

    <div class="g-content">
      <div class="g-form-upload-wrapper">
        <v-form v-model="isValid">
          <LoadingIndicator :state="state" all />

          <TextInput
            ref="textInputName"
            v-model="space.name"
            name="name"
            type="text"
            label="World name"
            autofocus
            :rules="[nameRule]"
          />

          <FileDrop
            ref="textFileDrop"
            :fileInput="fileInputGlb"
            fileSpecs="GLB/FBX/ZIP/AHW max 50MB"
          />

          <FileInput
            ref="fileInputGlb"
            v-model="fileUpload"
            :extensions="[...creatorItemTypes.glb.storageUploadExt, '.ahw']"
            placeholder="World file"
            required
            :validator="{}"
          />

          <div class="btn-create-wrapper">
            <Button
              primary
              class="btn-create"
              @click="createWorld"
              :disabled="!formValid || state.requestInProgress"
              :loading="state.isUpdating"
              >Create</Button
            >
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.file-drop-wrapper {
  margin-top: 38px;
}

.file-input-wrapper {
  margin-top: 28px;
}

.btn-create-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 72px;
}
</style>
