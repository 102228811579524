<script setup>
import { ref } from 'vue'

const props = defineProps({
  title: {
    type: String,
  },
  uppercase: {
    type: Boolean,
    default: false,
  },
})

const btn = ref(null)
const content = ref(null)

function triggerVisibility () {
  btn.value.classList.toggle('active')
  if (btn.value.classList.contains('active')) {
    content.value.style.maxHeight = content.value.scrollHeight + 'px'
    setTimeout(() => {
      content.value.style.maxHeight = 'none'
      content.value.scrollIntoView({ behavior: 'smooth' })
    }, 200)
  } else {
    content.value.style.maxHeight = null
  }
}
</script>

<template>
  <div class="collapsible__wrapper">
    <button
      type="button"
      class="btn"
      :class="props.uppercase ? 'uppercase' : ''"
      ref="btn"
      @click="triggerVisibility()"
    >
      {{ props.title }}
      <v-icon class="icon">mdi-chevron-down</v-icon>
    </button>
    <div class="content" ref="content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.collapsible__wrapper {
  &:not(:first-child) {
    margin-top: 24px;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.btn {
  height: 24px;
  font: 600 16px 'Inter';
  color: #909eaf;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  display: flex;
  align-content: center;

  &.uppercase {
    text-transform: uppercase;
  }
}

.icon {
  margin-left: auto;
  transition: all 0.2s ease-out;
}
.active .icon {
  transform: rotate(180deg);
}

.active,
.collapsible:hover {
  // background-color: rgba(black, 0.5);
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
</style>
