<script setup>
import { ref, computed } from 'vue'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import dateFormat from 'dateformat'

const dialog = ref(null)

const props = defineProps({
  newSubscription: Object,
  subscriptionPeriodEnd: Date,
  subscriptionPeriod: String,
  newSubscriptionPeriod: String,
})

const dialogButtons = ref([
  [
    DialogButtons.Cancel,
    {
      ...DialogButtons.Ok,
      variant: 'alert primary',
      title: computed(() =>
        props.newSubscription.price[props.newSubscriptionPeriod.toLowerCase()] ? 'Downgrade subscription' : 'Cancel subscription'
      ),
    },
  ],
])

const formattedPeriodEnd = computed(() => {
  const date = props.subscriptionPeriodEnd
  return dateFormat(date, 'mmm d, yyyy')
})

const show = async () => {
  return await dialog.value.show()
}

defineExpose({ show })
</script>

<template>
  <Dialog
    :title="`Downgrade to the ${props.newSubscription?.name} plan`"
    ref="dialog"
    :buttons="dialogButtons"
    class="dlg-downgrade"
  >
    <p class="text">
      Your {{ props.subscriptionPeriod }} subscription is paid until {{ formattedPeriodEnd }}. If you would like to proceed with canceling your subscription, please select "Cancel Subscription" below.
    </p>

    <p class="caution">
      Are you sure? After {{ formattedPeriodEnd }} you will not have the ability to work with white label options, and will be limited in Worlds and content storage. Download them within your account before {{ formattedPeriodEnd }}.
    </p>
  </Dialog>
</template>

<style lang="scss" scoped>

.dlg-downgrade > :deep(.ah-dialog) {
  width: 412px;
}

.text {
  font: 400 12px/18px 'Poppins';
  text-align: center;
  color: #ACC2D2;
}

.caution {
  font: 400 10px/15px 'Poppins';
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #F14953;
}
</style>
