<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { BillingPeriod } from '@/components/creator/billing_plans'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { getLoadingState, apiCallAllWithState, apiCallWithState } from '@/utils/common'
import { processError } from '@/helpers/errors'

const billingPeriod = ref()
const subscription = ref()
const userSubscriptionInfo = ref()

const state = ref(getLoadingState())

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiUserSubscriptionSet,
  apiUserSubscriptionInfoGet,
  apiBillingPlansList,
} = useActions({
  apiUserSubscriptionSet: 'apiUserSubscriptionSet',
  apiUserSubscriptionInfoGet: 'apiUserSubscriptionInfoGet',
  apiBillingPlansList: 'apiBillingPlansList',
})

async function submit () {
  try {
    await apiCallWithState(apiUserSubscriptionSet, { subscriptionId: subscription.value.id, billingPeriod: billingPeriod.value }, state, 'isUpdating')
    return true
  } catch (e) {
    processError(e)
  }
}

onMounted(async () => {
  const results = await apiCallAllWithState([
    apiBillingPlansList(),
    apiUserSubscriptionInfoGet(),
  ], state, 'isLoading')

  const billingPlans = results[0].content
  userSubscriptionInfo.value = results[1].content

  const productName = userSubscriptionInfo.value.billing_plan.name

  // TODO: change name to id
  subscription.value = billingPlans.find(item => item.name.toLowerCase() === productName.toLowerCase())

  billingPeriod.value = userSubscriptionInfo.value.billing_period === 'MONTH' ? 'MONTH' : 'YEAR'
})

defineExpose({ submit, state })
</script>

<template>
  <div class="billing-period-switcher" v-if="subscription && userSubscriptionInfo">
    <BillingPeriod
      :periods="{
        YEAR: { title: 'Annually', price: `$${Math.round(subscription.price.year / 12)}/month`, disabled: userSubscriptionInfo.billing_period === 'YEAR' },
        MONTH: { title: 'Monthly', price: `$${subscription.price.month}/month`, disabled: userSubscriptionInfo.billing_period === 'MONTH' }
      }"
      v-model="billingPeriod"
      class="billing-period"
    />

  </div>

</template>

<style scoped lang="scss">
.billing-period {
  display: table;
  margin: auto;
}

.billing-period :deep(.period-item__title) {
  width: 80px;
}

</style>
