<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { getLoadingState, apiCallWithState, timestampToDate } from '@/utils/common'
import dateFormat from 'dateformat'
import _ from 'lodash'
import { processError } from '@/helpers/errors'

import {
  Button,
  Chart,
  MetricIndex,
} from '@/components/creator'

const props = defineProps({
  entityId: {
    type: String,
    required: true,
  },
  entityType: {
    type: String,
    required: true,
  },
})

const state = ref(getLoadingState())

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiAnalyticsSpacesGet,
  apiAnalyticsProjectsGet,
} = useActions([
  'apiAnalyticsSpacesGet',
  'apiAnalyticsProjectsGet',
])

const analyticsPeriod = ref('daily')
const analytics = ref({})
const chartData = computed(() => {
  if (!analytics.value.graph_data) {
    return []
  }

  const data = analytics.value.graph_data.map((item) => {
    return {
      date: timestampToDate(item?.period),
      value: item?.views,
    }
  })

  return data
})

const chartDateFormat = computed(() => {
  switch (analyticsPeriod.value) {
    case 'daily':
      return 'mmm d'
    case 'monthly':
      return 'mmm yyyy'
  }

  return 'mmm d'
})

async function analyticsChangePeriod (period) {
  analyticsPeriod.value = period
  await loadAnalytics()
}

function analyticsDateToStr (timestamp) {
  const date = timestampToDate(timestamp)

  return dateFormat(date, 'mmm d, yyyy')
}

function getMetricChangePercent (metric) {
  try {
    return Math.round(analytics.value?.summary[metric]?.change / (analytics.value?.summary[metric]?.value - analytics.value?.summary[metric]?.change) * 1000) / 10
  } catch (error) {
    return null
  }
}

async function loadAnalytics () {
  const apiAnalyticsGet = props.entityType === 'space' ? apiAnalyticsSpacesGet : apiAnalyticsProjectsGet

  let interval = 'DAY'
  switch (analyticsPeriod.value) {
    case 'daily':
      interval = 'DAY'
      break
    case 'monthly':
      interval = 'MONTH'
      break
  }

  const requestData = { id: props.entityId, interval }
  try {
    const apiResult = await apiCallWithState(apiAnalyticsGet, requestData, state, 'isLoading')
    analytics.value = apiResult.content
  } catch (error) {
    processError(error)
  }
}

onMounted(async () => {
  await Promise.all([loadAnalytics()])
})

defineExpose({ state })
</script>

<template>
  <div class="analytics-panel__wrapper">
    <div class="g-section chart-margin">
      <div class="g-section-title">PERIOD</div>

      <div class="metrics-period__wrapper">
        <div class="metrics-period__buttons">
          <Button
            class=""
            @click="analyticsChangePeriod('daily')"
            secondary
            small
            :pressed="analyticsPeriod === 'daily'"
          >
            Daily
          </Button>

          <Button
            class=""
            @click="analyticsChangePeriod('monthly')"
            secondary
            small
            :pressed="analyticsPeriod === 'monthly'"
          >
            Monthly
          </Button>
        </div>

        <div class="metrics__period" v-if="!_.isEmpty(analytics)">{{ analyticsDateToStr(analytics.summary?.period?.date_from) }} - {{ analyticsDateToStr(analytics.summary?.period?.date_to) }}</div>
      </div>
    </div>

    <div class="metric-indexes__wrapper chart-margin">
      <MetricIndex
        title="Views"
        hint="Views — number of sessions for the World. If someone comes, leaves and then returns after more than 30 minutes, this is counted as a new session."
        :value="analytics?.summary?.views?.value"
        valueType="number"
        :changePercent="getMetricChangePercent('views')"
      />

      <MetricIndex
        title="Unique users"
        hint="The number of non-duplicated individual users who visit your website over the course of a specified time period."
        :value="analytics?.summary?.unique_users?.value"
        valueType="number"
        :changePercent="getMetricChangePercent('unique_users')"
      />

      <MetricIndex
        title="Engagement time"
        hint="Average time user spend in the World for a specified time period."
        :value="analytics?.summary?.engagement_time?.value"
        valueType="time"
        :changePercent="getMetricChangePercent('engagement_time')"
      />
    </div>

    <div class="g-section">
      <div class="g-section-title chart-margin">VIEWS</div>
      <Chart
        v-if="chartData"
        :data="chartData"
        :chartDateFormat="chartDateFormat"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.metrics__period {
  font: 400 12px 'Inter';
  color: #FFFFFF;
}

.metric-indexes__wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.metrics-period__wrapper {
  display: flex;
  flex-direction: row;
  gap: 23px;
  margin-top: 14px;
  align-items: center;
}

.metrics-period__buttons {
  display: flex;
  flex-direction: row;
  gap: 9px;
}

.chart__wrapper {
  height: 389px;
  margin-top: -48px;
}

.chart-margin {
  margin-left: 61px;
}
</style>
