<template>
  <v-window-item :value="value">
    <slot></slot>
  </v-window-item>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    value: String,
  },
}
</script>

<style scoped>
.v-window-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
