const creatorItemViews = {
  table: {
    title: 'Table',
    queryParam: 'table',
    icon: 'mdi-view-list',
  },
  cards: {
    title: 'Cards',
    queryParam: 'cards',
    icon: 'mdi-view-grid',
  },
}

export default creatorItemViews
