<script>
export default {
  name: 'Forbidden',
}
</script>

<template>
  <div class="main">
    <h1>403 Forbidden</h1>
    <div>
      You're attempting to visit a page you are not&nbsp;allowed&nbsp;to.
      <br />
      Try to start from
      <router-link class="link" :to="{ name: 'Home' }">Home</router-link>.
    </div>
  </div>
</template>

<style scoped lang="scss">
.link {
  color: #00d7ff;

  &:hover {
    color: #00d7ff;
  }
}
</style>
