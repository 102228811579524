<script setup>
import { onMounted, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { Button, TextInput, FileInput, Collapsible, LoadingIndicator } from '@/components/creator'
import { deepClone, copyToClipboard, apiCallAllWithState } from '@/utils/common'

import { CustomDomain } from '@/helpers/api/models'
import { processError } from '@/helpers/errors'
import { isAvailable } from '@/helpers/billingFeatures'
import { useRouter } from 'vue-router'

const router = useRouter()

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiCustomDomainsGetByLink,
  apiCustomDomainsList,
  apiCustomDomainsCreate,
  apiCustomDomainsUpdate,
} = useActions([
  'apiCustomDomainsGetByLink',
  'apiCustomDomainsList',
  'apiCustomDomainsCreate',
  'apiCustomDomainsUpdate',
])
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  linkType: {
    type: String,
    required: true,
  },
  linkId: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Object,
    default: ref(deepClone(CustomDomain)),
  },
})

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const rules = {
  required: (value) => !!value || 'Domain name is required',
  domain: (value) => {
    const pattern =
      /^([a-z0-9]{1,2}\.|[a-z0-9]{1,1}[a-z0-9-]{1,}[a-z0-9]{1,1}\.){1,}([a-z0-9]{2,}){1,1}$/
    return pattern.test(value) || 'Domain name is invalid'
  },
}

const customDomain = ref(deepClone(CustomDomain))
const customDomainEdit = ref(deepClone(CustomDomain))

const customDomainCount = ref(0)

const editCustomDomainDialog = ref(null)
const editCustomDomainFavicon = ref(null)
const editCustomDomainFormValid = ref(null)
const editCustomDomainDeleteDialog = ref(null)

const editDialogButtons = ref([
  [
    {
      ...DialogButtons.Delete,
      variant: computed(() => {
        let result = 'ghost'
        result +=
          state.value.requestInProgress && state.value.isDeleting
            ? ' loading'
            : ''
        result += customDomain.value?.id ? '' : ' disabled'

        return result
      }),
    },
  ],
  [DialogButtons.Close],
  [
    {
      ...DialogButtons.Save,
      variant: computed(() => {
        let result = 'primary'
        result +=
          state.value.requestInProgress && state.value.isUpdating
            ? ' loading'
            : ''
        result +=
          editCustomDomainFormValid.value &&
          editCustomDomainFavicon.value?.valid &&
          !editCustomDomainFavicon.value?.loading
            ? ''
            : ' disabled'

        return result
      }),
    },
  ],
])

watch(
  () => props.linkId,
  () => {
    refresh()
  }
)
watch(
  () => customDomain,
  () => {
    emit('update:modelValue', customDomain.value)
  }
)

const status = computed(() => {
  const status = {
    text: 'The domain is not set',
    variant: 'Not linked',
    class: 'error',
  }

  switch (customDomain.value?.state) {
    case 'TO_BE_CONFIRMED':
      if (customDomain.value?.confirmation_dns_records.length) {
        status.text = `Awaiting verification<br>'${customDomain.value?.id}'`
        status.variant = 'Awaiting verification'
        status.class = 'pending'
      } else {
        status.text = `Awaiting verification data<br>'${customDomain.value?.id}'`
        status.variant = 'Awaiting verification data'
        status.class = 'pending'
      }
      break
    case 'CONFIRMATION_FAILED':
      status.text = `Failed to link<br>'${customDomain.value?.id}'`
      status.variant = 'Link failed'
      status.class = 'error'
      break
    case 'CONFIRMED':
      status.text = `Awaiting linking<br>'${customDomain.value?.id}'`
      status.variant = 'Awaiting linking'
      status.class = 'pending'
      break
    case 'TO_BE_LINKED':
      status.text = `Awaiting linking<br>'${customDomain.value?.id}'`
      status.variant = 'Awaiting linking'
      status.class = 'pending'
      break
    case 'LINKED':
      status.text = `Configured with domain<br>'${customDomain.value?.id}'`
      status.variant = 'Domain successfully connected'
      status.class = 'connected'
      break
    case 'TO_BE_UNLINKED':
      status.text = `Removal in progress<br>'${customDomain.value?.id}'`
      status.variant = 'Pending removal'
      status.class = 'pending'
      break
  }

  return status
})

const dnsConfirmationRecords = computed(() => {
  return deepClone(customDomainEdit.value.confirmation_dns_records)
})

async function apiCustomDomainsGetByLinkNoException () {
  try {
    return (await apiCustomDomainsGetByLink({ linkType: props.linkType, linkId: props.linkId })).content
  } catch (error) {
    if (error?.statusCode === 404) {
      return deepClone(CustomDomain)
    } else {
      throw error
    }
  }
}

const refresh = async () => {
  if (!props.linkType || !props.linkId) {
    customDomain.value = deepClone(CustomDomain)
    return
  }

  try {
    state.value.isLoading = true
    state.value.requestInProgress = true

    const results = await apiCallAllWithState(
      [
        apiCustomDomainsGetByLinkNoException(),
        apiCustomDomainsList('LINKED'),
      ],
      state, 'isLoading')

    customDomain.value = results[0]
    customDomainCount.value = results[1].content.filter(item => item.state !== 'CONFIRMATION_FAILED' && item.state !== 'TO_BE_UNLINKED').length
    console.log(`customDomainCount ${customDomainCount.value}`)

    if (customDomain.value.state !== 'LINKED') {
      setTimeout(refresh, 30000)
    }
  } catch (error) {
    processError(error)
    customDomain.value = deepClone(CustomDomain)
  } finally {
    state.value.isLoading = false
    state.value.requestInProgress = false
  }
}

const editCustomDomainClick = async () => {
  customDomainEdit.value = deepClone(customDomain.value)
  customDomainEdit.value.link = {
    type: props.linkType,
    id: props.linkId,
  }
  editCustomDomainDialog.value.show()
}

const deleteCustomDomain = async () => {
  try {
    state.value.requestInProgress = true
    state.value.isDeleting = true

    customDomainEdit.value.state = 'TO_BE_UNLINKED'
    const apiResult = await apiCustomDomainsUpdate({
      data: customDomainEdit.value,
    })

    if (apiResult.success) {
      customDomain.value = apiResult.content
      editCustomDomainDialog.value.hide()
      refresh()
    }
  } catch (error) {
    processError(error)
  } finally {
    state.value.isDeleting = false
    state.value.requestInProgress = false
  }
}

const saveCustomDomain = async () => {
  try {
    state.value.requestInProgress = true
    state.value.isUpdating = true

    let apiResult

    if (customDomain.value.id) {
      apiResult = await apiCustomDomainsUpdate({
        data: customDomainEdit.value,
      })
    } else {
      apiResult = await apiCustomDomainsCreate({
        data: customDomainEdit.value,
      })
    }

    if (apiResult.success) {
      customDomain.value = apiResult.content
      editCustomDomainDialog.value.hide()
      refresh()
    }
  } catch (error) {
    processError(error)
  } finally {
    state.value.isUpdating = false
    state.value.requestInProgress = false
  }
}

const editCustomDomainDialogEvent = async (result) => {
  switch (result) {
    case 'delete':
      {
        const result = await editCustomDomainDeleteDialog.value.show()
        if (result === 'delete') {
          editCustomDomainDialog.value.hide()
          deleteCustomDomain()
        }
      }
      break
    case 'save':
      saveCustomDomain()
      break
  }
}

onMounted(() => {
  refresh()
})

defineExpose({
  refresh,
})
</script>

<template>
  <div class="custom-domain-brief__wrapper">
    <template  v-if="isAvailable('CUSTOM_DOMAINS_LIMIT', customDomainCount)">
      <div class="column">
        <div class="title">Custom domain</div>
        <div class="status" v-html="status.text"></div>
      </div>
      <div class="column">
        <Button primary small @click.stop="editCustomDomainClick">
          {{ customDomain?.id ? 'Edit domain' : 'Connect domain' }}
        </Button>
      </div>
    </template>
    <template v-else>
      <div class="column">
        <div class="title">Custom domain <v-icon size="18" class="icon-lock">mdi-lock-outline</v-icon></div>
      </div>
      <div class="column">
        <Button primary small @click="router.push({ name: 'Profile', query: { tab: 'Billing'}})">
          Upgrade
        </Button>
      </div>
    </template>

    <Dialog
      ref="editCustomDomainDialog"
      :autohide="['close']"
      title="Connect a domain"
      :buttons="editDialogButtons"
      class="custom-domain-edit-dialog"
      :onclose="editCustomDomainDialogEvent"
    >
      <div class="hint">
        <p>Enter the exact domain you want people to see</p>
        <p>when visiting the World.</p>
      </div>
      <v-form ref="editCustomDomainForm" v-model="editCustomDomainFormValid">
        <TextInput
          v-model="customDomainEdit.id"
          label="Domain name"
          name="domain_id"
          :rules="[rules.required, rules.domain]"
          :readonly="customDomain?.id"
        />
        <div class="status" v-html="status.variant" :class="status.class"></div>
        <FileInput
          ref="editCustomDomainFavicon"
          v-model="customDomainEdit.favicon"
          placeholder="Upload favicon"
          :extensions="['.png', '.ico']"
        />
        <div class="hint favicon">
          <p>
            Favicon is displayed in the browser before the page name in the tab.
          </p>
          <p>
            The favicon is acceptable only as .ico, the minimum size is 32x32px.
          </p>
        </div>
      </v-form>
      <div v-if="customDomain?.confirmation_dns_records?.length">
        <Collapsible title="Domain ownership verification">
          <div class="dns-records">
            <div class="title">
              To complete the setup of your custom domain, add the following
              records to your domain by visiting your DNS provider or registrar
            </div>
            <div
              v-for="dnsRecord in dnsConfirmationRecords"
              v-bind:key="dnsConfirmationRecords.indexOf(dnsRecord)"
              class="record"
            >
              <div class="type">{{ dnsRecord.type }}</div>
              <TextInput
                class="value"
                readonly
                v-model="dnsRecord.name"
                label="Name"
                append-icon="mdi-content-copy"
                @click:append.stop="copyToClipboard(dnsRecord.name)"
              />
              <TextInput
                class="value"
                readonly
                v-model="dnsRecord.value"
                label="Value"
                append-icon="mdi-content-copy"
                @click:append.stop="copyToClipboard(dnsRecord.value)"
              />
            </div>
          </div>
        </Collapsible>
        <div class="doc">
          <a href="https://docs.w3rlds.com/whitelabel/howto-dns" target="_blank">
            How to configure your DNS correctly
          </a>
        </div>
      </div>
    </Dialog>
    <Dialog
      ref="editCustomDomainDeleteDialog"
      :buttons="[DialogButtons.Cancel, DialogButtons.DeletePrimary]"
    >
      Are you sure you want to delete the custom domain?
    </Dialog>

    <LoadingIndicator loading :state="state" />
  </div>
</template>

<style scoped lang="scss">
.custom-domain-brief__wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;

  & > .column {
    display: flex;
    flex-direction: column;

    align-items: center;

    &:first-child {
      flex: 1;
      align-items: flex-start;
    }

    & > .title {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }

    & > .status {
      font-size: 12px;
      color: #acc2d2;
    }
  }
}

.custom-domain-edit-dialog > :deep(.wrapper) {
  min-width: 450px;
}

.custom-domain-edit-dialog {
  .hint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 40px;

    font-size: 12px;
    color: #acc2d2;

    &.favicon {
      align-items: flex-start;
      margin-top: -20px;
    }
  }

  .doc {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    > a {
      display: flex;
      font-size: 10px;
      color: #495f71;
    }
  }

  .status {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: -22px;

    font-size: 10px;

    &.connected {
      color: #2ad746;
    }
    &.pending {
      color: #d7922a;
    }
    &.error {
      color: #d7582a;
    }
  }
}

.dns-records {
  display: flex;
  flex-direction: column;

  font-size: 12px;

  max-width: 500px;

  & .title {
    margin: 8px 0 20px;
  }

  & .record {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & .type {
      font-weight: 700;
      margin-bottom: 8px;
    }

    & .value {
      margin-bottom: 0;

      &:deep(.v-field__field > input) {
        font-size: 12px;
      }

      &:deep(.v-input__details) {
        margin-bottom: 0;
      }
    }
  }
}
</style>
