<script setup>
import { computed } from 'vue'
import Card from '@/components/creator/Card.vue'
import CardEmpty from '@/components/creator/CardEmpty.vue'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  cardWidth: {
    type: String,
    required: true,
  },
  cardHeight: {
    type: String,
    required: true,
  },
  createButton: {
    type: [Array, Object],
    // { show, title, path, params, query }
  },
})

const cardWidth = computed(() => props.cardWidth)

const emit = defineEmits(['click'])

</script>

<template>
  <div class="items-cards__wrapper">
    <div v-for="item, index in props.items" :key="index">
      <Card
        v-bind="item"
        :cardWidth="props.cardWidth"
        :cardHeight="props.cardHeight"
        :menu="item.menu"
        :menuClickArg="item"
        @click="emit('click', item, index)"
      />
    </div>

    <div>
      <CardEmpty
        :cardWidth="props.cardWidth"
        :cardHeight="props.cardHeight"
        :buttons="props.createButton"
        v-if="props.createButton"
        @click="(buttonItem) => emit('buttonClick', buttonItem)"
      />
    </div>
  </div>

</template>

<style scoped lang="scss">
.items-cards__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, v-bind(cardWidth));
  justify-content: space-between;
  gap: 16px;
  min-height: 0;
  overflow-y: auto;
  padding: 2px;
  align-content: flex-start;
}
</style>
