<script setup>
import { ref, onMounted, computed, watch, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { useRoute, useRouter } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'
// import { useNft } from '@/helpers/smartContractApi/nft'
import { deepClone, htmlDecode, getLoadingState } from '@/utils/common'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import entityTypes from '@/helpers/entityTypes'
import { processError } from '@/helpers/errors'

import {
  Button,
  TabNav,
  Tabs,
  Tab,
  TextInput,
  ModelViewer,
  FileInput,
  TextArea,
  Collapsible,
  EmbedCodeDialog,
  Checkbox,
  NftStudio,
  LoadingIndicator,
  AnalyticsPanel,
} from '@/components/creator'

import { Project, ContentItem } from '@/helpers/api/models'
import { standardRules } from '@/helpers/validationRules'
import { isAvailable } from '@/helpers/billingFeatures'

const state = ref(getLoadingState())

const stateAll = computed(() => {
  return {
    requestInProgress: state.value.requestInProgress || store.state.nftStudio.isNftStudioLoading || analyticsPanel.value?.state.requestInProgress,
    isLoading: state.value.isLoading || analyticsPanel.value?.state.isLoading,
    isUpdating: state.value.isUpdating || analyticsPanel.value?.state.isUpdating,
  }
})

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiProjectsGet,
  apiContentItemsGet,
  apiProjectsUpdate,
  apiContentItemsUpdate,
  apiProjectsDelete,
  setTitle,
} = useActions([
  'apiProjectsGet',
  'apiContentItemsGet',
  'apiProjectsUpdate',
  'apiContentItemsUpdate',
  'apiProjectsDelete',
  'setTitle',
])

const route = useRoute()
const router = useRouter()

const analyticsIsAvailable = computed(() => {
  return isAvailable('ANALYTICS_ENABLED')
})

const projectId = route.params.id
// const spaceUrl = process.env.VUE_APP_SPACES_URL

const tab = ref()
const nftStudio = ref(null)
const embedCodeBtn = ref(null)
const deleteProjectDialog = ref(null)
const analyticsPanel = ref(null)

const project = ref(deepClone(Project))
const contentItem = ref(deepClone(ContentItem))

const projectUrl = computed(
  () => `${process.env.VUE_APP_APP_URL}/${project.value.id}`
)

const isBaseFormValid = ref(false)
const fileInputGlb = ref(null)
const fileInputUsdz = ref(null)
const isFormValid = computed(() => {
  return (
    isBaseFormValid.value &&
    fileInputGlb.value?.valid &&
    (!contentItem.value.media.custom_usdz || fileInputUsdz.value?.valid)
  )
})

const tabItems = ref([])

const embedCode = computed(() => {
  const isDevStage = process.env.VUE_APP_STAGE === 'dev'
  const apiUrl = process.env.VUE_APP_API_URL.replace(
    'https://',
    ''
  ).toLowerCase()
  const viewerUrl = `${process.env.VUE_APP_STATIC_URL.toLowerCase()}/v/aci-viewer${
    isDevStage ? '-dev' : ''
  }.js`

  return htmlDecode(
    `
    &lt;aci-viewer
      ${isDevStage ? 'apiUrl="' + apiUrl + '"' : ''}
      contentId="${project.value.id}"&gt;&lt;/aci-viewer&gt
      &lt;script type="module" src="${viewerUrl}"&gt;&lt;/script&gt
    `
  )
})

// nft
watch(tab, async (newTab) => {
  if (newTab !== 'NFT') {
    return
  }

  const fromSignin = route.query.signin === '1'

  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, 100)
  })

  if (!fromSignin) {
    nftStudio.value.onVisible()
  }
})

function close () {
  router.push({
    name: 'ArList',
  })
}

async function save () {
  try {
    state.value.requestInProgress = true
    state.value.isUpdating = true

    contentItem.value = (
      await apiContentItemsUpdate({ data: contentItem.value })
    ).content
    project.value = (await apiProjectsUpdate({ data: project.value })).content

    updateTitle()

    notify({
      type: 'success',
      text: 'Project updated',
      timeout: 30000,
    })
  } catch (error) {
    processError(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isUpdating = false
  }
}

async function load () {
  try {
    state.value.requestInProgress = true
    state.value.isLoading = true

    project.value = (
      await apiProjectsGet({ id: projectId })
    ).content
    const contentItemId = project.value.content[0].content_item.id
    contentItem.value = (
      await apiContentItemsGet({ data: contentItemId })
    ).content

    updateTitle()
  } catch (error) {
    processError(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isLoading = false
  }
}

function updateTitle () {
  setTitle(`${project.value.name} | AR settings`)
}

async function deleteProject () {
  try {
    state.value.requestInProgress = true
    state.value.isDeleting = true

    await Promise.all([
      apiProjectsDelete({ id: project.value.id }),
    ])
    close()
  } catch (error) {
    processError(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isDeleting = false
  }
}

function showEmbedCode () {
  embedCodeBtn.value.show()
}

const deleteProjectClicked = async () => {
  const result = await deleteProjectDialog.value.show()
  if (result === 'delete') {
    await deleteProject()
  }
}

onMounted(async () => {
  await Promise.all([load()])
})

onBeforeMount(async () => {
  tabItems.value = ['Settings']
  if (process.env.VUE_APP_STAGE !== 'prod') {
    tabItems.value.push('NFT')
  }

  tabItems.value.push({
    value: 'Analytics',
    icon: analyticsIsAvailable.value ? '' : 'mdi-lock-outline',
    disabled: !analyticsIsAvailable.value,
    color: analyticsIsAvailable.value ? '' : '#ACC2D2',
  })

  if (route.query.signin === '1') {
    setTimeout(async () => {
      tab.value = 'NFT'
    }, 100)
  }
})
</script>

<template>
  <div>
    <EmbedCodeDialog :code="embedCode" ref="embedCodeBtn" />

    <div class="g-title-wrapper">
      <h1 class="g-title">
        {{ project.name }}
      </h1>
    </div>

    <div class="g-content g-shrink">
      <div class="g-content__header">
        <div class="g-tabs__wrapper">
          <TabNav
            :items="tabItems"
            v-model="tab"
            urlParam="tab"
          />
        </div>
        <div class="g-content-toolbar_wrapper">
          <div class="project-url">
            <v-icon class="project-url__icon" size="18">
              mdi-link-variant
            </v-icon>
            <a :href="projectUrl" target="_blank" class="project-url__link">
              {{ projectUrl }}
            </a>
          </div>
          <button class="btn-embed" @click="showEmbedCode">&lt;/&gt;</button>
        </div>
      </div>
      <div class="g-content__inner g-shrink">
        <LoadingIndicator :state="stateAll" all />
        <Tabs v-model="tab">
          <Tab value="Settings">
            <div class="g-content__columns-wrapper g-shrink">
              <div class="g-content__column-first">
                <v-form
                  v-model="isBaseFormValid"
                  :loading="state.requestInProgress"
                >
                  <TextInput
                    v-model="project.name"
                    name="name"
                    type="text"
                    label="Content name"
                    :rules="[standardRules.required]"
                    autofocus
                  />

                  <div class="g-section-title">Content files</div>

                  <FileInput
                    ref="fileInputGlb"
                    v-model="contentItem.media.glbmodel_original"
                    :extensions="['.glb', '.gltf']"
                    placeholder="GLB/GLTF"
                    required
                    :validator="{}"
                  />

                  <Checkbox
                    title="Customize USDZ/Reality"
                    v-model="contentItem.media.custom_usdz"
                  />

                  <FileInput
                    ref="fileInputUsdz"
                    v-show="contentItem.media.custom_usdz"
                    v-model="contentItem.media.usdzmodel"
                    :extensions="['.usdz', '.reality']"
                    placeholder="USDZ / Reality"
                    required
                    :validator="{}"
                  />

                  <FileInput
                    v-model="contentItem.media.sound"
                    :extensions="['.mp3', '.wav', '.ogg']"
                    placeholder="Sound"
                    :validator="{}"
                  />

                  <FileInput
                    v-model="contentItem.media.poster"
                    :extensions="['.jpg', '.jpeg', '.png']"
                    :validator="{}"
                    placeholder="Poster"
                  />

                  <template v-if="false">
                    <div class="g-section-title">About</div>

                    <TextInput
                      v-model="contentItem.creator"
                      type="text"
                      label="Creator"
                    />

                    <TextInput
                      v-model="contentItem.link"
                      type="text"
                      label="External link"
                    />

                    <TextArea
                      v-model="project.description"
                      name="description"
                      label="Description"
                    />
                  </template>

                  <Collapsible title="banner" uppercase>
                    <TextInput
                      label="Title"
                      type="text"
                      v-model="contentItem.media.link.title"
                    />

                    <TextInput
                      label="Link"
                      type="text"
                      v-model="contentItem.media.link.href"
                    />

                    <TextInput
                      label="Button name"
                      type="text"
                      v-model="contentItem.media.link.button"
                    />

                    <TextInput
                      label="Description"
                      type="text"
                      v-model="contentItem.media.link.banner"
                    />

                    <Checkbox
                      title="Disable AR and redirect to link on click"
                      v-model="contentItem.media.link.use_instead_ar"
                    />
                  </Collapsible>
                </v-form>
              </div>

              <div class="g-content__column-second">
                <div class="g-content-preview-wrapper">
                  <ModelViewer
                    :src="contentItem.media.glbmodel"
                    :poster="contentItem.media.poster"
                    label="Preview"
                    :type="contentItem.type"
                  />
                </div>
              </div>
            </div>

            <div class="g-content__footer">
              <div class="g-controls__wrapper">
                <div class="g-controls__col-first">
                  <Button
                    class="g-controls__control"
                    @click="close"
                    :disabled="state.requestInProgress"
                    >Close</Button
                  >
                  <Button
                    class="g-controls__control"
                    @click="save"
                    primary
                    :disabled="state.requestInProgress || !isFormValid"
                    :loading="state.isUpdating"
                    >Save</Button
                  >
                </div>
                <div class="g-controls__col-last">
                  <Button
                    class="g-controls__control"
                    @click="deleteProjectClicked"
                    :disabled="state.requestInProgress"
                    :loading="state.isDeleting"
                    alert
                    >Delete</Button
                  >
                </div>
              </div>
            </div>
          </Tab>

          <Tab value="NFT">
            <div class="g-content__columns-wrapper g-shrink">
              <div class="g-content__column-first">
                <NftStudio
                  ref="nftStudio"
                  @needClose="tab = 'Settings'"
                  :entityType="entityTypes.ar.name"
                  :entity="project"
                />
              </div>
            </div>
          </Tab>

          <Tab value="Analytics" class="tab-analytics" v-if="analyticsIsAvailable">
            <AnalyticsPanel
              :entityId="projectId"
              :entityType="entityTypes.ar.name"
              ref="analyticsPanel"
            />
          </Tab>
        </Tabs>
      </div>
    </div>
    <Dialog
      title="Delete AR item?"
      ref="deleteProjectDialog"
      :buttons="[DialogButtons.Cancel, DialogButtons.DeletePrimary]"
    >
      <div class="g-text-center">
        The AR will be deleted with all the information.<br />
        All Content is saved in Storage
      </div>
    </Dialog>
  </div>
</template>

<style lang="scss" scoped>
.g-content__columns-wrapper {
  flex: 1;
}

.g-controls__col-first,
.g-controls__col-last {
  flex: 1;
}

.g-controls__col-last {
  align-items: flex-end;
}

.input-description {
  margin-top: 33px;
}

.content-toolbar-wrapper {
  margin-left: auto;
}

.project-url {
  display: flex;
  gap: 8px;
  align-items: center;
}

.project-url__link {
  font: 400 12px 'Inter';
  text-decoration-line: underline;
  color: #00d7ff;
}

.field {
  &.checkbox {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 16px;

    font-size: 14px;
    margin: 16px 0;
    color: #5a7287;

    & > input {
      margin-right: 8px;
    }
  }
}

.collapsible__wrapper {
  margin: 24px 0 8px;
}

.tab-analytics {
  overflow: auto;
}

</style>
