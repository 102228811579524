<script setup>
import { ref } from 'vue'
import creatorItemTypes from '@/helpers/creatorItemTypes'
import creatorItemViews from '@/helpers/creatorItemViews'
import StorageItems from '@/components/storage/StorageItems.vue'
import CreateBtn from '@/components/btns/CreateBtn.vue'
import SearchForm from '@/components/forms/SearchForm.vue'
import ContentFilter from '@/components/creator/ContentFilter.vue'
import ViewModeSwitcher from '@/components/creator/ViewModeSwitcher.vue'

const viewModeSwitcher = ref(null)
</script>

<template>
  <div class="storage__wrapper">
    <div class="g-toolbar">
      <div class="g-toolbar__first-col"></div>
      <div class="g-toolbar__last-col">
        <SearchForm />
        <CreateBtn :to="{ name: 'StorageNew' }">Add content</CreateBtn>
      </div>
    </div>

    <div class="g-content g-shrink">
      <div class="g-content__header">
        <div class="g-content-header__col-first">
          <h1 class="g-content-header__title">Your content</h1>
        </div>
        <div class="g-content-header__col-last">
          <div class="g-content-toolbar_wrapper">
            <ContentFilter
              :default-filter="creatorItemTypes.all"
              :exclude="['ar']"
            ></ContentFilter>
            <ViewModeSwitcher
              :default-view="creatorItemViews.cards"
              ref="viewModeSwitcher"
            ></ViewModeSwitcher>
          </div>
        </div>
      </div>
      <div class="g-content__inner g-shrink">
        <StorageItems :viewModeSwitcher="viewModeSwitcher" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.storage__wrapper {
  display: flex;
  flex-direction: column;
}
</style>
