<script setup>
import CreateBtn from '@/components/btns/CreateBtn.vue'
import WorldsItems from '@/components/worlds/WorldsItems.vue'
import creatorItemViews from '@/helpers/creatorItemViews'
import SearchForm from '@/components/forms/SearchForm.vue'
import { ViewModeSwitcher } from '@/components/creator'
import { ref } from 'vue'
import { isAvailable } from '@/helpers/billingFeatures'

const viewModeSwitcher = ref(null)
</script>

<template>
  <div>
    <div class="g-toolbar">
      <div class="g-toolbar__first-col"></div>
      <div class="g-toolbar__last-col">
        <SearchForm />
        <CreateBtn :to="{ name: 'WorldsNew' }">Create new world</CreateBtn>
      </div>
    </div>

    <div class="g-content g-shrink">
      <div class="g-content__header">
        <div class="g-content-header__col-first">
          <h1 class="g-content-header__title">Your worlds</h1>
        </div>
        <div class="g-content-header__col-last">
          <div class="g-content-toolbar_wrapper">
            <ViewModeSwitcher
              :default-view="creatorItemViews.cards"
              ref="viewModeSwitcher"
            />
          </div>
        </div>
      </div>
      <div class="g-content__inner g-shrink">
        <WorldsItems :viewModeSwitcher="viewModeSwitcher" v-if="isAvailable('WORLDS_ENABLED')"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>
