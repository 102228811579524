<script setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import SearchTextField from '@/components/fields/SearchTextField.vue'

const router = useRouter()
const route = useRoute()
const searchText = ref(route.query.search || '')

function handleSubmit () {
  const search = searchText.value.trim() || undefined
  router.push({
    name: '',
    query: { ...route.query, search },
  })
}

function clearSearch () {
  searchText.value = ''
  handleSubmit()
}
</script>

<template>
  <v-form class="form" @submit.prevent="handleSubmit">
    <SearchTextField
      v-model="searchText"
      @click:prepend-inner="handleSubmit"
      @click:clear="clearSearch"
    />
  </v-form>
</template>

<style scoped lang="scss">
.form {
  width: 282px;
}
</style>
