<script setup>
</script>

<template>
  <v-btn variant="text" prepend-icon="mdi-plus-circle-outline" class="btn">
    <span class="btnText">
      <slot></slot>
    </span>
  </v-btn>
</template>

<style scoped lang="scss">
.btn {
  text-transform: none !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 30px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #fff !important;
  height: 38px !important;
  letter-spacing: 0 !important;
  padding: 0 20px !important;

  .btnText {
    line-height: 1 !important;
  }

  :deep(.v-btn__content) {
    display: flex !important;
    align-items: center !important;

    > i {
      font-size: 23px !important;
      color: #00d7ff !important;
      margin-right: 10px !important;
    }
  }
}
</style>
