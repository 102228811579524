<script setup>
import Dialog from '@/components/common/dialogs/Dialog'
import { ref } from 'vue'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'

const dialog = ref(null)

const dialogButtons = ref([
  [
    DialogButtons.Cancel,
    {
      ...DialogButtons.Ok,
      title: 'Ok',
    },
  ],
])

const show = async () => {
  return await dialog.value.show()
}

defineExpose({ show })
</script>

<template>
  <Dialog
    ref="dialog"
    :buttons="dialogButtons"
    class="export-dialog__wrapper"
  >
    <p class="text">
      Recent changes are not saved.<br/>
      Are you sure you want to leave?
    </p>
  </Dialog>
</template>

<style lang="scss" scoped>
.text {
  font: 500 18px/24px 'Poppins';
  text-align: center;
  color: #FFFFFF;
}
</style>
