<script setup>
import { nextTick, onMounted, ref, watch } from 'vue'
import Dialog from '@/components/common/dialogs/Dialog'
import { Button } from '@/components/creator'

const tourDialog = ref()
const btnSkip = ref()

const videos = [
  { url: 'https://c.w3rlds.com/15112ed0-d5e8-11ed-a710-cf481c05a4f0.mp4' },
  { url: 'https://c.w3rlds.com/16a1e0f0-d5e8-11ed-a710-cf481c05a4f0.mp4' },
]

const videoElements = ref([])

const currentSlide = ref(0)

watch(() => currentSlide.value, (newVal, oldVal) => {
  videoElements.value[oldVal].pause()
})

onMounted(async () => {
  tourDialog.value.show()

  await nextTick()

  const carouselControls = tourDialog.value.$el.querySelector('.v-carousel__controls')
  btnSkip.value.$el.parentNode.insertBefore(carouselControls, btnSkip.value.$el)

  const btns = carouselControls.querySelectorAll('.v-btn__content')
  let i = 1
  for (const btn of btns) {
    btn.innerHTML = i
    i++
  }
})
</script>

<template>
  <Dialog
    title=""
    ref="tourDialog"
    class="dlg-tour"
    no-paddings
  >
    <v-carousel class="slider__wrapper" height="427" :show-arrows="false" v-model="currentSlide">
      <v-carousel-item
        class="slider-item"
        :value="index"
        v-for="(item, index) in videos"
        :key="index"
      >
        <div class="video-wrapper">
          <video crossorigin="anonymous" :src="item.url" class="video" controls="" ref="videoElements"></video>
        </div>
      </v-carousel-item>
    </v-carousel>

    <div class="tour-text__wrapper">
      <h2 class="title">Welcome to W3rlds</h2>
      <p class="description">Create immersive worlds and content faster than ever before. Get started right away with the App tour with a quick overview of what W3rlds can do.</p>

      <div class="slider-controls__wrapper">
        <Button
          ref="btnSkip"
          class="slider-controls-skip"
          @click="tourDialog.close()"
          secondary
        >Skip
        </Button>
      </div>
    </div>
  </Dialog>
</template>

<style scoped lang="scss">
.dlg-tour {
  z-index: 200 !important;
}

.dlg-tour > :deep(.ah-dialog) {
  max-width: 759px;
}

.dlg-tour :deep(.btn-close) {
  z-index: 10;
}

:deep(.slider-item) {
  width: 759px;
}

.video-wrapper,
.video {
  width: 100%;
  height: 100%;
}

.tour-text__wrapper {
  width: 759px;
  padding: 25px 52px 35px;
}

.title {
  font: normal 24px 'Poppins';
  text-align: center;
  color: #FFFFFF;
}

.description {
  max-width: 543px;
  text-align: center;
  margin: 10px auto 0;

  font: normal 14px/21px 'Poppins';
  color: #FFFFFF;
}
.dlg-tour :deep(.controls) {
  display: none !important;
}

.dlg-tour :deep(.v-carousel__controls) {
  position: static;
  width: auto;
  justify-content: start;
  background: none;
}

.dlg-tour :deep(.v-carousel__controls .v-btn) {
  width: 38px;
  height: 38px;
  margin-right: 20px;
  border: 1px solid #8B9FB0;

  .v-btn__content {
    font: 600 16px 'Inter';
    color: #8B9FB0;
  }

  &.v-btn--selected {
    border: 1px solid #00D7FF;

    .v-btn__content {
      color: #00D7FF;
    }
  }
}

.slider-controls__wrapper {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.slider-controls-skip {
  margin-left: auto;
}
</style>
