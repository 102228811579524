<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import ThBtn from '@/components/btns/ThBtn.vue'

const props = defineProps(['sortKey'])
const route = useRoute()

const sortOrder = computed(() => {
  if (route.query.sort === props.sortKey) {
    return route.query.order
  }
  return undefined
})
const query = computed(() => {
  let order = 'asc'
  if (sortOrder.value === 'asc') {
    order = 'desc'
  } else {
    order = 'asc'
  }
  return { ...route.query, sort: props.sortKey, order }
})
</script>

<template>
  <ThBtn :to="{ name: '', query }" :order="sortOrder">
    <slot></slot>
  </ThBtn>
</template>
