<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { notify } from '@kyvg/vue3-notification'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { UserRoles, User } from '@/helpers/api/models'

import {
  Button,
  TabNav,
  Tabs,
  Tab,
  TextInput,
  Select,
  Checkbox,
  LoadingIndicator,
} from '@/components/creator'

import { deepClone, camelCaseToSpaces, apiCallWithState } from '@/utils/common'
import { standardRules } from '@/helpers/validationRules'
import { processError } from '@/helpers/errors'

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const roleItems = ref([])

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiUsersGet,
  apiUsersUpdate,
  apiUsersDelete,
  apiUsersCreate,
  setTitle,
} = useActions([
  'apiUsersGet',
  'apiUsersUpdate',
  'apiUsersDelete',
  'apiUsersCreate',
  'setTitle',
])
const route = useRoute()
const router = useRouter()

const deleteUserDialog = ref(null)

const form = ref(null)
const tab = ref('')

const baseFormValid = ref(false)
const isFormValid = computed(() => baseFormValid.value && user.value.role)

const user = ref(deepClone(User))

const isNew = computed(() => route.params.id === 'new')

function close () {
  router.push({
    name: 'PlatformSettings',
  })
}

async function loadUser () {
  try {
    user.value = (
      await apiUsersGet({ id: route.params.id })
    ).content

    setTitle(`${user.value.full_name} | User settings`)
  } catch (error) {
    processError(error)
  }
}

async function saveClicked () {
  if (isNew.value) {
    await apiCallWithState(createUser, {}, state, 'isUpdating')
  } else {
    await apiCallWithState(updateUser, {}, state, 'isUpdating')
  }
}
async function createUser () {
  try {
    user.value = (
      await apiUsersCreate({ data: user.value })
    ).content

    notify({
      type: 'success',
      text: 'User has been created',
      timeout: 30000,
    })

    setTitle(`${user.value.full_name} | User settings`)

    router.push({
      name: 'UsersItem',
      params: {
        id: user.value.id,
      },
    })
  } catch (error) {
    processError(error)
  }
}

async function updateUser () {
  try {
    user.value = (
      await apiUsersUpdate({ data: user.value })
    ).content

    notify({
      type: 'success',
      text: 'User has been updated',
      timeout: 30000,
    })

    setTitle(`${user.value.full_name} | User settings`)
  } catch (error) {
    processError(error)
  }
}

async function deleteUser () {
  try {
    await apiUsersDelete({
      id: user.value.id,
      email: user.value.email,
    })
    close()
  } catch (error) {
    processError(error)
  }
}

onMounted(async () => {
  if (!isNew.value) {
    await apiCallWithState(loadUser, null, state, 'isLoading')
  }

  roleItems.value = Object.keys(UserRoles).map((key) => ({
    value: UserRoles[key],
    text: camelCaseToSpaces(UserRoles[key]),
  }))
  // form.value.validate()
})

const deleteUserClicked = async () => {
  const result = await deleteUserDialog.value.show()
  if (result === 'delete') {
    await apiCallWithState(deleteUser, {}, state, 'isDeleting')
  }
}
</script>

<template>
  <div>
    <div class="g-title-wrapper">
      <h1 class="g-title">User edit</h1>
    </div>

    <div class="g-content g-shrink">
      <div class="g-content__header">
        <div class="g-tabs__wrapper">
          <TabNav :items="['Users']" v-model="tab">
          </TabNav>
        </div>
      </div>
      <div class="g-content__inner g-shrink">

        <LoadingIndicator :state="state" all />

        <v-form
          v-model="baseFormValid"
          :loading="state.requestInProgress"
          class="g-shrink form"
          ref="form"
        >
          <Tabs v-model="tab">
            <Tab value="Users">
              <div class="g-content__columns-wrapper g-shrink">
                <div class="g-content__column-first">
                  <div class="g-section-title">BASIC INFO</div>
                  <TextInput
                    v-model="user.first_name"
                    type="text"
                    label="First name"
                    :rules="[standardRules.required]"
                    autofocus
                  />

                  <TextInput
                    v-model="user.last_name"
                    type="text"
                    label="Last name"
                    :rules="[standardRules.required]"
                  />

                  <TextInput
                    v-model="user.email"
                    type="text"
                    label="Email"
                    :rules="[standardRules.required, standardRules.email]"
                  />

                  <Select
                    v-model="user.role"
                    type="text"
                    placeHolder="Role"
                    :items="roleItems"
                    :rules="[standardRules.required, standardRules.email]"
                  />

                  <Checkbox
                    title="Service account"
                    v-model="user.is_service_account"
                  />

                  <div class="g-content__column-second">
                  </div>
                </div>
              </div>
            </Tab>

          </Tabs>
        </v-form>

        <div class="g-content__footer">
          <div class="g-controls__wrapper">
            <div class="g-controls__col-first">
              <Button
                class="g-controls__control"
                @click="close"
                :disabled="state.requestInProgress"
              >
                Close
              </Button>
              <Button
                class="g-controls__control"
                @click="saveClicked"
                primary
                :disabled="state.requestInProgress || !isFormValid"
                :loading="state.isUpdating"
              >
                {{ isNew ? 'Create' : 'Update' }}
              </Button>
            </div>
            <div class="g-controls__col-last">
              <Button
                class="g-controls__control"
                @click="deleteUserClicked"
                :disabled="state.requestInProgress"
                :loading="state.isDeleting"
                alert
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog
      title="Delete user?"
      ref="deleteUserDialog"
      :buttons="[DialogButtons.Cancel, DialogButtons.DeletePrimary]"
    >
      <div class="g-text-center">
        The User will be deleted with all the information.
      </div>
    </Dialog>
  </div>
</template>

<style lang="scss" scoped>
.g-content__columns-wrapper {
  flex: 1;
}

.g-controls__col-first,
.g-controls__col-last {
  flex: 1;
}

.g-controls__col-last {
  align-items: flex-end;
}

.input-description {
  margin-top: 13px;
}

.content-toolbar-wrapper {
  margin-left: auto;
}

.custom-domain-brief {
  margin-top: 30px;
}

.file-input-preview {
  margin-top: 23px;
}

.form {
  display: flex;
  flex: 1;
}
</style>
