<script setup>
import { onMounted } from 'vue'

import { Button } from '@/components/creator'

onMounted(() => {
  /* $('.hovered').trigger('mouseenter')
  const hovered = document.querySelectorAll('.hovered')

  hovered.forEach(function(el) {
    el.dispatchEvent(new MouseEvent('mouseenter', { bubbles: true }))
  }) */
})
</script>

<template>
  <div class="components-demo-wrapper">
    <div class="component-wrapper">
      <div v-for="type in ['primary', 'secondary', 'ghost']" :key="type">
        <div class="state-title">{{ type }}</div>

        <div class="row">
          <div
            class="state-wrapper"
            v-for="state in ['regular', 'hover', 'pressed', 'disabled']"
            :key="state"
          >
            <div class="column">
              <div class="title">{{ state }}</div>

              <template
                v-for="size in ['small', 'regular', 'large', 'xlarge']"
                :key="size"
              >
                <div class="variants-wrapper">
                  <Button
                    :primary="type == 'primary'"
                    :secondary="type == 'secondary'"
                    :ghost="type == 'ghost'"
                    :small="size == 'small'"
                    :regular="size == 'regular'"
                    :large="size == 'large'"
                    :xlarge="size == 'xlarge'"
                    :disabled="state == 'disabled'"
                    :class="{ hovered: state == 'hover' }"
                  >
                    Save
                  </Button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.components-demo-wrapper {
  display: flex;
}

.component-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.state-wrapper {
  border: 1px dashed #00d7ff;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
}

/* .state-title {
    margin: 10px;
  } */

.row {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.title {
  text-align: center;
  margin: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  border: 1px dotted #00d7ff;
  border-radius: 5px;
  padding: 10px;
}

.variants-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 10px;
  }
}
</style>
