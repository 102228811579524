import { useStore } from 'vuex'

export function isAvailable (featureId, currentNumber) {
  const feature = getFeatureById(featureId)

  const featureValueIsNumber = typeof feature.value === 'number'

  if (currentNumber !== undefined) {
    if (!featureValueIsNumber) {
      throw new Error('Feature value is not a number')
    }

    return feature.value === -1 || currentNumber < feature.value
  } else {
    if (featureValueIsNumber) {
      throw new Error('Feature value is a number')
    }

    return feature.value
  }
}

export function getFeatureById (featureId) {
  const store = useStore()

  const features = store.state.auth.subscription.billing_plan.features
  return features.find((item) => item.feature.id === featureId)
}
