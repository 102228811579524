<script setup>
import { ref, watchEffect } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'aclEnabled'])

const isToggled = ref(props.modelValue)

watchEffect(() => {
  isToggled.value = props.modelValue
})

const toggleChanged = (event) => {
  emit('update:modelValue', event.target.checked)
  emit('aclEnabled', event.target.checked)
}
</script>

<template>
  <div class="toggle-switch">
    <label :class="{ 'active': isToggled }">
      <input
        type="checkbox"
        :checked="isToggled"
        @change="toggleChanged"
      />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<style scoped>
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #465464;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 1px;
  background-color: black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #01b1d8;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
