<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import Dialog from '@/components/common/dialogs/Dialog'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { getLoadingState, apiCallWithState, getPreviewUrlByContentItem } from '@/utils/common'
import ItemsCards from '@/components/ItemsCards.vue'
import { LoadingIndicator, Pagination, Button } from '@/components/creator'
import { processError } from '@/helpers/errors'

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiContentItemsList,
} = useActions([
  'apiContentItemsList',
])

const dialog = ref(null)

const state = ref(getLoadingState())
const rawData = ref([])

const cardsPerPage = 10
const pageNumber = ref(1)

const pagesCount = computed(() => {
  return Math.ceil(filteredData.value.length / cardsPerPage)
})

const selectedCards = ref({})

const filteredData = computed(() => {
  const typeFilter = props.itemType?.name.toLowerCase()

  return rawData.value.filter((item) => {
    return (!typeFilter || item.type.toLowerCase() === typeFilter) && !props.exclude.includes(item.id)
  })
})

const data = computed(() => {
  const result = []

  const startNumber = (pageNumber.value - 1) * cardsPerPage
  const endNumber = startNumber + cardsPerPage > filteredData.value.length
    ? filteredData.value.length
    : startNumber + cardsPerPage

  for (let n = startNumber; n < endNumber; n++) {
    const item = filteredData.value[n]

    result.push({
      id: item.id,
      title: item.name,
      previewPoster: getPreviewUrlByContentItem(item),
      contentType: item.type,
      selected: selectedCards.value[item.id] !== undefined,
      sourceObject: item,
    })
  }

  return result
})

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  itemType: {
    type: Object,
    required: false,
  },
  exclude: {
    type: Array,
    default: () => [],
  },
})

watch(() => pageNumber.value, async () => {
  selectedCards.value = {}
})

const show = async () => {
  selectedCards.value = {}
  pageNumber.value = 1

  loadData()

  const result = await dialog.value.show()

  if (result === true) {
    return Object.values(selectedCards.value)
  } else {
    return []
  }
}

function selectAndClose () {
  dialog.value.close(true)
}

function cardClick (item) {
  selectedCards.value = {}

  if (selectedCards.value[item.id]) {
    delete selectedCards.value[item.id]
  } else {
    selectedCards.value[item.id] = item.sourceObject
  }
}

async function loadData () {
  try {
    const params = {
      // If itemType is set, don't send pagination args to the api.
      pageSize: props.itemType ? null : cardsPerPage,
      page: pageNumber.value,
    }
    const result = await apiCallWithState(apiContentItemsList, params, state, 'isLoading')
    rawData.value = result.content
  } catch (e) {
    processError(e)
  }
}

defineExpose({ show })
</script>

<template>
  <Dialog
    :title="props.title || 'Select export option'"
    titleAlign="left"
    ref="dialog"
    :buttons="[]"
  >
    <LoadingIndicator :state="state" all/>
    <ItemsCards
      :items="data"
      cardWidth="200px"
      cardHeight="254px"
      class="content-items__inner"
      v-if="data"
      @click="cardClick"
    />

    <div class="footer">
      <Pagination
        :length="pagesCount"
        v-model="pageNumber"
        v-if="pagesCount > 1"
      />
      <Button
        class="btn-select"
        @click="selectAndClose()"
        primary
        small
      >
        Select
      </Button>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
:deep(.ah-dialog) {
  width: 1122px;
  height: 700px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}
.pagination__wrapper {
}

.content-items__inner {
  margin-bottom: 20px;
}

.btn-select {
  margin-left: auto;
}
</style>
