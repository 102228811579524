<script setup>
import { computed } from 'vue'
import { deepClone } from '@/utils/common'

const props = defineProps({
  features: {
    type: Object,
    required: true,
  },
  billingPlans: {
    type: Object,
    required: true,
  },
})

const billingPlansSorted = computed((a, b) => {
  const billingPlansCopy = deepClone(props.billingPlans)

  return billingPlansCopy.sort((a, b) => {
    if (a.order < b.order) {
      return -1
    }
    if (a.order > b.order) {
      return 1
    }
    return 0
  })
})

const featuresExistsInPlans = computed(() => {
  return props.features.filter((featureItem) => {
    const featureInPlan = billingPlansSorted.value.find(planItem => {
      return planItem.features.find(planFeatureItem => planFeatureItem.feature.id === featureItem.id) !== undefined
    })

    return featureInPlan !== undefined
  })
})

function valuePresentation (billingPlan, func) {
  // const funcName = func.name
  const feature = billingPlan.features.find((item) => item.feature.id === func.id)

  if (!feature) {
    return ''
  }

  if (typeof feature.value !== 'boolean' && feature.human) {
    return feature.human
  }

  if (feature.value) {
    return feature.value
  }

  return ''
}

</script>

<template>
  <div class="subscription-features-table">
    <table class="table">
      <thead>
        <tr class="thead-row">
          <th class="cell cell-head cell-head-features cell-feature-name">Features</th>
          <th
            v-for="billingPlan of billingPlansSorted"
            :key="billingPlan.name"
            class="cell cell-head cell-head-subscription cell-feature-value"
          >
            {{ billingPlan.name }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="func of featuresExistsInPlans" :key="func.name">
          <td class="cell cell-body cell-feature-name">
            <p v-html="func.title"></p>
            <ul
              class="feature-sublist"
              v-if="func.items"
            >
              <li
                v-for="item of func.items"
                :key="item"
                class="feature-sublist__item"
              >
                {{ item }}
              </li>
            </ul>
          </td>

          <td
            v-for="billingPlan of billingPlansSorted"
            :key="billingPlan.name"
            class="cell cell-body cell-feature-value"
          >
            <v-icon
              class="feature__icon"
              size="18"
              v-if="valuePresentation(billingPlan, func) === true"
            >
              mdi-check
            </v-icon>
            <template v-else>
              {{ valuePresentation(billingPlan, func) }}
            </template>
          </td>
        </tr>
      </tbody>
   </table>
  </div>
</template>

<style scoped lang="scss">
th {
  text-align: left;
}

.subscription-features-table {
  background: #182734;
  border: 1px solid #334656;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.table {
  table-layout: fixed;
}

.thead-row {
  height: 60px;
}

.cell-head {
  padding: 18px 24px;
}

.cell-body {
  padding: 6px 24px;
  color: #FFFFFF;
  border-top: 1px solid #334656;
}

.cell-head-features {
  font: 500 16px/24px 'Poppins';
  letter-spacing: 0.002em;
  color: #FFFFFF;
  width: 370px;
}

.cell-feature {
  width: 370px
}

.cell-feature-value {
  border-left: 1px solid #334656;
  text-align: center;
  font: 400 14px 'Poppins';
  color: #FFFFFF;
  padding: 6px 12px;
}

.cell-head-subscription {
  font: 500 16px/24px 'Poppins';
  letter-spacing: 0.002em;
  color: #00D7FF;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.feature-sublist {
  margin-left: 23px;
}

.cell-feature-name p ul {
  margin-left: 40px;
}
</style>
