<script setup>
</script>

<template>
  <div class="collectables-list-wrapper">
    <div class="g-title-wrapper">
      <h1 class="g-title">Collectibles</h1>
    </div>

    <div class="g-content"></div>
  </div>
</template>

<style scoped lang="scss">
</style>
