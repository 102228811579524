import { ContentItemType } from '@/helpers/api/models'

const creatorItemTypes = {
  all: {
    title: 'All',
    queryParam: 'all',
  },
  glb: {
    title: '3D object',
    queryParam: '3d',
    icon: 'mdi-cube',
    storageUploadExt: ['.fbx', '.zip', '.glb'],
    contentType: ContentItemType.GLB,
    textSpecs: 'GLB/FBX/ZIP max 50MB',
    fileValidator: {},
  },
  avatar: {
    title: 'Avatar',
    queryParam: 'avatar',
    icon: 'mdi-account',
    storageUploadExt: ['.fbx', '.zip', '.glb'],
    contentType: ContentItemType.AVATAR,
    textSpecs: 'GLB/FBX/ZIP max 50MB',
    fileValidator: {},
  },
  ar: {
    title: 'AR',
    queryParam: 'ar',
    icon: 'mdi-augmented-reality',
    storageUploadExt: ['.fbx', '.zip', '.glb'],
    contentType: ContentItemType.AR,
    textSpecs: 'GLB/FBX/ZIP max 50MB',
    fileValidator: {},
  },
  image: {
    title: 'Image',
    queryParam: 'image',
    icon: 'mdi-image',
    storageUploadExt: ['.jpg', '.jpeg', '.png'],
    contentType: ContentItemType.IMAGE,
    textSpecs: 'JPEG/PNG max 10MB',
    fileValidator: {},
  },
  video: {
    title: 'Video',
    queryParam: 'video',
    icon: 'mdi-video',
    storageUploadExt: ['.mp4'],
    contentType: ContentItemType.VIDEO,
    textSpecs: 'MP4 max 300MB',
    fileValidator: {},
  },
  // sound: {
  //   title: 'Sound',
  //   queryParam: 'sound',
  //   icon: 'mdi-surround-sound',
  //   storageUploadExt: ['.mp3', '.ogg'],
  //   contentType: ContentItemType.SOUND,
  //   textSpecs: 'OGG/MP3 max 100MB',
  //   fileValidator: {}
  // }
}

for (const creatorItemTypeName in creatorItemTypes) {
  creatorItemTypes[creatorItemTypeName].name = creatorItemTypeName
}

export default creatorItemTypes
