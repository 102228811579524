<script>
import { Sketch } from '@ckpack/vue-color'

let uid = 0

export default {
  name: 'ColorPickerInput',
  props: {
    modelValue: {
      default: '',
      type: String,
    },
    label: {
      default: '',
      type: String,
    },
    validator: {
      default: '^#([a-fA-F0-9]{3}|[a-fA-F0-9]{6}$|[a-fA-F0-9]{8})$',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data () {
    return {
      uid: `${this.$options.name}-${++uid}`,
      opened: false,
      pickerColor: {},
      pickerPosition: 'right',
      focused: false,
    }
  },
  computed: {
    value: {
      get: function () {
        return this.modelValue || ''
      },
      set: function (value) {
        this.$emit('update:modelValue', this.makeValidHexColor(value))
        setTimeout(() => {
          this.$emit('update:valid', this.valid)
        }, 10)
      },
    },
    valid: function () {
      // eslint-disable-next-line no-mixed-operators
      const matches =
        (this.validator &&
          typeof this.value === 'string' &&
          this.value.match(RegExp(this.validator))) ||
        null
      const valid =
        !this.validator || (Array.isArray(matches) && matches.length > 0)
      return valid
    },
  },
  emits: ['update:modelValue', 'update:valid', 'input'],
  methods: {
    clearClicked: function () {
      this.value = ''
    },

    onFocus: function () {
      if (!this.$refs.controlsWrapper) return

      const focused = this.$refs.controlsWrapper.contains(
        document.activeElement
      )

      if (focused && !this.opened) {
        this.showPicker()
      }
    },

    onBlur: function (event) {
      if (!this.$refs.controlsWrapper) return

      const focused = this.$refs.controlsWrapper.contains(event.relatedTarget)

      if (this.opened && !focused) {
        this.hidePicker()
      }
    },

    makeValidHexColor: function (color) {
      return color
        .replace(/[^#0-9A-Fa-f]/g, '') // Remove invalid characters
        .replace(/#+/, '#') // Remove multiple #
        .replace(/^(#[^#]+)#/, '$1') // Remove non-leading #
        .replace(/^([^#]{1})/, '#$1') // Add leading #
        .replace(/(.{9}).*/, '$1') // Limit to 9 characters
        .toUpperCase()
    },

    onInput: function (event) {
      event.target.value = this.makeValidHexColor(event.target.value)
    },

    previewClick ($event) {
      if (!$event.target.classList.contains('disabled')) {
        this.showPicker('left')
      }
    },
    showPicker: function (position) {
      this.pickerPosition = position
      this.opened = true
      this.pickerColor = this.value
      this.$refs.input.focus()
    },
    updateColor: function (color) {
      // eslint-disable-next-line no-mixed-operators
      this.value = (color.a === 1 && color.hex) || color.hex8
    },
    hidePicker: function () {
      this.opened = false
    },
  },
  components: {
    Sketch,
  },
  mounted () {},
}
</script>

<template>
  <div class="color-picker-input" :class="{ disabled: disabled }" >
    <label ref="label" :for="uid + '-input'" class="label" v-if="label">
      {{ label }}
    </label>

    <div class="controls-wrapper" tabindex="100" ref="controlsWrapper">
      <div ref="preview" class="preview" @click="previewClick"></div>

      <input
        :id="uid + '-input'"
        ref="input"
        type="text"
        :class="{ invalid: !valid }"
        class="input"
        v-model="value"
        tabindex="100"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
        placeholder="#RRGGBBAA"
        :disabled="disabled"
      />

      <div
        ref="picker"
        tabindex="101"
        class="picker"
        :class="pickerPosition"
        id="picker"
        v-show="opened"
        @focus="onFocus"
        @blur="onBlur"
      >
        <Sketch v-model="pickerColor" @update:modelValue="updateColor" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.color-picker-input {
  display: flex;
  flex-direction: column;
  position: relative;
}

.label {
  font: normal 14px 'Inter';
  font-size: 14px;
  color: white;
}

.color-picker-input.disabled .label {
  color: #667F92;
}

.controls-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 9px;
  gap: 14px;
}

.preview {
  width: 40px;
  height: 40px;
  background: v-bind(modelValue);
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.color-picker-input.disabled .preview {
  border-color: #667F92;
}

.color-picker-input:not(.disabled) .preview {
  cursor: pointer;
}

.input {
  height: 40px;
  width: 116px;
  background: #131e28;
  border: 1px solid #334656;
  border-radius: 8px;
  font: normal 16px 'Poppins';
  text-align: center;
}

input.invalid {
  border-bottom: 1px solid #eb4c5e;
}

.picker {
  all: unset;
  position: absolute;
  top: calc(100% + 11px);
  z-index: 5;
}
</style>
