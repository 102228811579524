<template>
  <v-text-field
    placeholder="Search"
    single-line
    hide-details="auto"
    prepend-inner-icon="mdi-magnify"
    clearable
    persistent-clear
    clear-icon="mdi-close"
    class="search-text-field"
  >
  </v-text-field>
</template>

<style scoped lang="scss">
.search-text-field {
  max-width: 282px;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #fff;
}

:deep(.v-field) {
  border-radius: 30px;
  height: 38px;
  padding-left: 11px;
  padding-right: 11px;

  .v-field__prepend-inner {
    font-size: 15px;
    color: #8b9fb0;

    .v-icon {
      opacity: 1;
      cursor: pointer;

      &:active {
        position: relative;
        top: 1px;
        left: 1px;
      }
    }
  }

  .v-field__field {
    padding: 0;
    min-height: 38px;

    input {
      height: 38px;
      font-size: 14px;
    }
    input::placeholder {
      color: #8b9fb0;
    }
  }

  .v-field__input {
    padding: 0 11px 0 11px;
    min-height: 38px;
  }

  .v-field__clearable {
    font-size: 15px;
    color: #fff;
    margin-left: 0;
    margin-right: 0;

    .v-icon {
      opacity: 1;
    }
  }

  .v-field__outline {
    display: none;
  }
}
</style>
