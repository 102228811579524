import axios from 'axios'

const API = {
  ax: axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  }),

  axs3: axios.create({}),

  request: function (
    endpoint,
    method = 'GET',
    data,
    authToken = null,
    progressCallback = null
  ) {
    return new Promise((resolve) => {
      const headers = {
        'Content-type': 'application/json',
        'X-App-Name': 'W3rlds CMS',
        'X-App-Version': process.env.VUE_APP_VERSION,
      }
      if (authToken) {
        headers.Authorization = `Bearer ${authToken}`
      }

      this.ax
        .request({
          url: endpoint,
          method,
          data,
          headers,
          onUploadProgress: progressCallback,
          timeout: 300000,
        })
        .then((response) => {
          resolve({
            response: response.data,
            options: {
              headers: response.headers,
            },
          })
        })
        .catch((error) => {
          resolve({
            // eslint-disable-next-line no-mixed-operators
            response: (error.response && error.response.data || { success: false, error: `${error}`, content: {} }),
            options: {
              statusCode: error.response ? error.response.status : null,
              headers: error.response ? error.response.headers : null,
            },
          })
        })
    })
  },

  s3request: function (url, file, metadata, progressCallback = null) {
    return new Promise((resolve) => {
      const headers = {
        'x-amz-meta-original_filename': metadata.original_filename,
      }

      this.axs3
        .request({
          url,
          method: 'PUT',
          data: file,
          headers,
          onUploadProgress: progressCallback,
        })
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  },
}

export { API }
