<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { User } from '@/helpers/api/models'
import { deepClone, apiCallWithState } from '@/utils/common'
import { notify } from '@kyvg/vue3-notification'
import isEqual from 'lodash-es/isEqual'
import { standardRules } from '@/helpers/validationRules'

import { Button, TabNav, Tabs, Tab, TextInput, LoadingIndicator } from '@/components/creator'
import { processError } from '@/helpers/errors'

const store = useStore()

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const { useActions } = useVuex(null, store)
const {
  apiUsersGet,
  apiUsersUpdate,
} = useActions([
  'apiUsersGet',
  'apiUsersUpdate',
])

const email = computed(() => store.state.auth.user.email)

const user = ref(deepClone(User))
const userSrc = ref(deepClone(User))
const userEdited = computed(() => !isEqual(user.value, userSrc.value))

const tab = ref(null)
const formPersonalValid = ref(null)
const formPasswordsValid = ref(null)

const passwordState = ref({
  old: null,
  new: null,
  edited: computed(() => {
    return passwordState.value.old !== null || passwordState.value.new !== null
  }),
})

async function loadProfile () {
  const apiResult = await apiUsersGet({
    email: email.value,
  })
  user.value = apiResult.content
  userSrc.value = deepClone(user.value)
}

async function saveProfile () {
  const requestData = deepClone(user.value)

  try {
    await apiCallWithState(
      apiUsersUpdate,
      { data: requestData },
      state,
      'isUpdating'
    )

    userSrc.value = deepClone(user.value)

    notify({
      type: 'success',
      text: 'Account settings updated',
    })
  } catch (e) {
    processError(e)
  }
}

async function changePassword () {
  const requestData = deepClone(userSrc.value)
  requestData.old_password = passwordState.value.old
  requestData.new_password = passwordState.value.new

  try {
    state.value.requestInProgress = true
    state.value.isUpdating = true

    await apiUsersUpdate({ data: requestData })

    notify({
      type: 'success',
      text: 'Password updated',
    })
    passwordState.value.old = null
    passwordState.value.new = null
  } catch (e) {
    processError(e)
  } finally {
    state.value.requestInProgress = false
    state.value.isUpdating = false
  }
}

function resetProfileForm () {
  user.value = deepClone(userSrc.value)
}

onMounted(async () => {
  try {
    state.value.requestInProgress = true
    state.value.isLoading = true

    loadProfile()
  } catch (e) {
    processError(e)
  } finally {
    state.value.requestInProgress = false
    state.value.isLoading = false
  }
})
</script>

<template>
  <div class="g-content g-shrink">
    <div class="g-content__header">
      <div class="g-tabs__wrapper">
        <TabNav :items="['Account']" v-model="tab"> </TabNav>
      </div>
    </div>
    <div class="g-content__inner g-shrink">
      <Tabs v-model="tab">
        <Tab value="Account">
          <LoadingIndicator :state="state" all />
          <div class="g-content__columns-wrapper g-shrink">
            <div class="g-content__column-first">
              <v-form
                class="form-personal"
                autocomplete="off"
                v-model="formPersonalValid"
              >
                <TextInput
                  v-model="user.first_name"
                  type="text"
                  label="First name"
                  :rules="[standardRules.required]"
                  autofocus
                />

                <TextInput
                  v-model="user.last_name"
                  type="text"
                  label="Last name"
                  :rules="[standardRules.required]"
                />

                <TextInput
                  v-model="user.email"
                  type="text"
                  label="Email"
                  disabled
                />

                <div class="g-controls__wrapper account-controls__wrapper">
                  <div class="g-controls__col-first">
                    <Button
                      class="g-controls__control"
                      @click="saveProfile"
                      primary
                      small
                      :disabled="
                        !formPersonalValid ||
                        state.requestInProgress ||
                        !userEdited
                      "
                    >
                      Save
                    </Button>
                    <Button
                      class="g-controls__control"
                      @click="resetProfileForm"
                      secondary
                      small
                      :disabled="state.requestInProgress || !userEdited"
                      :loading="state.isUpdating"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </v-form>

              <v-form
                class="form-passwords"
                autocomplete="off"
                v-model="formPasswordsValid"
              >
                <div class="change-password-title">Change password</div>

                <TextInput
                  v-model="passwordState.old"
                  type="password"
                  label="Current password"
                  :rules="[standardRules.required]"
                />

                <TextInput
                  v-model="passwordState.new"
                  type="password"
                  label="New password"
                  :rules="[standardRules.required]"
                />

                <div class="g-controls__wrapper account-controls__wrapper">
                  <div class="g-controls__col-first">
                    <Button
                      class="g-controls__control"
                      @click="changePassword"
                      primary
                      small
                      :disabled="
                        !formPasswordsValid ||
                        state.requestInProgress ||
                        !passwordState.edited
                      "
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </v-form>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  </div>

</template>

<style scoped lang="scss">
.account-controls__wrapper {
  margin-top: 32px;
}

.change-password-title {
  font: 600 20px/24px 'Inter';
  color: #ffffff;

  margin: 68px 0 25px;
}

</style>
