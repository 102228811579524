<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import CreateBtn from '@/components/btns/CreateBtn.vue'
import ArItems from '@/components/ar/ArItems.vue'
import { ViewModeSwitcher, Button } from '@/components/creator'
import Dialog from '@/components/common/dialogs/Dialog.vue'
import creatorItemViews from '@/helpers/creatorItemViews'
import SearchForm from '@/components/forms/SearchForm.vue'
import CustomDomainBrief from '@/components/custom_domains/CustomDomainBrief'
import { processError } from '@/helpers/errors'

const store = useStore()
const profileId = computed(() => store.state.auth.profile?.id)
const campaignId = ref(null)
const customDomainDialog = ref(null)
const customDomainBrief = ref(null)
const customDomainButton = ref(null)

const viewModeSwitcher = ref(null)

const { useActions } = useVuex(null, store)
const { apiCampaignsList } = useActions(['apiCampaignsList'])

onMounted(async () => {
  try {
    campaignId.value = (
      await apiCampaignsList()
    ).content[0]?.id
  } catch (error) {
    processError(error)
  }
})
</script>

<template>
  <div>
    <div class="g-toolbar">
      <div class="g-toolbar__first-col"></div>
      <div class="g-toolbar__last-col">
        <SearchForm />
        <CreateBtn v-if="profileId && campaignId" :to="{ name: 'ArNew' }">
          Add AR content
        </CreateBtn>
      </div>
    </div>

    <div class="g-content g-shrink">
      <div class="g-content__header">
        <div class="g-content-header__col-first">
          <h1 class="g-content-header__title">Your AR content</h1>
        </div>
        <div class="g-content-header__col-last">
          <div class="g-content-toolbar_wrapper">

            <div class="toolbar-buttons__wrapper">
              <Button
                v-if="profileId && campaignId"
                ghost
                small
                :to="{
                  name: 'CreatorViewerBranding',
                  params: { campaign_id: campaignId },
                }"
              >
                Custom viewer
              </Button>

              <Button
                v-if="profileId && campaignId"
                ghost
                small
                ref="customDomainButton"
                @click="customDomainDialog.show()"
              >
                Custom domain
              </Button>
            </div>

            <ViewModeSwitcher
              :default-view="creatorItemViews.cards"
              ref="viewModeSwitcher"
            />
          </div>
        </div>
      </div>
      <div class="g-content__inner g-shrink">
        <ArItems
          :viewModeSwitcher="viewModeSwitcher"
          :btnCreate="!!profileId && !!campaignId"
        />
      </div>
    </div>
    <Dialog
      class="custom-domain-dialog"
      ref="customDomainDialog"
      :blurred="false"
      :buttons="[]"
      :boundElement="customDomainButton?.$el"
      boundType="bottom right"
      :boundOffsetY="10"
    >
      <div class="custom-domain-dialog__hint">
        Connect your custom domain for yor AR items to be accessible with
        https://your.custom.domain/AR-item-ID
      </div>
      <CustomDomainBrief
        ref="customDomainBrief"
        class="custom-domain-brief"
        linkType="CAMPAIGN"
        :linkId="campaignId"
        v-model="customDomain"
      />
    </Dialog>
  </div>
</template>

<style scoped lang="scss">
.custom-domain-dialog > :deep(.wrapper) {
  width: 391px;
}

.custom-domain-dialog__hint {
  font-size: 12px;
  line-height: 20px;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.toolbar-buttons__wrapper {
  display: flex;
  flex-direction: row;
  gap: 0;
}
</style>
