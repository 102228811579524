<script setup>
import CreateBtn from '@/components/btns/CreateBtn.vue'
import UsersItems from '@/components/users/UsersItems.vue'
import SearchForm from '@/components/forms/SearchForm.vue'
import { ref, computed } from 'vue'
import {
  TabNav,
  Tabs,
  Tab,
  LoadingIndicator,
} from '@/components/creator'
import { AvatarCards } from '@/components/worlds/avatars'
import { getLoadingState } from '@/utils/common'

const tab = ref('Users')

const state = ref(getLoadingState())

const stateAll = computed(() => {
  return {
    requestInProgress: state.value.requestInProgress || avatarCards.value?.state.requestInProgress,
    isLoading: state.value.isLoading || avatarCards.value?.state.isLoading,
    isUpdating: state.value.isUpdating || avatarCards.value?.state.isUpdating,
  }
})

const avatarCards = ref(null)

</script>

<template>
  <div>
    <div class="g-title-wrapper">
      <h1 class="g-title">
        Platform Settings
      </h1>
    </div>

    <div class="g-content g-shrink">
      <div class="g-content__header">
        <div class="g-content-header__col-first">
          <div class="g-tabs__wrapper">
            <TabNav :items="['Users', 'Subscriptions', 'Default avatars']" v-model="tab" urlParam="tab">
            </TabNav>
          </div>
        </div>
        <div class="g-content-header__col-last">
          <div class="g-content-toolbar_wrapper" v-if="tab === 'Users'">
            <SearchForm />
            <CreateBtn :to="{ name: 'UsersItem', params: { id: 'new'} }">Create new user</CreateBtn>
          </div>
        </div>
      </div>
      <div class="g-content__inner g-shrink">
        <LoadingIndicator :state="stateAll" all />

        <Tabs
          v-model="tab"
        >
          <Tab value="Users">
            <UsersItems/>
          </Tab>
          <Tab value="Subscriptions">

          </Tab>
          <Tab value="Default avatars">
            <AvatarCards
              :platformAvatars="true"
              ref="avatarCards"
              returnTab="Default avatars"
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>
