<script setup>
import { ref } from 'vue'
import { useHtmlMinHeight } from '@/composables/htmlMinHeight'
import CreatorLogoLink from '@/components/links/CreatorLogoLink.vue'
import HeaderNav from '@/components/creator/HeaderNav.vue'
import { UserButton } from '@/components/creator'

const contentMinHeight = '350px'
const header = ref(null)
useHtmlMinHeight(header, parseInt(contentMinHeight))
// const avatarDefaultPurpleImg = require('@/images/avatar-default-purple.svg')
</script>

<template>
  <header class="header" ref="header">
    <CreatorLogoLink class="logo" />
    <HeaderNav class="nav" />
    <div class="support">
      <a class="link" href="https://docs.w3rlds.com/" target="_blank">Support</a>
    </div>
    <div class="user">
      <UserButton />
    </div>
  </header>
</template>

<style scoped lang="scss">
.header {
  font-family: 'Inter', sans-serif;
  color: #f9f9f9;
  padding: 20px 20px 0;
  display: flex;
  align-items: center;

  a {
    color: #f9f9f9;
  }
  a:hover {
    color: #00d7ff;
  }
}

.logo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-right: 20px;
}

.nav {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 50px;
  margin-right: 50px;
  width: 100%;
}

.support {
  font-weight: 500;
  font-size: 12px;
  margin-right: 30px;
  display: inline-flex;
}

.user {
  display: inline-flex;
}
</style>
