<script setup>
import _sortBy from 'lodash-es/sortBy'
import _findKey from 'lodash-es/findKey'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { computed, ref, onMounted } from 'vue'
// import { searchData } from '@/helpers/tables'
import creatorItemTypes from '@/helpers/creatorItemTypes'
import creatorItemViews from '@/helpers/creatorItemViews'
import ContentItemCards from '@/components/worlds/content/ContentItemCards.vue'
import ContentItemTable from '@/components/worlds/content/ContentItemTable.vue'
import { getLoadingState, apiCallWithState, encodeUriFrom, addSearchParamToUrl } from '@/utils/common'
import { LoadingIndicator } from '@/components/creator'
import { processError } from '@/helpers/errors'

const state = ref(getLoadingState())

const store = useStore()
const { useActions } = useVuex(null, store)
const { apiSpaceContentList, apiContentItemsListByIds } = useActions({
  apiSpaceContentList: 'apiSpaceContentList',
  apiContentItemsListByIds: 'apiContentItemsListByIds',
})

const rawData = ref([])

const route = useRoute()

const props = defineProps({
  spaceId: {
    type: String,
    required: true,
  },
  defaultView: {
    Object,
    required: true,
  },
  showLoadingIndicator: {
    type: Boolean,
    default: false,
  },
})

onMounted(async () => {
  try {
    const contentItems = (await apiCallWithState(apiSpaceContentList, { spaceId: props.spaceId }, state, 'isLoading')).content

    rawData.value = []
    if (!contentItems.length) return

    const contentIds = contentItems.map(a => a.id)

    const content = (await apiCallWithState(apiContentItemsListByIds, { ids: contentIds }, state, 'isLoading')).content

    for (const key in contentItems) {
      const contentItem = content.filter(
        (item) => item.id === contentItems[key].id
      )[0]

      if (!contentItem) continue

      const contentTypeTitle =
        creatorItemTypes[contentItem.type.toLowerCase()]?.title

      contentItems[key].contentItem = contentItem
      contentItems[key].contentItem.typeTitle = contentTypeTitle

      contentItems[key].routePath = 'StorageItem'
      contentItems[key].routeParams = { id: contentItem.id }

      const fromUrl = encodeUriFrom(addSearchParamToUrl(document.location.href, 'tab', 'Content'))
      contentItems[key].routeQuery = { from: fromUrl }
    }

    rawData.value = contentItems
  } catch (e) {
    processError(e)
  }
})

const data = computed(() => {
  let data = rawData.value.slice()
  /* if (route.query.search) {
    data = searchData(data, ['name'], route.query.search)
  } */
  if (route.query.sort === 'slot' || !route.query.sort) {
    data = _sortBy(data, (item) => item.name?.toLowerCase())
  } else if (route.query.sort === 'name') {
    data = _sortBy(data, (item) => item.contentItem.name)
  } else if (route.query.sort === 'type') {
    data = _sortBy(data, (item) => item.contentItem.type.toLowerCase())
  }
  if (
    route.query.type &&
    route.query.type !== creatorItemTypes.all.queryParam
  ) {
    const key = _findKey(creatorItemTypes, { queryParam: route.query.type })
    const type = key.toUpperCase()
    data = data.filter((item) => item.contentItem.type === type)
  }
  if (route.query.order === 'desc') data.reverse()
  return data
})

defineExpose({ state })
</script>

<template>
  <div class="content-items__wrapper">
    <LoadingIndicator :state="state" all v-if="props.showLoadingIndicator"/>

    <template
      v-if="
        route.query.view === creatorItemViews.cards.queryParam ||
        (!route.query.view &&
          props.defaultView?.queryParam === creatorItemViews.cards.queryParam)
      "
    >
      <ContentItemCards
        :items="data"
        class="content-items__inner"
      />
    </template>
    <template
      v-if="
        route.query.view === creatorItemViews.table.queryParam ||
        (!route.query.view &&
          props.defaultView?.queryParam === creatorItemViews.table.queryParam)
      "
    >
      <ContentItemTable
        :items="data"
        class="content-items__inner"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
.content-items__wrapper {
  display: flex;
  flex-direction: column;
}

.content-items__inner {
  flex: 1;
  padding: 2px;
}
</style>
