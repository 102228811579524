<script setup>
import { computed } from 'vue'

const props = defineProps([
  'blockchain',
  'networkType',
  'accountAddress',
  'tokenStatus',
  'showTokenStatus',
])

const accountAddressFormatted = computed(() => {
  if (props.accountAddress) {
    if (props.accountAddress.length > 20) {
      return `${props.accountAddress.slice(0, 12)}...${props.accountAddress.slice(-8)}`
    } else {
      return props.accountAddress
    }
  }

  return ''
})
</script>

<template>
  <div class="nft-status-panel">
    <div class="network-status">
      <div class="item item-blockchain">{{ props.blockchain }}</div> |
      <div class="item item-network-type">{{ props.networkType }}</div> |
      <div class="item item-account" :title="props.accountAddress">{{ accountAddressFormatted }}</div>
    </div>
    <div class="token-status" v-if="props.showTokenStatus">{{ props.tokenStatus }}</div>
  </div>
</template>

<style scoped lang="scss">
.nft-status-panel {
  height: 42px;
  display: flex;
  flex-direction: row;
  border-radius: 7px;
  border: 1px solid #091520;
  align-items: stretch;
  position: relative;
}

.network-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  font: normal 14px 'Poppins';
  color: #8b9fb0;
  background: #091520;
  border-radius: 7px;
  padding: 0 16px;
  gap: 5px;

  overflow: hidden;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.token-status {
  flex: 0;
  min-width: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #ffffff;
  font: 500 14px 'Inter';
}

.tooltiptext {
  display: none;
  padding: 5px 17px;
  padding-top: 5px;
  position: absolute;
  background: #091520;
  border-radius: 7px;
  padding-top: 10px;
  color: #8b9fb0;
  left: 0;
  right: 0;
  top: -1px;
  bottom: 0;
}

.item-account {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-account__icon {
  margin-left: 10px;
  cursor: pointer;
}

</style>
