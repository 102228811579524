<template>
  <div class="button">
    <button
      @click="onClick"
      :class="$attrs.class"
      :title="$attrs.title"
      :disabled="disabled"
    >
      <object
        v-if="iconData"
        type="image/svg+xml"
        v-bind:data="iconData"
        class="icon"
      ></object>
      <slot v-if="!isToolbar">
        {{ text }}
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button', // eslint-disable-line vue/no-reserved-component-names
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data: function () {
    return {
      isActive: false,
      icons: {
        close: require('@/images/icons/close.svg'),
        download: require('@/images/icons/download.svg'),
        edit: require('@/images/icons/edit.svg'),
        plus: require('@/images/icons/plus.svg'),
        search: require('@/images/icons/search.svg'),
        trash: require('@/images/icons/trash.svg'),
        upload: require('@/images/icons/upload.svg'),
        move: require('@/images/icons/move.svg'),
        copy: require('@/images/icons/copy.svg'),
        code: require('@/images/icons/code.svg'),
        prev: require('@/images/icons/prev.svg'),
        next: require('@/images/icons/next.svg'),
        show: require('@/images/icons/show.svg'),
        hide: require('@/images/icons/hide.svg'),
        redTrash: require('@/images/icons/redTrash.svg'),
      },
    }
  },
  computed: {
    iconData: function () {
      return this.icons[this.icon]
    },
    isToolbar: function () {
      return (
        this.$attrs.class && this.$attrs.class.split(' ').includes('toolbar')
      )
    },
  },
  methods: {
    onClick (event) {
      event.value = this.value
      this.$emit('click', event)
    },
  },
}
</script>

<style scoped>
.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button button {
  width: 100%;
  height: 100%;
  flex-direction: row;
  background: linear-gradient(270deg, #00fefe 0%, #28cbff 100%);
  border-radius: 16px;
  padding: 4px 16px 4px 16px;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.002em;
  color: #131e28;
}
.button button .icon {
  width: 16px;
  height: 16px;
  margin: 0px 8px 0px 0px;
  pointer-events: none;
}
.button button:hover {
  background: linear-gradient(270deg, #94ffff 0%, #6adbff 100%);
  cursor: pointer;
}
.button button:active {
  background: linear-gradient(270deg, #00f5f5 0%, #16b9ed 100%);
}
.button button:disabled {
  background: linear-gradient(270deg, #cbffff 0%, #caf2ff 100%);
  color: #b3bdd7;
}
.button button.gray {
  background: #eef4f9;
}
.button button.gray:hover {
  background: #e7ebf3;
}
.button button.danger {
  border: 2px solid #eb4c5e;
  background: white;
  color: #eb4c5e;
}
.button button.medium {
  font-size: 14px;
  border-radius: 32px;
  padding: 8px 32px 8px 32px;
}
.button button.medium .icon {
  width: 24px;
  height: 24px;
}
.button button.large {
  font-size: 19px;
  border-radius: 32px;
  padding: 12px 32px 12px 32px;
}
.button button.large .icon {
  width: 32px;
  height: 32px;
}
.button button.toolbar {
  background: none;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  padding: 0 0 0 0;
}
.button button.toolbar:hover {
  background: #e7ebf3;
}
.button button.toolbar .icon {
  width: 20px;
  height: 20px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
.button button.toolbar.medium {
  border-radius: 8px;
  width: 32px;
  height: 32px;
}
.button button.toolbar.medium .icon {
  width: 24px;
  height: 24px;
}
.button button.toolbar.large {
  border-radius: 8px;
  width: 48px;
  height: 48px;
}
.button button.toolbar.large .icon {
  width: 32px;
  height: 32px;
}
.button button.transparent {
  background: none;
}
.button button.transparent:hover {
  background: #e7ebf3;
}
.button button.gray {
  background: #e7ebf3;
}
.button button.gray:hover {
  background: #d8dbe2;
}
.button button.sq {
  border-radius: 8px;
}
.button button.b {
  border: 1px solid #d2d9e7;
  padding: 3px 15px 3px 15px;
}
.button button.b.medium {
  border: 1px solid #d2d9e7;
  padding: 7px 31px 7px 31px;
}
.button button.b.large {
  border: 1px solid #d2d9e7;
  padding: 11px 31px 11px 31px;
}
.button button.deepBlue {
  background: #262f56;
  color: #ffffff;
}
.button button.deepBlue:hover {
  background: #ffffff;
  color: #262f56;
  border: 1px solid #262f56;
}
.button button.deepBlueLite {
  background: #ffffff;
  color: #262f56;
  border: 1px solid #262f56;
}
.button button.deepBlueLite:hover {
  background: #262f56;
  color: #ffffff;
}
.button button.deepBlue,
.button button.deepBlue:hover,
.button button.deepBlueLite,
.button button.deepBlueLite:hover {
  font-size: 15px;
  font-weight: 500;
}
</style>
