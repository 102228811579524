<script setup>
</script>

<template>
  <div class="error-view-wrapper">
    <!-- <Header /> -->
    <router-view class="view"></router-view>
  </div>
</template>

<style scoped lang="scss">
.error-view-wrapper {
  font-family: 'Inter', sans-serif;
  align-self: center;
  padding: 15px;
}
</style>
