<script setup>
import { ref, computed, onMounted } from 'vue'

const props = defineProps({
  type: String,
  regular: Boolean,
  large: Boolean,
  icons: Array,
})
const control = ref(null)
const valid = ref(null)

const showPassword = ref(false)

const icons = computed(() => {
  let icons = []
  if (props.type === 'password') {
    icons.push(showPassword.value ? 'mdi-eye' : 'mdi-eye-off')
  }

  if (props.icons) {
    icons = icons.concat(props.icons)
  }

  return icons
})

function onIconClick (index) {
  if (icons.value[index] === 'mdi-eye' || icons.value[index] === 'mdi-eye-off') {
    showPassword.value = !showPassword.value
  }

  emit('iconClick', icons.value[index])
}

onMounted(() => {
  const iconAppend = control.value.$el.querySelector('.v-input__append .v-icon')
  const iconAppendInner = control.value.$el.querySelector('.v-field__append-inner .v-icon')

  iconAppend?.addEventListener('mouseenter', () => {
    emit('iconMouseEnter', icons.value[0], iconAppend)
  })
  iconAppend?.addEventListener('mouseleave', () => {
    emit('iconMouseLeave', icons.value[0], iconAppend)
  })

  iconAppendInner?.addEventListener('mouseenter', () => {
    emit('iconMouseEnter', icons.value[1], iconAppendInner)
  })
  iconAppendInner?.addEventListener('mouseleave', () => {
    emit('iconMouseLeave', icons.value[1], iconAppendInner)
  })
})

defineExpose({
  valid,
})

const emit = defineEmits(['iconClick', 'iconMouseEnter', 'iconMouseLeave'])
</script>

<template>
  <v-text-field
    ref="control"
    variant="underlined"
    :append-icon="icons[0]"
    :append-inner-icon="icons[1]"
    :type="props.type === 'password' && !showPassword ? 'password' : 'text'"
    @click:append="onIconClick(0)"
    @click:append-inner="onIconClick(1)"
    persistent-hint
    color="#00d7ff"
    v-bind="$attrs"
    :class="{
      regular: props.regular || (!props.regular && !props.large),
      large: props.large,
    }"
  >
  </v-text-field>
</template>

<style scoped lang="scss">
:deep(.v-field__outline > label) {
  color: #acc2d2;
  opacity: 1;
  font: normal 12px/18px Inter;
}

:deep(.v-field-label) {
  color: #acc2d2;
  opacity: 1;
}

:deep(.v-field__input) {
  color: #fff;
}

:deep(.v-field--disabled) {
  opacity: 1;
}

.v-input--disabled {
  :deep(.v-field__outline > label),
  :deep(.v-field-label) {
    color: #667f92;
  }

  :deep(.v-field--variant-underlined .v-field__outline::before) {
    border-image: none;
  }

  :deep(.v-field__input) {
    color: #667f92;
  }
}

:deep(.v-messages > .v-messages__message) {
  font: normal 10px 'Inter';
  transition: none;
  color: #909eaf;
}

.v-input--error:not(.v-input--disabled)
  :deep(.v-input__details .v-messages > .v-messages__message) {
  color: rgb(201, 102, 121);
}

:deep(.v-input__append),
:deep(.v-field__append-inner) {
  position: absolute;
  right: 6px;
  bottom: 6px;

  .v-icon {
    width: 22px;
    height: 22px;
    font-size: 22px;
  }
}

:deep(.v-field__append-inner) {
  right: 32px;
  top: 3px;
}

:deep(.v-field__field) {
  padding-right: 34px;
}

.v-input {
  position: relative;
  margin-bottom: 7px;
  flex: 0 1 auto;
}

:deep(.v-icon) {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

:deep(.v-field--variant-underlined .v-label.v-field-label--floating) {
  transform: translateY(0px);
}

:deep(.v-input__details) {
  margin-top: 6px;
  margin-bottom: 0px;
  min-height: 0;
}

.regular {
  :deep(.v-field__field) {
    padding-top: 20px;
  }

  :deep(.v-field__field > input) {
    font: normal 14px Inter;
  }

  :deep(.v-field-label) {
    font: normal 14px Inter;
  }
}

.large {
  :deep(.v-field__field) {
    padding-top: 26px;
  }

  :deep(.v-field__field > input) {
    font: normal 18px Inter;
  }

  :deep(.v-field-label) {
    font: normal 18px Inter;
  }
}
</style>
