<script setup>
import { computed } from 'vue'
import { Radio, RadioGroup } from '@/components/creator'

const props = defineProps({
  periods: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  horizontal: {
    type: Boolean,
    required: false,
    default: false,
  },
  modelValue: {
    type: String,
    required: true,
  },
})

const radioGroupIsInline = computed(() => {
  return props.horizontal
})

const emit = defineEmits(['update:modelValue'])
</script>

<template>
  <div class="billing-period">
    <div class="title" v-if="props.title">
      {{ props.title }}
    </div>
    <RadioGroup
      class="radio-group"
      :inline="radioGroupIsInline"
      v-bind:modelValue="props.modelValue"
      @change="(event) => emit('update:modelValue', event.target.value)"
      >
      <Radio
        :value="periodName"
        label="Pay annually"
        v-for="(period, periodName) in props.periods"
        :key="periodName"
        class="radio-group__item"
        :disabled="period.disabled"
      >
        <template v-slot:label>
          <div class="period-item">
            <span class="period-item__title">{{ period.title }}</span>
            <span class="period-item__discount" v-if="period.discount">{{ period.discount }}</span>
            <span class="period-item__price" v-if="period.price">{{ period.price }}</span>
          </div>
        </template>
      </Radio>
    </RadioGroup>
  </div>
</template>

<style scoped lang="scss">

.period-item__discount {
  font: 700 12px 'Inter';
  color: #091520;
  background: #00D7FF;
  border-radius: 10px;
  padding: 2px 4px;
  margin-left: 6px;
}

.period-item__title {
  display: inline-block;
}
</style>
