function isObject (item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

function isArray (item) {
  return item && typeof item === 'object' && Array.isArray(item)
}

function deepClone (source) {
  let output = null

  if (isObject(source)) {
    output = {}
    for (const key in source) {
      output[key] = deepClone(source[key])
    }
  }

  if (isArray(source)) {
    output = []
    source.forEach(function (item) {
      output.push(deepClone(item))
    })
  }

  if (!isArray(source) && !isObject(source)) {
    output = source
  }

  return output
}

function htmlDecode (input) {
  const doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

function camelCaseToSpaces (str) {
  if (!str) { return str }
  const converted = str.replace(/([A-Z])/g, ' $1').trim()
  const capitalized = converted.charAt(0).toUpperCase() + converted.slice(1).toLowerCase()
  return capitalized
}

function firstLetterToUpperCase (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function capitalize (str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

function getFileExtension (filename) {
  return '.' + filename.split('.').pop().toLowerCase()
}

async function readFileToBase64 (file) {
  const result = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  return result.replace('data:application/octet-stream;base64,', '')

  /* let tmp = (new TextDecoder('utf-8')).decode(result)
  tmp = decodeURIComponent(encodeURIComponent(tmp))
  tmp = Buffer.from(tmp).toString('base64')

  return tmp */
}

function getPreviewUrlByContentItem (item) {
  if (!item.media) {
    throw Error('No media')
  }

  if (item.media.poster) {
    return getContentUrl(item.media.poster)
  }

  if (item.thumbnail) {
    return getContentUrl(item.thumbnail)
  }

  if (item.media.image) {
    return getContentUrl(item.media.image)
  }

  return ''
}

function getContentUrl (filename) {
  return `${process.env.VUE_APP_CONTENT_BASE_URL}/${filename}`
}

function timestampToDate (timestamp) {
  return new Date(timestamp * 1000)
}

function timestampToStr (timestamp) {
  return timestampToDate(timestamp).toLocaleString()
}

function copyToClipboard (text) {
  navigator.clipboard.writeText(text)
}

async function wait (timeout) {
  await new Promise(resolve => setTimeout(resolve, timeout))
}

function downloadDataAsFile (data, filename) {
  const element = document.createElement('a')

  element.setAttribute('href', 'data:text/plain;base64,' + data)
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

async function apiCallWithState (method, params, state, stateAction) {
  try {
    state.value.requestInProgress = true
    state.value[stateAction] = true
    const apiResultTariffFunctions = await method(params)
    return apiResultTariffFunctions
  } finally {
    state.value[stateAction] = false
    state.value.requestInProgress = false
  }
}

async function apiCallAllWithState (methods, state, stateAction) {
  try {
    state.value.requestInProgress = true
    state.value[stateAction] = true
    const apiResultTariffFunctions = await Promise.all(methods)
    return apiResultTariffFunctions
  } finally {
    state.value[stateAction] = false
    state.value.requestInProgress = false
  }
}

function getLoadingState () {
  return {
    requestInProgress: false,
    isLoading: false,
    isUpdating: false,
    isDeleting: false,
  }
}

function getPeriodByBillingInterval (billingPeriod) {
  if (billingPeriod === 'DAY') {
    return 'day'
  }

  if (billingPeriod === 'MONTH') {
    return 'month'
  }

  if (billingPeriod === 'YEAR') {
    return 'year'
  }

  throw Error(`Unknown billing interval: ${billingPeriod}`)
}

function compareBillingPeriods (a, b) {
  const periods = ['DAY', 'MONTH', 'YEAR']

  const aIndex = periods.indexOf(a)
  const bIndex = periods.indexOf(b)

  if (aIndex === bIndex) {
    return 0
  }

  if (aIndex > bIndex) {
    return 1
  }

  return -1
}

function encodeUriFrom (from) {
  return encodeURIComponent(from)
}

function decodeUriFrom (from) {
  const base = window.location.protocol + '//' + window.location.host
  const url = new URL(decodeURIComponent(from), base)

  const query = {}
  for (const [key, value] of url.searchParams.entries()) {
    query[key] = value
  }

  return { path: url.pathname, query }
}

function addSearchParamToUrl (url, param, value) {
  const urlObj = new URL(url)
  urlObj.searchParams.append(param, value)

  return urlObj.toString()
}

function removeSearchParamFromUrl (url, param) {
  const urlObj = new URL(url)
  urlObj.searchParams.delete(param)

  return urlObj.toString()
}

function getSearchParamFromUrl (url, param) {
  const urlObj = new URL(url)
  return urlObj.searchParams.get(param)
}

function isValidUrl (string) {
  try {
    // eslint-disable-next-line no-unused-vars
    const urlObj = new URL(string)
    return true
  } catch (_) {
    return false
  }
}

export {
  deepClone,
  htmlDecode,
  camelCaseToSpaces,
  firstLetterToUpperCase,
  capitalize,
  getFileExtension,
  getContentUrl,
  getPreviewUrlByContentItem,
  timestampToDate,
  timestampToStr,
  copyToClipboard,
  wait,
  downloadDataAsFile,
  readFileToBase64,
  apiCallWithState,
  apiCallAllWithState,
  getLoadingState,
  getPeriodByBillingInterval,
  compareBillingPeriods,
  encodeUriFrom,
  decodeUriFrom,
  addSearchParamToUrl,
  removeSearchParamFromUrl,
  getSearchParamFromUrl,
  isValidUrl,
}
