<script setup>
import { onMounted } from 'vue'

defineProps({
  paymentInfo: {
    type: Object,
    required: true,
  },
})

function getPaymentTypeImage (paymentType, paymentTypeBrand) {
  paymentType = paymentType.toLowerCase()

  let path = ''
  switch (paymentType) {
    case 'card':
      try {
        path = require(`@/images/icons/payments/${paymentTypeBrand}.png`)
      } catch (e) {
        path = require('@/images/icons/payments/card.png')
        console.warn(`Unknown card brand: ${paymentTypeBrand}`)
      }
      break
    default:
      path = ''
      break
  }
  return path ? `background: url(${path}) no-repeat center center; background-size: auto 11px;background-color: white` : ''
}

onMounted(() => {
})

const emit = defineEmits(['edit'])
</script>

<template>
  <div class="payment-method-existing">
    <div class="column-logo" :style="getPaymentTypeImage(paymentInfo.type, paymentInfo.brand)"></div>
    <div class="column-info">
      <div class="last4">
        {{ `Card ending in ${paymentInfo.last4}` }}
      </div>

      <div class="card-expiry">
        {{ `Expires ${paymentInfo.exp_month}/${paymentInfo.exp_year}` }}
      </div>
    </div>

    <div class="column-actions">
      <a href="#" class="link-edit" @click="emit('edit')">Edit</a>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .payment-method-existing {
    display: flex;
    flex-direction: row;
    padding: 15px;
    gap: 15px;
    border: 1px solid #334656;
    border-radius: 8px;
  }

  .column-logo {
    min-width: 50px;
    flex: 0;
    height: 24px;
    border-radius: 2px;
    align-self: flex-start;
    margin-top: 4px;
  }

  .column-info {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .column-actions {
    min-width: 56px;
    flex: 0;
    text-align: right;
  }

  .last4 {
    font: 400 14px 'Poppins';
    color: #FFFFFF;
  }

  .expire,
  .billing-email {
    font: 400 14px 'Poppins';
    color: #667F92;
  }

  .link-edit {
    font: 400 14px 'Inter';
    color: #00D7FF;
    text-decoration: none;
  }

  .card-expiry {
    font: 400 14px 'Poppins';
    color: #667F92;
  }
</style>
