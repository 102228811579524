<template>
  <v-textarea variant="outlined" :hide-details="true"> </v-textarea>
</template>

<style scoped lang="scss">
.v-input {
  padding-top: 20px;
}

:deep(.v-field__outline .v-field-label) {
  color: #5a7287;
  opacity: 1;
  font: normal 12px Poppins;
}

:deep(.v-field__field > label) {
  font-size: 18px;
  color: #5a7287;
  opacity: 1;
  left: -8px;
  top: 6px;
  font: normal 14px Poppins;
}

:deep(.v-field__field) {
  padding-top: 10px;
  padding-bottom: 2px;
}

:deep(.v-field__field > .v-field__input) {
  font: normal 14px Poppins;
  padding-inline: 12px;
}

:deep(.v-field--variant-outlined .v-field__outline__notch::before) {
  opacity: var(--v-field-border-opacity) !important;
}

:deep(.v-field--variant-outlined .v-label.v-field-label--floating) {
  position: relative;
  left: -17px;
  top: -15px;
}
</style>
