const Close = {
  name: 'btnClose',
  title: 'Close',
  icon: '',
  event: 'close',
  variant: 'secondary',
}

const Save = {
  name: 'btnSave',
  title: 'Save',
  icon: '',
  event: 'save',
  variant: 'primary',
}

const Yes = {
  name: 'btnYes',
  title: 'Yes',
  icon: '',
  event: 'yes',
  variant: 'primary',
}

const No = {
  name: 'btnNo',
  title: 'No',
  icon: '',
  event: 'no',
  variant: 'secondary',
}

const Ok = {
  name: 'btnOk',
  title: 'Ok',
  icon: '',
  event: 'ok',
  variant: 'primary',
}

const DeletePrimary = {
  name: 'btnDelete',
  title: 'Delete',
  icon: '',
  event: 'delete',
  variant: 'alert primary',
}

const Delete = {
  name: 'btnDelete',
  title: 'Delete',
  icon: '',
  event: 'delete',
  variant: 'alert',
}

const Cancel = {
  name: 'btnCancel',
  title: 'Cancel',
  icon: '',
  event: 'cancel',
  variant: 'secondary',
}

const CreatePrimary = {
  name: 'btnCreate',
  title: 'Create',
  icon: '',
  event: 'create',
  variant: 'primary',
}

const SelectPrimary = {
  name: 'btnSelect',
  title: 'Select',
  icon: '',
  event: 'select',
  variant: 'primary',
}

export {
  Yes,
  Save,
  Delete,
  DeletePrimary,
  No,
  Ok,
  Close,
  Cancel,
  CreatePrimary,
  SelectPrimary,
}
