<script setup>
import { ref } from 'vue'

const wrapper = ref(null)

const props = defineProps({
  fileInput: Object,
  fileSpecs: String,
  disabled: Boolean,
})

function dragover () {
  wrapper.value.classList.add('ondrag')
}

function dragleave () {
  wrapper.value.classList.remove('ondrag')
}

function dropInputChanged (event) {
  wrapper.value.classList.remove('ondrag')

  const droppedFiles = event.dataTransfer.files
  if (!droppedFiles) return

  if (!props.fileInput.checkExtension(droppedFiles[0])) return

  props.fileInput.changed(droppedFiles[0])
}
</script>

<template>
  <div
    class="file-drop-wrapper"
    @dragover.prevent="dragover"
    @dragleave="dragleave"
    v-cloak
    @drop.prevent="dropInputChanged"
    ref="wrapper"
  >
    <v-icon class="drop-icon">mdi-cloud-upload-outline</v-icon>

    <div class="title">
      Drop file here<br />
      <span class="title-small">or</span>
    </div>

    <label
      :for="props.fileInput?.fileInputId"
      class="btn-file"
      :disabled="disabled"
      >Select file</label
    >

    <div class="file-specs">{{ props.fileSpecs }}</div>
  </div>
</template>

<style scoped lang="scss">
.file-drop-wrapper {
  border: 1px dashed #b3bdd7;
  border-radius: 17px;
  padding: 22px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ondrag {
  background: rgba(255, 255, 255, 0.1);
}

.drop-icon,
.title,
.title-small,
.file-specs {
  color: #909eaf;
}

.title {
  font: normal 16px 'Inter';
  margin-top: 19px;
  color: #909eaf;
}

.title-small {
  font-size: 12px;
  margin-top: 19px;
}

.btn-file {
  font: normal 12px 'Poppins';
  background: #ffffff;
  border-radius: 32px;
  padding: 3px 23px;
  margin-top: 5px;
  color: #131e28;
  cursor: pointer;
}

.file-specs {
  font: normal 12px 'Inter';
  margin-top: 19px;
}
</style>
