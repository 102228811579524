<script setup>
import { ref, onMounted, computed, nextTick } from 'vue'
import { FeaturesTable, BillingPlansCards, BillingPeriod } from '@/components/creator/billing_plans'
import { DowngradeDialog } from '@/components/creator/dialogs'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { apiCallAllWithState, apiCallWithState, getLoadingState } from '@/utils/common'
import { PaymentDetails } from '@/components/creator/payments'
import { processError } from '@/helpers/errors'

const store = useStore()

const pricePeriod = ref('YEAR')
const downgradeDialog = ref(null)
const paymentDetailsDialog = ref(null)

const userActivePeriodEnd = ref(null)

const selectedSubscription = ref()
const subscriptionFeatures = ref()
const billingPlans = ref()
const userSubscription = ref()
const isUserBillingPlanFree = ref(null)

const state = ref(getLoadingState())

const dataLoaded = computed(() => {
  return billingPlans.value && subscriptionFeatures.value
})

const mailContactSales = 'hello@arhead.io'

const { useActions } = useVuex(null, store)
const {
  apiBillingFeaturesList,
  apiBillingPlansList,
  apiUserSubscriptionSet,
  apiUserSubscriptionInfoGet,
} = useActions({
  apiBillingFeaturesList: 'apiBillingFeaturesList',
  apiBillingPlansList: 'apiBillingPlansList',
  apiUserSubscriptionSet: 'apiUserSubscriptionSet',
  apiUserSubscriptionInfoGet: 'apiUserSubscriptionInfoGet',
})

async function chooseSubscription (subscription) {
  selectedSubscription.value = subscription
  await nextTick()

  const currentSubscriptionMonthPrice = store.state.auth.subscription.billing_plan.price.month

  if (subscription.price[pricePeriod.value.toLowerCase()] === -1) {
    // upon request
    window.location.href = `mailto:${mailContactSales}`
    return
  }

  if (currentSubscriptionMonthPrice > subscription.price.month) {
    // downgrade subscription
    const result = await downgradeDialog.value.show()
    if (result === 'ok') {
      if (changeSubscription()) {
        emit('switched', subscription, pricePeriod.value)
      }
    }
    return
  }

  if (currentSubscriptionMonthPrice < subscription.price.month) {
    // upgrade subscription

    const result = await paymentDetailsDialog.value.show()
    if (result === 'ok') {
      emit('switched', subscription, pricePeriod.value.toLowerCase())
    }
    return
  }

  /* const comparePeriods = compareBillingPeriods(userSubscriptionInfo.value.billing_period, pricePeriod.value)
  if (comparePeriods === -1) {
    // upgrade period

    return
  }

  if (comparePeriods === 1) {
    // downgrade period
    return
  } */

  // same period
  throw new Error('no changes')
}

async function changeSubscription () {
  try {
    await apiCallWithState(apiUserSubscriptionSet, { subscriptionId: selectedSubscription.value.id, billingPeriod: pricePeriod.value }, state, 'isUpdating')
    return true
  } catch (e) {
    processError(e)
    return false
  }
}

onMounted(async () => {
  const results = await apiCallAllWithState([
    apiBillingFeaturesList(),
    apiBillingPlansList(),
    apiUserSubscriptionInfoGet(),
  ], state, 'isLoading')

  subscriptionFeatures.value = results[0].content
  billingPlans.value = results[1].content
  userSubscription.value = results[2].content

  isUserBillingPlanFree.value = store.state.auth.subscription.billing_plan.price.month === 0

  if (!isUserBillingPlanFree.value) {
    userActivePeriodEnd.value = new Date(userSubscription.value.date_end * 1000)
  }
})

defineExpose({ state })
const emit = defineEmits(['switched'])
</script>

<template>
  <div class="subscription-switcher" v-if="dataLoaded">
    <BillingPeriod
      :periods="{ YEAR: { title: 'Annually', discount: '-20%'}, MONTH: { title: 'Monthly'}}"
      horizontal
      v-model="pricePeriod"
    />

    <BillingPlansCards
      :billingPlans="billingPlans"
      :pricePeriod="pricePeriod"
      :userSubscription="userSubscription.billing_plan"
      @choose="chooseSubscription"
    />

    <h2 class="compare-title">Compare all features</h2>

    <FeaturesTable
      :features="subscriptionFeatures"
      :billingPlans="billingPlans"
    />

    <DowngradeDialog
      ref="downgradeDialog"
      v-if="selectedSubscription && !isUserBillingPlanFree"
      :newSubscription="selectedSubscription"
      :subscriptionPeriodEnd="userActivePeriodEnd"
      :subscriptionPeriod="pricePeriod"
      :newSubscriptionPeriod="pricePeriod"
    />

    <PaymentDetails
      ref="paymentDetailsDialog"
      v-if="selectedSubscription"
      :selectedBillingPeriod="pricePeriod"
      :newSubscription="selectedSubscription"
    />
  </div>

</template>

<style scoped lang="scss">
.billing-period {
  display: table;
  margin: auto;
}

.compare-title {
  margin-top: 54px;
  font: 600 20px/24px 'Inter';
  text-align: center;
  color: #FFFFFF;
}

.billing-plans-cards {
  margin-top: 26px;
}

.subscription-features-table {
  max-width: 1058px;
  margin: 30px auto 0;
}

.billing-period :deep(.v-selection-control:last-child) {
  margin-left: 24px;
}
</style>
