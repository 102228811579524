<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import creatorItemTypes from '@/helpers/creatorItemTypes'

const props = defineProps(['defaultFilter', 'exclude'])

const route = useRoute()

const items = computed(() => {
  const result = {}
  for (const key in creatorItemTypes) {
    if (!props.exclude?.includes(key)) {
      result[key] = creatorItemTypes[key]
    }
  }
  return result
})

function isSelected (item) {
  return (
    route.query.type === item.queryParam ||
    (!route.query.type && item === props.defaultFilter)
  )
}
</script>

<template>
  <div class="content-filter-wrapper">
    <template v-for="(item, key) in items" :key="key">
      <v-btn
        class="btn"
        variant="outlined"
        :to="{ name: '', query: { ...route.query, type: item.queryParam } }"
        :class="{ selected: isSelected(item) }"
        :active="false"
      >
        {{ item.title }}
      </v-btn>
    </template>
  </div>
</template>

<style scoped lang="scss">
.content-filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0;
}

.types {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 7px 0;
}

.views {
  white-space: nowrap;
  margin-left: 15px;
}

.btn {
  font-family: 'Inter' !important;
  border-color: transparent !important;
  border-radius: 30px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  height: auto !important;
  text-transform: none !important;
  padding: 1.5px 13px !important;
  min-width: 56px !important;
  letter-spacing: 0 !important;
  color: #e8ebf8 !important;
}

.selected {
  border: 1px solid #00d7ff !important;
}
</style>
