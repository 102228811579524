<script setup>
import { computed } from 'vue'
import { BillingPlanCard } from '@/components/creator/billing_plans'
import { deepClone } from '@/utils/common'

const props = defineProps({
  billingPlans: {
    type: Object,
    required: true,
  },
  pricePeriod: {
    type: String,
    required: true,
  },
  userSubscription: {
    type: Object,
    required: false,
  },
})

const billingPlansSorted = computed((a, b) => {
  const billingPlansCopy = deepClone(props.billingPlans)

  return billingPlansCopy.sort((a, b) => {
    if (a.order < b.order) {
      return -1
    }
    if (a.order > b.order) {
      return 1
    }
    return 0
  })
})

const emit = defineEmits(['choose'])

</script>

<template>
  <div class="billing-plans-cards">
    <BillingPlanCard
      v-for="billingPlan of billingPlansSorted"
      :key="billingPlan.id"
      :billingPlan="billingPlan"
      :pricePeriod="props.pricePeriod"
      :isCurrentSubscription="props.userSubscription?.name === billingPlan.name"
      @choose="emit('choose', billingPlan)"
    />
  </div>
</template>

<style scoped lang="scss">
.billing-plans-cards {
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: center;
}
</style>
