<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ContentItemType } from '@/helpers/api/models'

const props = defineProps({
  title: String,
  link: String,
  routePath: String,
  routeParams: Object,
  routeQuery: Object,
  previewPoster: String,
  previewSrc: String,
  label: String,
  cardWidth: String,
  cardHeight: String,
  contentType: String,
  selected: Boolean,
  menu: Object,
  menuClickArg: Object,
})

const router = useRouter()

const menuIcon = ref()

const cardClick = () => {
  if (props.routePath) {
    router.push({
      name: props.routePath,
      params: props.routeParams,
      query: props.routeQuery,
    })
  }

  emit('click')
}

function menuClick () {
  const rect = menuIcon.value.getBoundingClientRect()
  props.menu.show(rect.right, rect.bottom + 3, props.menuClickArg)
}

const types3D = [
  ContentItemType.AR,
  ContentItemType.AVATAR,
  ContentItemType.GLB,
]

const modelViewerStyle = computed(() => `width:${props.cardWidth}`)
const wrapperStyle = computed(
  () => `width:${props.cardWidth}; height:${props.cardHeight}`
)
const is3D = computed(() => types3D.includes(props.contentType))
const icon = computed(() => {
  switch (props.contentType) {
    case ContentItemType.SOUND:
      return 'mdi-volume-high'
    case ContentItemType.VIDEO:
      return 'mdi-video'
    default:
      return 'mdi-file'
  }
})

const emit = defineEmits(['click'])

</script>

<template>
  <div class="card-wrapper" :style="wrapperStyle" :class="{ selected: selected }">
    <a :href="link" target="_blank" v-if="link" class="card-link">
      <v-icon>mdi-link-variant</v-icon>
    </a>

    <div class="card-menu" ref="menuIcon" v-if="menu" @click.stop="menuClick">
      <v-icon size="24">mdi-dots-horizontal</v-icon>
    </div>

    <div class="card-preview-wrapper" @click="cardClick">
      <model-viewer
        v-if="is3D && !previewPoster"
        loading="eager"
        :src="previewSrc"
        :poster="previewPoster"
        :style="modelViewerStyle"
        environment-image="neutral"
        class="item-preview-model"
      ></model-viewer>
      <img
        v-else-if="previewPoster"
        loading="lazy"
        class="item-preview-image"
        :src="previewPoster"
      />
      <v-icon class="item-preview-icon" v-else>{{ icon }}</v-icon>
      <div class="card-preview-overlay"></div>
      <div class="card-label" v-if="label">{{ label }}</div>
    </div>
    <div class="card-title-wrapper">
      <div class="card-title" @click="cardClick">{{ title }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-wrapper {
  background: linear-gradient(
    158.84deg,
    rgba(255, 255, 255, 0.24) -52.29%,
    rgba(255, 255, 255, 0) 103.57%
  );
  filter: drop-shadow(-5.88385px 6.53761px 3.92256px rgba(0, 0, 0, 0.08));
  // backdrop-filter: blur(29.4192px);
  border-radius: 8.49889px;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding-bottom: 8px;

  &:hover,
  &.selected {
    outline: 2px solid #00d7ff;
  }
}

.card-preview-wrapper {
  display: flex;
  flex: 1;
  position: relative;
  min-height: 0;
  margin: 13px 14px 0;
}

.card-preview-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.item-preview-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item-preview-model {
  margin: 0px;
  width: 100%;
  height: 100%;
}

.item-preview-icon {
  width: 100%;
  height: 100%;
}

.card-title-wrapper {
  height: 20.8%;
  margin: 8px 14px 0;
}
.card-title {
  font: normal 12px/16px 'Poppins';
  color: white;
  cursor: pointer;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-link,
.card-menu {
  position: absolute;
  right: 8px;
  top: 7px;
  z-index: 10;

}

.card-link {
  width: 21px;
  height: 21px;
  background: #091520;
  border-radius: 10px;
  color: #acc2d2;

  > i {
    font-size: 15px;
    position: absolute;
    top: 2px;
    left: 3px;
  }
}

.card-menu {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  color: white;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #575757;
  }
}

.card-label {
  background: #131e28;
  border-radius: 8.5px;
  font: 400 8px/12px 'Poppins';
  color: white;
  position: absolute;
  right: 5px;
  bottom: 3px;
  padding: 2px 0 1px;
  min-width: 51px;
  text-align: center;
}

/* themes */
.theme-worlds {
  .card-preview-wrapper {
    margin: 0;
  }

  .card-title {
    font-size: 14px;
  }
}
</style>
