<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { PaymentMethod } from '@/components/creator/payments/stripe'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { LoadingIndicator } from '@/components/creator'
import { getLoadingState } from '@/utils/common'
import { processError } from '@/helpers/errors'

const clientSecret = ref()
const paymentMethodForm = ref(null)

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiPaymentCreateSetupIntent,
} = useActions([
  'apiPaymentCreateSetupIntent',
])

const dialog = ref(null)

const state = ref(getLoadingState())

const props = defineProps({
  title: String,
})

const dialogButtons = ref([
  [
    DialogButtons.Cancel,
    {
      ...DialogButtons.Save,
      onClick: () => { save() },
    },
  ],
])

const show = async () => {
  let result = {}

  try {
    state.value.requestInProgress = true
    state.value.isLoading = true

    const dialogPromise = dialog.value.show()
    const apiResult = await apiPaymentCreateSetupIntent()
    clientSecret.value = apiResult.content.client_secret

    state.value.requestInProgress = false
    state.value.isLoading = false

    result = await dialogPromise
  } catch (error) {
    processError(error)
    result = {
      result: false,
      error,
    }
    dialog.value.close()
  } finally {
    clientSecret.value = ''
    state.value.requestInProgress = false
    state.value.isLoading = false
  }

  return result
}

async function save () {
  let result
  try {
    result = await paymentMethodForm.value.formSubmit()
  } catch (error) {
    processError(error)
    result = {
      result: false,
      error,
    }
  }

  dialog.value.close(result)
}

onMounted(() => {

})

defineExpose({ show })
</script>

<template>
  <Dialog
    :title="props.title || 'Payment method'"
    ref="dialog"
    :buttons="dialogButtons"
    class="payment-dialog__wrapper"
  >
    <LoadingIndicator loading :state="state" />
    <PaymentMethod
      :clientSecret="clientSecret"
      v-if="clientSecret"
      ref="paymentMethodForm"
    />
  </Dialog>
</template>

<style lang="scss" scoped>

.payment-dialog__wrapper > :deep(.wrapper) {
  min-width: 339px;
}

</style>
