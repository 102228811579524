<script setup>
import { ref, computed } from 'vue'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'

const blockchainsDialog = ref(null)
const selectedItem = ref(null)

const props = defineProps([
  'items',
])

const buttons = ref([
  [
    {
      ...DialogButtons.Cancel,
    },
  ],
  [
    {
      ...DialogButtons.Ok,
      title: 'Continue',
      variant: computed(() => {
        let result = 'primary'
        result += selectedItem.value ? '' : ' disabled'

        return result
      }),
    },
  ],
])

function itemClick (item) {
  selectedItem.value = item.value
}

async function show () {
  const dialogResult = await blockchainsDialog.value.show()

  return dialogResult === 'ok' ? selectedItem.value : undefined
}

defineExpose({ show })
</script>

<template>
  <Dialog
    title="Choose blockchain"
    ref="blockchainsDialog"
    :buttons="buttons"
    class="dlg-blockchains"
  >
    <div class="blockchain__items">
      <div
        v-for="item in props.items"
        :key="item.value"
        class="blockchain__item"
        @click="itemClick(item)"
        :class="item.value === selectedItem ? 'selected' : ''"
      >
        <div
          class="blockchain-item__icon"
          :style="`background: url('${item.icon}') no-repeat center center;`"></div>
      </div>
    </div>
  </Dialog>
</template>

<style scoped lang="scss">

.blockchain__items {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin: 11px 0 26px;
}

.blockchain__item {
  width: 121px;
  height: 103px;

  background: linear-gradient(158.84deg, rgba(255, 255, 255, 0.24) -52.29%, rgba(255, 255, 255, 0) 103.57%);
  filter: drop-shadow(-5.88385px 6.53761px 3.92256px rgba(0, 0, 0, 0.08));
  backdrop-filter: blur(14.7096px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  &.selected {
    outline: 2px solid #00D7FF;
  }
}

.blockchain-item__icon {
  height: 100%;
  width: 100%;
}
</style>
