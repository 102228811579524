import '@mdi/font/css/materialdesignicons.css'
import '@/styles/vuetifyOverrides.scss'
import { createVuetify } from 'vuetify'
import {
  VApp,
  VBtn,
  VTextField,
  VForm,
  VTabs,
  VTab,
  VWindow,
  VWindowItem,
  VIcon,
  VTextarea,
  VOverlay,
  VProgressCircular,
  VSlider,
  VCarousel,
  VCarouselItem,
  VRadio,
  VRadioGroup,
  VPagination,
} from 'vuetify/lib/components'

export default createVuetify({
  components: {
    VApp,
    VBtn,
    VTextField,
    VForm,
    VTabs,
    VTab,
    VWindow,
    VWindowItem,
    VIcon,
    VTextarea,
    VOverlay,
    VProgressCircular,
    VSlider,
    VCarousel,
    VCarouselItem,
    VRadio,
    VRadioGroup,
    VPagination,
  },
  theme: {
    defaultTheme: 'dark',
  },
})
