import { watch } from 'vue'
import { useElementSize } from '@vueuse/core'

export function useHtmlMinHeight (el, contentMinHeight = 500) {
  const { height } = useElementSize(el)

  watch(height, () => {
    const minHeight = contentMinHeight + height.value
    document.querySelector('html').style.minHeight = `${minHeight}px`
  })
}
