<script setup>
import { ref, computed } from 'vue'
import { copyToClipboard } from '@/utils/common'
import { notify } from '@kyvg/vue3-notification'

import { TextArea, Button } from '@/components/creator'

const props = defineProps(['code'])
const codeStr = computed(() => props.code)

const overlay = ref(null)

async function show () {
  overlay.value.style.display = 'flex'
}

function close () {
  overlay.value.style.display = 'none'
}

function copyCode () {
  copyToClipboard(codeStr.value)

  notify({
    type: 'success',
    text: 'Copied to clipboard. AR embed code',
  })
}

defineExpose({ show })
</script>

<template>
  <div class="g-dlg-overlay" ref="overlay" @click="close">
    <div class="g-dlg-wrapper wrapper" @click.stop="">
      <div class="title">Embed widget</div>

      <div class="g-dlg-close" @click="close"><v-icon>mdi-close</v-icon></div>

      <TextArea v-model="codeStr" type="text" label="" readonly />

      <Button primary class="btn-copy" @click="copyCode"> Copy code </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 374px;
  display: flex;
  flex-direction: column;
}

.title {
  font: normal 20px 'Poppins';
  text-align: center;
  color: #ffffff;
  margin: 14px 0;
}

.btn-copy {
  margin-top: 36px;
  align-self: center;
}
</style>
