<script setup>
import { computed } from 'vue'

const props = defineProps({
  src: String,
  poster: String,
  label: String,
  type: String,
})

function getFullUrl (url) {
  if (!url) return ''

  if (url.toLowerCase().match('https?://')) {
    return url
  } else {
    return `${process.env.VUE_APP_CONTENT_BASE_URL}/${url}`
  }
}
const urlSrc = computed(() => getFullUrl(props.src))
const urlPoster = computed(() => getFullUrl(props.poster))
</script>

<template>
  <div class="model-viewer-wrapper">
    <div class="g-section-title model-viewer__title" v-if="props.label">{{ props.label }}</div>
    <template v-if="['AR', 'AVATAR', 'GLB'].includes(type)">
      <model-viewer
        v-if="urlSrc"
        :src="urlSrc"
        :poster="urlPoster"
        camera-controls
        environment-image="neutral"
        v-bind="$attrs"
        autoplay
        class="content-3d"
      ></model-viewer>

      <img
        v-if="urlPoster && !urlSrc"
        class="model-image"
      />
    </template>
    <img v-if="['IMAGE'].includes(type)" :src="urlSrc" class="content-image" />
    <audio
      v-if="['SOUND'].includes(type)"
      :src="urlSrc"
      class="content-audio"
      controls
    />
    <video
      v-if="['VIDEO'].includes(type)"
      crossOrigin="anonymous"
      ref="videoPreview"
      :src="urlSrc"
      class="content-video"
      controls
      @canplay="videoPreviewCanPlay"
    />
  </div>
</template>

<style scoped lang="scss">
.model-viewer-wrapper,
.model-image,
.model-3d {
  width: 100%;
  height: 90%;
}

.model-viewer-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
}

.model-viewer__title {
  margin-bottom: 12px;
}

model-viewer {
  width: 100%;
  height: 100%;
  max-height: 430px;
  background: white;
}

.model-image {
  display: block;
}

.content-3d {
  align-self: stretch;
  flex: 1;
  border-radius: 16px;
  --poster-color: transparent;
}

.content-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.content-video {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>
