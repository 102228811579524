<script setup>
import { ref } from 'vue'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { LoadingIndicator } from '@/components/creator'
import { processError } from '@/helpers/errors'
import { BillingPeriodSwitcher } from '@/components/creator/billing_plans'

const dialog = ref(null)
const billingPeriodSwitcher = ref(null)

const buttons = ref([
  [
    DialogButtons.Cancel,
  ],
  [
    {
      ...DialogButtons.Ok,
      title: 'Subscribe',
      onClick: async () => { onSubscribe() },
    },
  ],
])

async function onSubscribe () {
  const result = await billingPeriodSwitcher.value.submit()
  if (result) {
    dialog.value.close(true)
  }
}

const show = async () => {
  try {
    return await dialog.value.show()
  } catch (error) {
    processError(error)
    dialog.value.close(false)
  }
}

defineExpose({ show })
</script>

<template>
  <Dialog
    title="Change billing interval"
    ref="dialog"
    :buttons="buttons"
    class="dlg-change-billing-period"
  >
    <LoadingIndicator loading :state="billingPeriodSwitcher?.state" v-if="billingPeriodSwitcher"/>

    <BillingPeriodSwitcher
      ref="billingPeriodSwitcher"
    />
  </Dialog>
</template>

<style lang="scss" scoped>
.billing-period-switcher {
  margin: 20px 0;
}

</style>
