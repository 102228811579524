<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { Editor, EditorContent } from '@tiptap/vue-3'

import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Italic from '@tiptap/extension-italic'
import Bold from '@tiptap/extension-bold'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import TextAlign from '@tiptap/extension-text-align'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  maxHeight: {
    type: String,
    default: '200px',
  },
})

const emit = defineEmits(['update:modelValue'])

const editor = ref(null)

watch(() => props.modelValue, (value) => {
  const isSame = editor.value.getHTML() === value

  if (isSame) {
    return
  }

  editor.value.commands.setContent(value, false)
})

onMounted(() => {
  editor.value = new Editor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Italic,
      Bold,
      Underline,
      Strike,
      TextAlign.configure({
        types: ['paragraph'],
      }),
    ],
    content: props.modelValue,
    onUpdate: () => {
      emit('update:modelValue', editor.value.getHTML())
    },
    injectCSS: false,
  })
})

onBeforeUnmount(() => {
  editor.value.destroy()
})

</script>

<template>
  <div class="rich-text-editor" v-if="editor">
    <div class="editor-title">
      <div class="editor-menu">
        <button
          class="menu-item menu-item-bold"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()">
          <v-icon class="menu-item-icon" :size="18">mdi-format-bold</v-icon>
        </button>

        <button
          class="menu-item menu-item-italic"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()">
          <v-icon class="menu-item-icon" :size="18">mdi-format-italic</v-icon>
        </button>

        <button
          class="menu-item menu-item-underline"
          :class="{ 'is-active': editor.isActive('underline') }"
          @click="editor.chain().focus().toggleUnderline().run()">
          <v-icon class="menu-item-icon" :size="18">mdi-format-underline</v-icon>
        </button>

        <button
          class="menu-item menu-item-strike"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().run()">
          <v-icon class="menu-item-icon" :size="18">mdi-format-strikethrough</v-icon>
        </button>

        <button
          class="menu-item menu-item-text-algn-left"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          @click="editor.chain().focus().setTextAlign('left').run()">
          <v-icon class="menu-item-icon" :size="18">mdi-format-align-left</v-icon>
        </button>

        <button
          class="menu-item menu-item-text-algn-center"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          @click="editor.chain().focus().setTextAlign('center').run()">
          <v-icon class="menu-item-icon" :size="18">mdi-format-align-center</v-icon>
        </button>

        <button
          class="menu-item menu-item-text-algn-right"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          @click="editor.chain().focus().setTextAlign('right').run()">
          <v-icon class="menu-item-icon" :size="18">mdi-format-align-right</v-icon>
        </button>

        <button
          class="menu-item menu-item-text-algn-justify"
          :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
          @click="editor.chain().focus().setTextAlign('justify').run()">
          <v-icon class="menu-item-icon" :size="18">mdi-format-align-justify</v-icon>
        </button>
      </div>
    </div>

    <div class="editor-wrapper" :class="{ 'is-filled': editor.state.doc.textContent.length > 0 }">
      <label for="#editorContent" class="label">
        {{ props.label }}
      </label>
      <editor-content
        id="editorContent"
        :editor="editor"
        class="editor-content"
        :style="{ maxHeight: props.maxHeight }"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>

.rich-text-editor {
}

.editor-content {
  border: 1px solid #D2D9E7;
  border-radius: 7px;
  resize: vertical;
  overflow: auto;
  outline: none;
  min-height: 84px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 12px;
}

.editor-content :deep(.ProseMirror) {
  outline: none;
  flex: 1;
}

.editor-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  color: #ACC2D2;
  opacity: 1;
  font: normal 14px Poppins;
  position: absolute;
  left: 10px;
  top: 10px;
  transition: all 0.2s ease;
}

.editor-wrapper:focus-within .label,
.editor-wrapper.is-filled .label{
  left: 0px;
  top: -29px;
  font: normal 12px Poppins;
}

.editor-content:focus-within {
  border-color: #00D7FF;
}

.editor-wrapper {
  position: relative;
}

.editor-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.menu-item {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  color: #ACC2D2;

  background-size: auto 12px;
  background-repeat: no-repeat;
  background-position: center;

  &.is-active {
    color: #00D7FF;
  }
}

.menu-item-icon {
  margin-top: -6px;
  margin-left: -2px;
}

</style>
