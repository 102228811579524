<template>
  <div class="container slider">
    <div ref="viewport" class="view">
      <div class="control left">
        <Button
          icon="prev"
          class="large transparent"
          @click="btnPrevClicked"
        ></Button>
      </div>
      <div class="slides">
        <slot></slot>
      </div>
      <div class="control right">
        <Button
          icon="next"
          class="large transparent"
          @click="btnNextClicked"
        ></Button>
      </div>
    </div>
    <div class="footer">
      <div class="title">{{ currentTitle }}</div>
      <div class="switcher">
        <div
          class="switcher-pin"
          v-for="index in slidesCount"
          :key="index"
          :class="{ active: index - 1 === current }"
          :title="slideTitle(index - 1)"
          @click="switcherPinClicked(index - 1)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@/components/common'

export default {
  name: 'Slider',
  data: function () {
    return {
      current: 0,
      vpWidth: 1000,
      slotsMutationObserver: null,
    }
  },
  computed: {
    slides: function () {
      return this.$slots.default()
    },
    slidesPosition: function () {
      return `${-this.current * this.vpWidth}px`
    },
    slideWidth: function () {
      return `${this.vpWidth}px`
    },
    slideHeight: function () {
      return '100%'
    },
    slidesCount: function () {
      return this.slides.length
    },
    currentTitle: function () {
      return this.slideTitle(this.current)
    },
  },
  methods: {
    slideTitle: function (index) {
      return (
        (this.slides[index] &&
          this.slides[index].props.data &&
          this.slides[index].props.data.title) ||
        ''
      )
    },
    btnPrevClicked: function () {
      this.current--
      if (this.current < 0) {
        this.current = this.slides.length - 1
      }
    },
    btnNextClicked: function () {
      this.current++
      if (this.current >= this.slidesCount) {
        this.current = 0
      }
    },
    switcherPinClicked: function (index) {
      this.current = index
    },
    handleResize: function () {
      this.vpWidth = this.$refs.viewport.clientWidth
    },
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },
  components: {
    Button, // eslint-disable-line vue/no-reserved-component-names
  },
}
</script>

<style scoped>
.container {
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
}

.slider .view {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  left: 0;
  top: 0;
  right: 0;
  bottom: 34px;
  flex-direction: row;
  overflow-x: hidden;
  border: 1px solid #b3bdd7;
  border-radius: 16px;
}
.slider .view > .control {
  position: absolute;
  width: 62px;
  height: 100%;
  z-index: 1;
  align-items: center;
  display: flex;
  justify-content: center;
}
.slider .view > .control.left {
  left: 0;
}
.slider .view > .control.right {
  right: 0;
}
.slider .slides {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: fit-content;
  transform: translateX(v-bind(slidesPosition));
  transition: 0.2s ease-in-out transform;
}
.slider .slides > * {
  height: 100%;
  width: 1000px;
}
.slider .footer {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  height: 34px;
  display: flex;
  flex-direction: row;
}
.slider .footer > .title {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: #131e28;
}
.slider .switcher {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 18px;
  z-index: 3;
  opacity: 0.5;
  transition: 0.2s ease-in-out opacity;
}
.slider .switcher:hover {
  opacity: 1;
}
.slider .switcher > .switcher-pin {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d2d9e7;
  margin: 0 0 0 5px;
  cursor: pointer;
}
.slider .switcher > .switcher-pin.active {
  background-color: #00d7ff;
}

::v-slotted(.slide) {
  width: v-bind(slideWidth);
  height: v-bind(slideHeight);
}

.control :deep(object.icon) {
  margin: 3px 0 0 0 !important;
}
</style>
