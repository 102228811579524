<script setup>
import { ref, onMounted } from 'vue'
import { Button, Checkbox, ModelViewer } from '@/components/creator'

const props = defineProps({
  initDefaultAvatarsDisabled: {
    type: Boolean,
    default: false,
  },
  editable: {
    type: Boolean,
    default: true,
  },
})

const defaultAvatarsDisabled = ref(false)
const defaultAvatarsSetPoster = location.protocol + '//' + location.host + require('@/images/default-avatars-set.png')

const emit = defineEmits(['close'])

onMounted(() => {
  defaultAvatarsDisabled.value = props.initDefaultAvatarsDisabled
})
</script>

<template>
  <div class="default-avatars-set-panel__wrapper">

    <div class="g-content__columns-wrapper g-shrink">
      <div class="g-content__column-first">
        <a
          href="#"
          @click.stop.prevent="emit('close', undefined)"
          class="link-back"
        >
          <v-icon>mdi-arrow-left</v-icon>
          Back to avatars
        </a>

        <div class="g-section-title title">EDIT AVATAR</div>

        <Checkbox
          title="Disable default avatars"
          v-bind:model-value="defaultAvatarsDisabled"
          @change="(e) => defaultAvatarsDisabled = e.target.checked"
          class="checkbox"
          :disabled="!editable"
        />

        <Button
          small
          primary
          @click="emit('close', defaultAvatarsDisabled)"
          class="button-save"
          :disabled="!editable"
        >
          Save
        </Button>
      </div>

      <div class="g-content__column-second">
        <div class="g-content-preview-wrapper">
          <ModelViewer
            :src="defaultAvatarsSetPoster"
            label="Preview"
            type="IMAGE"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">

.link-back {
  color: white !important;
  text-decoration: none !important;

  font: 400 12px 'Poppins';
}

.title {
  margin-top: 27px;
}

.button-save {
  align-self: flex-start;
  margin-top: 30px;
}

.checkbox {
  margin-top: 12px;
}
</style>
