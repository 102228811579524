<script setup>
import { ref } from 'vue'
import { TextInput, NftPropertyDialog } from '@/components/creator'
import { deepClone } from '@/utils/common'

const props = defineProps(['modelValue'])

const emit = defineEmits(['update:modelValue'])

const propertyDialog = ref(null)

async function addProperty () {
  const result = await propertyDialog.value.show()
  if (result) {
    const newList = deepClone(props.modelValue)
    newList.push(result.property)

    emit('update:modelValue', newList)
  }
}

async function editProperty (property) {
  const result = await propertyDialog.value.show(property)
  if (result) {
    const newList = deepClone(props.modelValue)
    newList.forEach((element) => {
      if (element.key === result.key) {
        Object.assign(element, result.property)
      }
    })

    emit('update:modelValue', newList)
  }
}

async function deleteProperty (propertyKey) {
  const newList = props.modelValue.filter((element) => element.key !== propertyKey)

  emit('update:modelValue', newList)
}

function onIconClick (iconName, propertyKey) {
  switch (iconName) {
    case 'mdi-trash-can-outline':
      deleteProperty(propertyKey)
      break

    case 'mdi-square-edit-outline':
      editProperty(props.modelValue.find((element) => element.key === propertyKey))
      break
  }
}

function getPreviewValueText (value) {
  const div = document.createElement('div')
  div.innerHTML = value
  const texts = []
  for (const childNode of div.childNodes) {
    const text = childNode.textContent || childNode.innerText || ''
    texts.push(text)
  }
  return texts.join(' ')
}

</script>

<template>
  <div class="nft-properties-list">
    <div class="property-wrapper" v-for="prop in modelValue" :key="prop.key">
      <TextInput
        :modelValue="getPreviewValueText(prop.value)"
        type="text"
        :label="prop.key"
        :icons="['mdi-trash-can-outline', 'mdi-square-edit-outline']"
        @iconClick="iconName => onIconClick(iconName, prop.key)"
        readonly
      />
    </div>

    <button class="btn-add" @click="addProperty">+ add property</button>

    <NftPropertyDialog ref="propertyDialog" />
  </div>
</template>

<style scoped lang="scss">
.btn-add {
  border: none;
  outline: none;
  background: none;
  font: normal 14px 'Poppins';
  color: #acc2d2;

  &:hover {
    color: #00D7FF;
  }
}

.property-wrapper :deep(.v-field__input) {
  padding-right: 56px;
}

</style>
