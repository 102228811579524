<script setup>
import { computed } from 'vue'
const props = defineProps({
  percent: Number,
  pattern: String,
  image: String,
})

const footerText = computed(() => {
  // {%}% Loading World
  return props.pattern.replace(/{%}/, props.percent)
})

</script>

<template>
  <div
    class="loading-screen-preview"
    :style="{ 'background-image': `url(${props.image})` }"
  >
    <div
      class="footer"
      :style="{ background: `linear-gradient(90deg, rgba(0, 0, 0, 0.85) ${props.percent}%, rgba(0, 0, 0, 0.051) 100%)` }"
    >
      <div class="footer__text">{{ footerText }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .loading-screen-preview {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 410px;
    background-size: auto 100%;
    background-position: center center;
  }

  .footer {
    display: flex;
    margin-top: auto;
    height: 30px;

    backdrop-filter: blur(10px);
  }

  .footer__text {
    margin: auto;
    font: 400 14px 'Poppins';
    text-transform: uppercase;
  }
</style>
