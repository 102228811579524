<script setup>
import _sortBy from 'lodash-es/sortBy'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { computed, ref, onMounted, watch } from 'vue'
import creatorItemTypes from '@/helpers/creatorItemTypes'
import creatorItemViews from '@/helpers/creatorItemViews'
import { LoadingIndicator, Pagination } from '@/components/creator'
import ItemsTable from '@/components/ItemsTable.vue'
import ItemsCards from '@/components/ItemsCards.vue'
import { getPreviewUrlByContentItem } from '@/utils/common'
import { processError } from '@/helpers/errors'

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const props = defineProps(['viewModeSwitcher'])

const store = useStore()
const { useActions } = useVuex(null, store)
const { apiContentItemsList } = useActions({
  apiContentItemsList: 'apiContentItemsList',
})

const rawData = ref([])

const pageNumber = ref(0)
const pageSize = 9
const pagesCount = ref(0)

const route = useRoute()

onMounted(async () => {
  pageNumber.value = 1
})

watch(() => route.query.search, async (newSearchQuery, oldSearchQuery) => {
  if (newSearchQuery !== oldSearchQuery) {
    pageNumber.value = 1
    await loadRawData()
  }
})

watch(() => route.query.type, async (newSearchQuery, oldSearchQuery) => {
  if (newSearchQuery !== oldSearchQuery) {
    await loadRawData()
  }
})

watch(() => pageNumber.value, async () => {
  await loadRawData()
})

async function loadRawData () {
  try {
    state.value.requestInProgress = true
    state.value.isLoading = true

    const searchQuery = route.query.search || ''
    const contentTypeQuery = route.query.type || ''
    const apiAnswer = await apiContentItemsList({ page: pageNumber.value, search: searchQuery, contentType: contentTypeQuery, pageSize })
    rawData.value = apiAnswer.content

    pagesCount.value = Math.ceil(apiAnswer.pagination.total / pageSize)

    for (const key in rawData.value) {
      const itemType = rawData.value[key].type.toLowerCase()
      rawData.value[key].typeIcon = creatorItemTypes[itemType]?.icon
      rawData.value[key].created_by = rawData.value[key].author.full_name
      rawData.value[key].assignedText = ''
      rawData.value[key].titleField = creatorItemTypes[itemType]?.title
    }
  } catch (error) {
    processError(error)
  } finally {
    state.value.isLoading = false
    state.value.requestInProgress = false
  }
}

const data = computed(() => {
  let data = rawData.value.slice()

  const exclude = ['ar']
  data = data.filter((item) => !exclude.includes(item.type.toLowerCase()))

  if (route.query.sort === 'name' || !route.query.sort) {
    data = _sortBy(data, (item) => item.name?.toLowerCase())
  } else if (route.query.sort === 'updated') {
    data = _sortBy(data, (item) => item.updated_at)
  } else if (route.query.sort === 'created') {
    data = _sortBy(data, (item) => item.author?.full_name?.toLowerCase())
  } else if (route.query.sort === 'assigned') {
    data = _sortBy(data, (item) => '')
  }
  if (route.query.order === 'desc') data.reverse()
  return data
})

const cardItems = computed(() => {
  const _items = []
  for (const item of data.value) {
    const previewSrc = item.media.glbmodel
      ? `${process.env.VUE_APP_CONTENT_BASE_URL}/${item.media.glbmodel}`
      : ''
    const previewPoster = getPreviewUrlByContentItem(item)
    const label = creatorItemTypes[item.type.toLowerCase()]?.title

    _items.push({
      id: item.id,
      title: item.name,
      routePath: 'StorageItem',
      routeParams: { id: item.id },
      link: item.media.link.href,
      previewPoster,
      previewSrc,
      label,
      contentType: item.type,
    })
  }
  return _items
})
</script>

<template>
  <div class="storage-items__wrapper">
    <template v-if="props.viewModeSwitcher?.isSelected(creatorItemViews.cards)">
      <LoadingIndicator loading :state="state" />
      <ItemsCards
          :items="cardItems"
          cardWidth="200px"
          cardHeight="254px"
          :createButton="{ title: 'Add content', name: 'StorageNew' }"
        />
      <Teleport to="#footer">
        <Pagination
          :length="pagesCount"
          v-model="pageNumber"
          v-if="pagesCount > 1"
        />
      </Teleport>
    </template>
    <template v-if="props.viewModeSwitcher?.isSelected(creatorItemViews.table)">
      <LoadingIndicator loading :state="state" />
      <ItemsTable
        :items="data"
        :columns="[
          {
            title: '',
            field: 'typeIcon',
            type: 'icon',
            width: '24px',
            options: { size: 24, titleField: 'titleField' },
          },
          {
            sortKey: 'name',
            title: 'Name',
            field: 'name',
            type: 'link',
            options: { routePath: 'StorageItem' },
          },
          {
            sortKey: 'updated',
            title: 'Last updated',
            field: 'updated_at',
            type: 'timestamp',
          },
          {
            sortKey: 'created_by',
            title: 'Created by',
            field: 'created_by',
          },
          {
            sortKey: 'assignedText',
            title: 'Assigned',
            field: 'assignedText',
          },
        ]"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
.storage-items__wrapper {
  min-height: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

</style>
