<script setup>
import { ref, onMounted, computed } from 'vue'

const globalNewSelectElement = ref(null)
const globalSelectValueElement = ref(null)
const newSelectHeadElement = ref(null)
const newOptionsElement = ref(null)

const props = defineProps(['modelValue', 'items', 'placeHolder', 'disabled'])

const emit = defineEmits(['update:modelValue'])

const isOpened = ref(false)
const currentItem = computed(() => {
  return props.items.find(x => x.value === props.modelValue)
})

function toggleOpen () {
  isOpened.value = !isOpened.value
}

function closeMenu () {
  isOpened.value = false
}

function addOutsideClickEvent () {
  document.body.addEventListener('click', (e) => {
    if (
      globalNewSelectElement.value &&
      !globalNewSelectElement.value.contains(e.target)

    ) {
      closeMenu()
    }
  })
}

function onClick (event) {
  closeMenu()

  const newItem = props.items[parseInt(event.target.getAttribute('data-index'))]

  emit('update:modelValue', newItem.value)
}

onMounted(() => {
  addOutsideClickEvent()

  if (props.modelValue) {
    // currentItem.value = props.items.find(x => x.value === props.modelValue)
  }
})
</script>

<template>
  <div
    class="custom-select-wrapper"
    ref="globalNewSelectElement"
    :class="{
      'custom-select-is-open': isOpened,
      'filled': currentItem,
      'disabled': props.disabled,
    }"
  >
    <div
      class="custom-select-head"
      ref="newSelectHeadElement"
      @click="toggleOpen"
    >
      <div
        class="custom-select-label"
        :class="{ 'custom-select-is-open': isOpened }"
      >
        {{ props.placeHolder }}
      </div>
      <div
        class="custom-select-value"
        ref="globalSelectValueElement"
        v-if="currentItem"
      >
        {{ currentItem ? currentItem.text : props.placeHolder }}
      </div>
      <v-icon class="custom-select-icon" size="28px">mdi-chevron-down</v-icon>
    </div>
    <div class="custom-select-options" ref="newOptionsElement">
      <div
        class="custom-select-option custom-select-option-selected"
        :data-index="key"
        v-for="(item, key) in props.items"
        :key="key"
        @click="onClick($event)"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.custom-select-wrapper {
  position: relative;
  box-sizing: border-box;
  padding-top: 20px;
  margin-bottom: 20px;
}

.custom-select-options {
  position: absolute;
  width: 100%;
  top: 100%;
  max-height: 0;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  z-index: 1;
  background: #091520;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 7px;
  border: 1px solid #334656;
  opacity: 0;
}

.custom-select-is-open .custom-select-options {
  max-height: 300px;
  overflow-y: auto;
  opacity: 1;
}

.custom-select-option {
  font: 400 14px 'Poppins';
  color: #ffffff;
  padding: 6px 11px;
  cursor: pointer;
  border-radius: 4px;
  margin: 9px 8px;
}

.custom-select-option:hover {
  background-color: #273846;
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.custom-select-option-selected {
  // background: #273846;
}

.custom-select-head {
  cursor: pointer;
  font: normal 14px 'Inter';
  border-bottom: 1px solid rgba(251, 251, 254, 0.38);
  transition: border-bottom 250ms cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.custom-select-head:hover {
  border-bottom-color: rgba(210, 217, 231, 1);
}

.disabled .custom-select-head {
  color: #acc2d2;
  pointer-events: none;
}

.custom-select-is-open .custom-select-head {
  // background-color: rgba(0, 0, 0, 0.1);
}

.custom-select-value {
  // color: #ACC2D2;
  padding: 5px 0;
}

.custom-select-label {
  transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1) all;
  color: #acc2d2;
  padding: 5px 0;
}

.filled .custom-select-label {
  top: 2px;
  position: absolute;
  padding: 0;
}

.custom-select-icon {
  margin-left: auto;
}
</style>
