<script setup>
import { ref } from 'vue'
import Dialog from '@/components/common/dialogs/Dialog'
import { LoadingIndicator } from '@/components/creator'
import { BillingPlanSwitcher } from '@/components/creator/billing_plans'
import { processError } from '@/helpers/errors'

const dialog = ref(null)
const billingPlanSwitcher = ref(null)

const show = async () => {
  try {
    return await dialog.value.show()
  } catch (error) {
    processError(error)
    dialog.value.close(false)
  }
}

defineExpose({ show })
</script>

<template>
  <Dialog
    title="Choose your plan"
    ref="dialog"
    :buttons="[]"
    class="dlg-change-billing-plan"
  >
    <LoadingIndicator loading :state="billingPlanSwitcher?.state" v-if="billingPlanSwitcher" />

    <BillingPlanSwitcher
      ref="billingPlanSwitcher"
      @switched="dialog.close('switched')"
    />
  </Dialog>
</template>

<style lang="scss" scoped>
.dlg-change-billing-plan > :deep(.ah-dialog) {
  overflow-y: scroll;
  max-height: 90%;
  min-width: 90%;
}
</style>
