<script setup>
const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const handleChange = (event) => {
  emit('update:modelValue', event.target.value)
}
</script>

<template>
  <v-radio-group
    class="radio-group"
    v-bind="$attrs"
    v-bind:model-value="props.modelValue"
    @change="handleChange"
    hide-details
  >
    <slot></slot>
  </v-radio-group>
</template>

<style scoped lang="scss">
:deep(.v-selection-control__input .v-icon) {
  font-size: 18px;
  color: #00D7FF;
}

:deep(.v-selection-control__input .v-ripple__container) {
  width: 30px;
  height: 30px;
}

:deep(.v-selection-control__input) {
  width: 30px;
  height: 30px;
}

:deep(.v-selection-control__wrapper) {
  width: 18px;
  height: 18px;
}

:deep(.v-label) {
  margin-left: 6px;
  font: 400 14px/17px 'Inter';
  color: #FFFFFF;
}
</style>
