<script setup>
import { computed } from 'vue'
import { Button } from '@/components/creator'
import { getPeriodByBillingInterval } from '@/utils/common'

const props = defineProps({
  billingPlan: {
    type: Object,
    required: true,
  },
  pricePeriod: {
    type: String,
    required: true,
  },
  isCurrentSubscription: {
    type: Boolean,
    required: true,
  },
})

const briefFeatures = computed(
  () => props.billingPlan.features.filter((feature) => feature.include_in_brief)
)

function getButtonText () {
  if (props.isCurrentSubscription) {
    return 'Your plan'
  } else if (props.billingPlan.price[props.pricePeriod.toLowerCase()] === -1) {
    return 'Contact sales'
  } else {
    return 'Choose'
  }
}

const emit = defineEmits(['choose'])

</script>

<template>
  <div class="billing-plan-card">
    <div class="title__wrapper">
      <h3 class="title">
        {{ props.billingPlan.name }}
      </h3>

      <div class="description">
        {{ props.billingPlan.description }}
      </div>
    </div>

    <div class="price__wrapper">
      <template v-if="props.billingPlan.price[props.pricePeriod.toLowerCase()] === -1">
        <span class="price__request">Upon request</span>
      </template>

      <template v-else-if="props.billingPlan.price[props.pricePeriod.toLowerCase()] === 0">
        <span class="price__free">Free</span>
      </template>

      <template v-else>
        <span class="price__currency">$</span><span class="price__value">{{ props.billingPlan.price[props.pricePeriod.toLowerCase()] }}</span> <span class="price__period">per {{ getPeriodByBillingInterval(props.pricePeriod) }}</span>
      </template>
    </div>

    <Button primary class="button-choose" @click="emit('choose')" :disabled="props.isCurrentSubscription">
      {{ getButtonText() }}
    </Button>

    <div class="features_splitter"></div>

    <ul class="features">
      <li v-for="item of briefFeatures" :key="item.feature.id" class="feature__item">
        <v-icon class="feature__icon" size="18">mdi-check</v-icon>
        <span class="feature__text">
          {{ item.feature.title }}
        </span>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.billing-plan-card {
  background: #182734;
  border: 1px solid #334656;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 22px 16px;
  width: 251px;
}

.features {
  list-style: none;
}

.feature__item {
  display: flex;
  flex-direction: row;
}

.title {
  font: 500 22px 'Poppins';
  text-align: center;
  letter-spacing: 0.002em;
  color: #FFFFFF;
}

.description {
  font: 400 14px/21px 'Poppins';
  text-align: center;
  color: #ACC2D2;
  margin-top: 14px;
}

.title__wrapper {
  height: 110px;
}

.price__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
}

.price__currency {
  font: 400 18px 'Poppins';
  color: #FFFFFF;
}

.price__value {
  font: 700 28px 'Poppins';
  color: #FFFFFF;
}

.price__period {
  font: 400 12px/18px 'Poppins';
  color: #ACC2D2;
  margin-left: 8px;
}

.price__request,
.price__free {
  font: 500 22px 'Poppins';
  color: #FFFFFF;
}

.button-choose {
  margin: 20px auto 0;
  display: block;
}

.features_splitter {
  border: 1px solid #E7EBF3;
  width: 100%;
  height: 1px;
  margin-top: 18px;
}

.feature__icon {
  color: #00D7FF;
}

.features {
  margin-top: 17px;
}

.feature__text {
  padding-left: 9px;
  font: 400 14px 'Poppins';
  color: #FFFFFF;
}

</style>
