<template>
  <div class="file-input-wrapper" :class="{ uploading: uploading, readonly: readonly }">
    <div
      class="control-wrapper"
      :class="{ invalid: !valid && edited && !uploading }"
      @dragover.prevent="dragover"
      @dragleave="dragleave"
      v-cloak
      @drop.prevent="dropInputChanged"
      ref="controlWrapper"
    >
      <input
        ref="fileInput"
        class="input-file"
        type="file"
        :id="fileInputId"
        v-bind:accept="accept"
        @input.prevent.stop="fileInputChanged"
      />
      <input
        :id="textInputId"
        ref="input"
        type="text"
        class="input-text"
        :title="textInputValue"
        :value="textInputValue"
        readonly
      />
      <label
        ref="placeholder"
        :for="textInputId"
        class="placeholder"
        :class="original_filename && !lock ? 'filled' : ''"
        v-cloak
      >
        {{ placeholder }}
      </label>

      <div class="buttons">
        <router-link v-if="lock" class="upgrade-link" :to="{ name: 'Profile', query: {tab: 'Billing'} }">Upgrade</router-link>

        <button
          class="btn btn-download"
          v-if="!uploading && filename && !lock"
          title="Download"
          @click.prevent="downloadClick"
        >
          <v-icon>mdi-tray-arrow-down</v-icon>
        </button>

        <button
          class="btn btn-upload"
          v-if="!uploading && !filename && !readonly && !lock"
          title="Upload"
          @click.prevent="uploadClick"
        >
          <v-icon>mdi-tray-arrow-up</v-icon>
        </button>

        <button
          class="btn btn-clear"
          v-if="!uploading && filename && !readonly && !lock"
          title="Clear"
          @click.prevent="clearClick"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </button>

        <v-icon class="icon-lock" v-if="lock">mdi-lock-outline</v-icon>
      </div>

      <div class="progress-bar-done" v-if="uploading"></div>
    </div>

    <div
      class="description"
      :class="{ invalid: !valid && edited }"
      v-if="!uploading"
    >
      {{ description }}
    </div>

    <div class="progress-wrapper" v-if="uploading">
      <div class="progress-value">{{ progress }}&nbsp;%</div>
      <div class="file-size">{{ humanFileSize(fileSize) }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import humanFileSize from '@/utils/formatBytes'

let uid = 0

const defaultValidator = {
  size: {
    min: 0,
    max: null,
  },
  picture: {
    height: {
      min: 0,
      max: null,
    },
    width: {
      min: 0,
      max: null,
    },
    ratio: null,
  },
}

export default {
  name: 'FileInput',
  props: {
    extensions: {
      default: () => {
        return []
      },
      type: Array,
    },
    modelValue: {
      default: '',
      type: String,
    },
    placeholder: {
      default: '',
      type: String,
    },
    description: {
      default: '',
      type: String,
    },
    required: {
      default: false,
      type: Boolean,
    },
    validator: {
      default: () => {
        return defaultValidator
      },
      type: Object,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    lock: {
      default: false,
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'update:valid'],
  data: function () {
    return {
      uid: `${this.$options.name}-${++uid}`,

      uploading: false,
      progress: 0,
      original_file: null,
      original_filename: null,
      fileSize: 0,

      validatorResult: true,
      edited: false,
    }
  },
  computed: {
    filename: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      },
    },
    accept: function () {
      return this.extensions.join(', ')
    },
    valid: function () {
      return !this.required || (!!this.filename && this.validatorResult)
    },
    fileInputId: function () {
      return `${this.uid}-file`
    },
    textInputId: function () {
      return `${this.uid}-input`
    },
    styleProgressWidth: function () {
      return `${this.progress}%`
    },
    textInputValue: function () {
      return this.lock ? '' : this.original_filename
    },
  },
  watch: {
    modelValue: function (newVal, oldVal) {
      if (newVal) {
        if (newVal !== oldVal) {
          this.reloadMetadata()
        }
      } else {
        this.original_file = null
        this.original_filename = null
        this.filename = null
      }
      this.$emit('update:valid', this.valid)
    },
  },
  methods: {
    ...mapActions([
      'apiContentItemsFilesGet',
      'apiContentItemsFilesPut',
      'apiContentItemsFilesPutToS3',
    ]),

    uploadClick: function () {
      this.$refs.fileInput.click()
    },

    downloadClick: function () {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.download = this.original_filename
      link.href = `${process.env.VUE_APP_CONTENT_BASE_URL}/${this.filename}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    clearClick: function (event = null) {
      this.$refs.fileInput.value = null
      this.fileInputChanged(event)
    },

    sendToS3: async function (fileToUpload) {
      if (!this.s3url) {
        return
      }

      const result = await this.apiContentItemsFilesPutToS3({
        url: this.s3url,
        file: fileToUpload,
        metadata: { original_filename: this.original_filename },
        progressCallback: this.uploadProgress,
      })

      this.uploading = false

      if (!result) {
        this.clearClick()
        return
      }

      this.filename = this.newfilename
    },

    upload: async function (fileToUpload) {
      this.original_file = fileToUpload
      this.original_filename = fileToUpload.name
      this.fileSize = fileToUpload.size
      this.uploading = true
      this.progress = 0
      const apiResult = await this.apiContentItemsFilesPut({
        original_filename: this.original_filename,
      })

      if (!apiResult.success) {
        this.clearClick()
        return
      }

      this.s3url = apiResult.content.url
      this.newfilename = apiResult.content.filename
      this.sendToS3(fileToUpload)
    },

    uploadProgress: function (event) {
      this.progress = Math.round((event.loaded / event.total) * 100)
    },

    checkExtension: function (file) {
      const extension = '.' + file.name.split('.').pop().toLowerCase()

      const index = this.extensions.findIndex(
        (item) => extension === item.toLowerCase()
      )

      return index !== -1
    },

    dragover: function () {
      this.$refs.controlWrapper.classList.add('ondrag')
    },

    dragleave: function () {
      this.$refs.controlWrapper.classList.remove('ondrag')
    },

    dropInputChanged: function (event) {
      this.$refs.controlWrapper.classList.remove('ondrag')

      const droppedFiles = event.dataTransfer.files
      if (!droppedFiles) return

      if (!this.checkExtension(droppedFiles[0])) return

      this.changed(droppedFiles[0])
    },

    fileInputChanged: function () {
      if (
        this.$refs.fileInput.value &&
        this.$refs.fileInput.files.length > 0 &&
        this.$refs.fileInput.files[0]
      ) {
        this.changed(this.$refs.fileInput.files[0])
      } else {
        this.changed(undefined)
      }
    },

    changed: async function (fileToUpload) {
      this.edited = true
      if (!fileToUpload) {
        this.original_file = null
        this.original_filename = null
        this.filename = null
        this.uploading = false
        return
      }

      if (!(await this.validate(fileToUpload))) {
        this.$notify({
          type: 'error',
          text: 'File is not valid',
        })

        this.original_file = null
        this.original_filename = null
        this.filename = null
        this.uploading = false
        return
      }

      this.upload(fileToUpload)
    },

    validate: async function (fileToUpload) {
      return new Promise((resolve) => {
        if (
          this.validator &&
          this.validator.size &&
          this.validator.size.min &&
          fileToUpload.size < this.validator.size.min
        ) {
          resolve(false)
          return
        }

        if (
          this.validator &&
          this.validator.size &&
          this.validator.size.max &&
          fileToUpload.size > this.validator.size.max
        ) {
          resolve(false)
          return
        }

        if (this.validator && this.validator.picture) {
          const img = document.createElement('img')
          img.src = URL.createObjectURL(fileToUpload)
          img.style.display = 'none'
          img.onerror = () => {
            resolve(false)
          }
          img.onload = () => {
            if (
              this.validator.picture.width &&
              this.validator.picture.width.min &&
              img.naturalWidth < this.validator.picture.width.min
            ) {
              resolve(false)
              return
            }

            if (
              this.validator.picture.width &&
              this.validator.picture.width.max &&
              img.naturalWidth > this.validator.picture.width.max
            ) {
              resolve(false)
              return
            }

            if (
              this.validator.picture.width &&
              this.validator.picture.width.equal &&
              img.naturalWidth !== this.validator.picture.width.equal
            ) {
              resolve(false)
              return
            }

            if (
              this.validator.picture.height &&
              this.validator.picture.height.min &&
              img.naturalHeight < this.validator.picture.height.min
            ) {
              resolve(false)
              return
            }

            if (
              this.validator.picture.height &&
              this.validator.picture.height.max &&
              img.naturalHeight > this.validator.picture.height.max
            ) {
              resolve(false)
              return
            }

            if (
              this.validator.picture.height &&
              this.validator.picture.height.equal &&
              img.naturalHeight !== this.validator.picture.height.equal
            ) {
              resolve(false)
              return
            }

            if (
              this.validator.picture.aspect &&
              Math.round((img.naturalWidth / img.naturalHeight) * 100, 2) !==
                Math.round(this.validator.picture.aspect * 100)
            ) {
              resolve(false)
              return
            }

            resolve(true)
          }
          document.body.appendChild(img)
          setTimeout(() => {
            document.body.removeChild(img)
          }, 3000)
        } else {
          resolve(true)
        }
      })
    },

    reloadMetadata: async function () {
      if (!this.filename) {
        return
      }

      const apiResult = await this.apiContentItemsFilesGet({
        filename: this.filename,
      })
      if (!apiResult.success) {
        this.clearClick()
        return
      }

      // this.original_file = null
      this.original_filename = apiResult.content.original_filename
    },
    humanFileSize: function (size) {
      return humanFileSize(size)
    },
  },
  mounted: function () {
    this.reloadMetadata()
  },
  components: {},
}
</script>

<style scoped lang="scss">
.file-input-wrapper {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 18px;
  // margin-top: 0;
}

.placeholder {
  position: absolute;
  left: 0;
  bottom: 2px;
  transition: 0.1s ease all;
  font: normal 14px Inter;
  color: #acc2d2;
  margin-bottom: 1px;
}

.file-input-wrapper.readonly .placeholder {
  color: #667F92;
}

.description {
  margin-top: 5px;
  font: normal 12px 'Inter';
  color: #acc2d2;
  height: 18px;
}

.file-input-wrapper.readonly .description {
  color: #667F92;
}

.invalid .placeholder {
  color: rgb(207, 102, 121);
}

.placeholder.filled {
  bottom: 100%;
  margin-bottom: 0px;
  font: normal 12px Inter;
}

.input-file {
  display: none;
}

.control-wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 4px;

  border-bottom: 1px solid rgba(210, 217, 231, 0.38);
  transition: border-bottom 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    border-bottom-color: rgba(210, 217, 231, 1);
  }

  &.invalid {
    border-bottom: 1px solid rgba(rgb(207, 102, 121), 0.38);
  }

  &.invalid:hover {
    border-bottom-color: rgba(rgb(207, 102, 121), 1);
  }
}

.uploading .control-wrapper {
  border-bottom: 2px solid rgba(210, 217, 231, 0.8);
}

.input-text {
  flex: 1;
  font: normal 14px Inter;
  outline: none;
  min-width: 0;
}

.input-text.ondrag {
  background-color: #e7ebf3;
}

.invalid .description {
  color: #eb4c5e;
}
.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.btn-download,
.btn-upload,
.btn-clear {
  margin-left: 3px;

  > i {
    font-size: 22px;
    color: #acc2d2;
  }

  &:hover > i {
    color: #00d7ff;
  }
}

.icon-lock {
  color: #acc2d2;
}

.ondrag {
  background: rgba(255, 255, 255, 0.1);
}

.progress-wrapper {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  height: 18px;

  font: normal 12px 'Inter';
  color: #909eaf;
}

.file-size {
  margin-left: auto;
}

.progress-bar-done {
  width: v-bind(styleProgressWidth);
  position: absolute;
  bottom: -2px;
  height: 2px;
  background: #00d7ff;
  left: 0;
}

.upgrade-link {
  font: 400 14px 'Inter';
  text-decoration: none;
  align-self: end;
  padding-right: 5px;
}
</style>
