import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import Notifications from '@kyvg/vue3-notification'
import VueMixpanel from 'vue-mixpanel'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'

import '@/styles/style.scss'
import '@/styles/notification.scss'

loadFonts()

const app = createApp(App)

app.config.errorHandler = function (err, vm, info) {
  console.error(err, vm, info)
}

const sentryParams = {
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_STAGE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_APP_API_URL],
    }),
  ],
  tracesSampleRate: 1.0,
}
if (process.env.VUE_APP_STAGE !== 'dev') {
  sentryParams.release = process.env.VUE_APP_VERSION
}
Sentry.init(sentryParams)

app.config.ignoredElements = [/model-viewer/]
app.config.compilerOptions.isCustomElement = tag => tag === 'model-viewer'

app.use(store)
app.use(router)
app.use(vuetify)
app.use(Notifications)
app.use(VueMixpanel, { token: process.env.VUE_APP_MIXPANEL_TOKEN, config: { debug: process.env.VUE_APP_STAGE === 'dev' } })
app.mount('#app')
