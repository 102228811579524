<script setup>
</script>

<template>
  <nav class="nav">
    <ul class="ul">
      <li class="li">
        <router-link
          class="link"
          exact-active-class="selected"
          :to="{ name: 'Worlds' }"
          >Worlds</router-link
        >
      </li>
      <!--
      <li class="li">
        <router-link class="link" exact-active-class="selected" :to="{ name: 'Collectibles' }">Collectibles</router-link>
      </li>
      -->
      <li class="li">
        <router-link
          class="link"
          exact-active-class="selected"
          :to="{ name: 'Storage' }"
          >Storage</router-link
        >
      </li>
      <li class="li">
        <router-link
          class="link"
          exact-active-class="selected"
          :to="{ name: 'ArList' }"
          >Augmented reality</router-link
        >
      </li>
    </ul>
  </nav>
</template>

<style scoped lang="scss">
.nav {
  .link {
    color: #fff;
    text-decoration: none;
  }
  .selected {
    color: #00d7ff;
  }
}
.ul {
  display: flex;
  list-style: none;
  margin-left: -20px;
  margin-right: -20px;
  gap: 20px;
}

.li {
  text-transform: uppercase;
  display: inline-block;
}
</style>
