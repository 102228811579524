<script setup>
import { isObject } from 'lodash-es'
import { onMounted, ref, watch, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const props = defineProps({
  modelValue: String,
  items: Array,
  ghost: Boolean,
  urlParam: String,
})

const router = useRouter()
const route = useRoute()

const tabRef = ref(null)

const itemsTabs = computed(() => {
  return props.items.map((item) => {
    if (isObject(item)) {
      return {
        value: item.value,
        icon: item.icon ? item.icon : '',
        // class: item.disabled ? 'disabled' : '',
        disabled: item.disabled,
      }
    } else {
      return {
        value: item,
      }
    }
  })
})

watch(() => props.modelValue, (newValue) => {
  tabRef.value = newValue
  if (props.urlParam) {
    const newQuery = { ...router.currentRoute.value.query }
    newQuery[props.urlParam] = newValue
    router.replace({
      query: newQuery,
    })
  }
})

function updateTabModelValue (newValue) {
  emit('update:modelValue', newValue)
}

onMounted(() => {
  if (props.urlParam &&
      route.query[props.urlParam] &&
      route.query[props.urlParam] !== props.modelValue) {
    emit('update:modelValue', route.query[props.urlParam])
  }
})

const emit = defineEmits(['update:modelValue'])

</script>

<template>
  <v-tabs
    v-bind:model-value="tabRef"
    @update:modelValue="updateTabModelValue"
    :hide-slider="ghost"
    :class="{ 'type-ghost': props.ghost }"
  >
    <v-tab
      v-for="item in itemsTabs"
      :key="item.value"
      :value="item.value"
      :append-icon="item.icon"
      :disabled="item.disabled"
    >
      {{ item.value }}
    </v-tab>
  </v-tabs>
  <slot></slot>
</template>

<style scoped lang="scss">
.v-btn {
  text-transform: none;
}

.v-btn.v-btn--density-default {
  height: auto;
}

.v-btn:disabled {
  opacity: 1;
}

:deep(.v-btn__content) {
  font: 600 14px/17px 'Inter';
  letter-spacing: normal;
  color: #00D7FF;
}

.v-btn:disabled :deep(.v-btn__content),
.v-btn:disabled :deep(.v-btn__append) {
  color: #ACC2D2;
}

.v-btn:disabled :deep(.v-btn__append) {
  margin-inline-start: 5px;

  > .v-icon {
    font-size: 16px;
  }
}

.v-tabs--align-with-title:not(.v-slide-group--has-affixes) .v-tab:first-child {
  margin-inline-start: 0;
}

.v-tabs {
  height: 45px;
}

.v-tab {
  min-width: 78px;
}

:deep(.v-tab__slider) {
  height: 4px;
}

.type-ghost {
  &.v-tabs {
    height: 26px;
    margin-bottom: 10px;
  }

  :deep(.v-tab) {
    padding: 0;
    justify-content: left;
    margin-right: 33px;

    .v-btn__content {
      font: 600 16px 'Inter';
      color: #909eaf;
      text-transform: uppercase;
    }
  }

  :deep(.v-tab.v-tab--selected .v-btn__content) {
    color: white;
  }
}

</style>
