<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { Checkbox } from '@/components/creator'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { downloadDataAsFile } from '@/utils/common'
import { processError } from '@/helpers/errors'

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiSpacesExport,
} = useActions([
  'apiSpacesExport',
])

const dialog = ref(null)

const state = ref('')

const props = defineProps({
  title: String,
  entityType: String,
  entity: Object,
})

const options = ref({})
const optionValues = ref({})

const dialogButtons = ref([
  [
    DialogButtons.Cancel,
    {
      ...DialogButtons.Ok,
      title: 'Export',
      onClick: () => { exportEntity() },
    },
  ],
])

const show = async () => {
  state.value = ''
  const result = await dialog.value.show()

  return (result === 'ok')
}

async function exportEntity () {
  try {
    state.value = 'exporting'

    if (props.entityType === 'space') {
      const result = await apiSpacesExport({
        id: props.entity.id,
        ...optionValues.value,
      })

      downloadDataAsFile(result.content, `${props.entity.name}.ahw`)
      dialog.value.close()
    }
    state.value = 'exported'
  } catch (error) {
    state.value = 'error'
    processError(error)
  }
}

onMounted(() => {
  if (props.entityType === 'space') {
    options.value = {
      includeContent: 'All the world content items',
    }
  }
})

defineExpose({ show })
</script>

<template>
  <Dialog
    :title="props.title || 'Select export option'"
    ref="dialog"
    :buttons="dialogButtons"
    class="export-dialog__wrapper"
  >
    <div class="options-wrapper">
      <Checkbox
        v-for="(title, key) of options"
        :key="key"
        :title="title"
        v-model="optionValues[key]"
      />
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>

.export-dialog__wrapper > :deep(.wrapper) {
  min-width: 339px;
}

.input-option {
  margin-bottom: 30px;
}

.progress-icon {
  margin: auto;
  display: block;
}

.progress-text {
  font: normal 10px 'Poppins';
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 16px;
  text-align: center;
  margin-bottom: 8px;
}

.options-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
</style>
