<script setup>
import { defineExpose, onMounted, ref } from 'vue'
import { loadStripe } from '@stripe/stripe-js'

let stripe
let elements

const isValid = ref(false)
const name = ref(null)
const address = ref(null)

const props = defineProps(['initName', 'initAddress'])

async function initStripeElements () {
  const appearance = {
    theme: 'night',

    variables: {

    },

    rules: {
      '.Input--invalid': {
        // color: '#00FF00',
      },
      '.Input': {
        // color: '#0000FF',
      },
    },
  }

  elements = stripe.elements({ appearance })

  // Create and mount the Payment Element
  const addressElement = elements.create('address', {
    mode: 'billing',
    defaultValues: {
      name: props.initName,
      address: props.initAddress ? props.initAddress : {},
    },
  })
  addressElement.mount('#address-element')

  addressElement.on('change', (event) => {
    isValid.value = event.complete
    name.value = event.value.name
    address.value = event.value.address
    emit('change', {
      valid: event.complete,
      name: event.value.name,
      address: event.value.address,
    })
  })
}

async function formSubmit () {

}

onMounted(async () => {
  stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)

  initStripeElements()
})

defineExpose({ formSubmit })

const emit = defineEmits(['change'])

</script>

<template>
  <div class="billing-details-form">
    <v-form
      id="payment-form"
      @submit.prevent="formSubmit"
    >
        <div id="address-element">
            <!-- Elements will create form elements here -->
        </div>

        <div id="error-message">
            <!-- Display error message to your customers here -->
        </div>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
</style>
