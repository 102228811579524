<script setup>
import { ref, computed } from 'vue'
import creatorItemTypes from '@/helpers/creatorItemTypes'
import Card from '@/components/creator/Card.vue'
import { getPreviewUrlByContentItem } from '@/utils/common'

const props = defineProps({
  items: Array,
})

const cardWidth = ref('198px')

const cardItems = computed(() => {
  const _items = []
  for (const item of props.items) {
    const previewSrc = item.contentItem.media.glbmodel
      ? `${process.env.VUE_APP_CONTENT_BASE_URL}/${item.contentItem.media.glbmodel}`
      : ''
    const previewPoster = getPreviewUrlByContentItem(item.contentItem)
    const label = creatorItemTypes[item.contentItem.type.toLowerCase()]?.title

    _items.push({
      id: item.id,
      title: item.name,
      routePath: item.routePath,
      routeParams: item.routeParams,
      routeQuery: item.routeQuery,
      link: item.contentItem.media.link.href,
      previewPoster,
      previewSrc,
      label,
      contentType: item.type,
    })
  }
  return _items
})
</script>

<template>
  <div class="content-item-cards">
    <template v-if="cardItems.length">
      <div v-for="item in cardItems" :key="item.id">
        <Card v-bind="item" :cardWidth="cardWidth" :cardHeight="`262px`">
        </Card>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.content-item-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, v-bind(cardWidth));
  justify-content: space-between;
  gap: 16px;
  overflow-y: auto;
}
</style>
