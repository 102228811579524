<script setup>
import _sortBy from 'lodash-es/sortBy'
import _findKey from 'lodash-es/findKey'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { computed, ref, onMounted, watch } from 'vue'
import creatorItemTypes from '@/helpers/creatorItemTypes'
import { LoadingIndicator, Pagination } from '@/components/creator'
import { getPreviewUrlByContentItem } from '@/utils/common'
import creatorItemViews from '@/helpers/creatorItemViews'
import ItemsTable from '@/components/ItemsTable.vue'
import ItemsCards from '@/components/ItemsCards.vue'
import { processError } from '@/helpers/errors'

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const props = defineProps(['viewModeSwitcher'])

const store = useStore()
const { useActions } = useVuex(null, store)
const { apiContentItemsListByIds, apiArList } = useActions({
  apiContentItemsListByIds: 'apiContentItemsListByIds',
  apiArList: 'apiArList',
})

const rawData = ref([])

const pageNumber = ref(0)
const pageSize = 9
const pagesCount = ref(0)

const route = useRoute()

const profileId = computed(() => store.state.auth.profile.id)

watch(() => route.query.search, async (newSearchQuery, oldSearchQuery) => {
  if (profileId.value) {
    pageNumber.value = 1
    rawData.value = await loadData(profileId.value)
  }
})

watch(() => pageNumber.value, async () => {
  if (profileId.value) {
    rawData.value = await loadData(profileId.value)
  }
})

async function loadData (profileId) {
  const result = []

  try {
    state.value.requestInProgress = true
    state.value.isLoading = true

    const searchQuery = route.query.search || ''
    const apiArListResponse = await apiArList({ page: pageNumber.value, search: searchQuery, pageSize })
    const projectsList = apiArListResponse.content
    pagesCount.value = Math.ceil(apiArListResponse.pagination.total / pageSize)

    const projectsListWithContent = projectsList.filter(p => p.content.length > 0)

    if (!projectsListWithContent.length) return []

    const contentIds = projectsListWithContent.map(p => p.content[0].content_item.id)

    const contentList = (await apiContentItemsListByIds({ ids: contentIds })).content

    for (const project of projectsListWithContent) {
      if (!project.content.length) continue

      const firstProjectContentId = project.content[0].content_item.id
      const contentItem = contentList.filter(
        (item) => item.id === firstProjectContentId
      )[0]

      if (!contentItem) {
        continue
      }

      result.push({
        id: project.id,
        title: project.name,
        routePath: 'ArItem',
        routeParams: { id: project.id },
        link: `${process.env.VUE_APP_APP_URL}/${project.id}`,
        updated_at: project.updated_at,
        author: project.author?.full_name,
        previewPoster: getPreviewUrlByContentItem(contentItem),
        previewSrc: contentItem.media.glbmodel
          ? `${process.env.VUE_APP_CONTENT_BASE_URL}/${contentItem.media.glbmodel}`
          : '',
        contentType: contentItem.type,
        assigned: '',
      })
    }

    return result
  } catch (error) {
    processError(error)
  } finally {
    state.value.isLoading = false
    state.value.requestInProgress = false
  }
}

const data = computed(() => {
  let data = rawData.value.slice()
  if (route.query.sort === 'title' || !route.query.sort) {
    data = _sortBy(data, (item) => item.title?.toLowerCase())
  } else if (route.query.sort === 'updated_at') {
    data = _sortBy(data, (item) => item.updated_at)
  } else if (route.query.sort === 'author') {
    data = _sortBy(data, (item) => item.author.toLowerCase())
  } else if (route.query.sort === 'assigned') {
    data = _sortBy(data, (item) => item.assigned)
  }
  if (
    route.query.type &&
    route.query.type !== creatorItemTypes.all.queryParam
  ) {
    const key = _findKey(creatorItemTypes, { queryParam: route.query.type })
    const type = key.toUpperCase()
    data = data.filter((item) => item.type === type)
  }
  if (route.query.order === 'desc') data.reverse()
  return data
})

onMounted(async () => {
  pageNumber.value = 1
})
</script>

<template>
  <div class="ar-items__wrapper">
    <template v-if="props.viewModeSwitcher?.isSelected(creatorItemViews.cards)">
      <LoadingIndicator loading :state="state" />
      <ItemsCards
        class="ar-items-cards"
        :items="data"
        cardWidth="200px"
        cardHeight="254px"
        :createButton="{ title: 'Add AR content', name: 'ArNew' }"
      />
      <Teleport to="#footer">
        <Pagination
          :length="pagesCount"
          v-model="pageNumber"
          v-if="pagesCount > 1"
        />
      </Teleport>
    </template>
    <template v-if="props.viewModeSwitcher?.isSelected(creatorItemViews.table)">
      <LoadingIndicator loading :state="state" />
      <ItemsTable
        :items="data"
        :columns="[
          {
            sortKey: 'title',
            title: 'Name',
            field: 'title',
            type: 'link',
            options: { routePath: 'ArItem' },
          },
          {
            sortKey: 'updated_at',
            title: 'Last updated',
            field: 'updated_at',
            type: 'timestamp',
          },
          {
            sortKey: 'author',
            title: 'Created by',
            field: 'author',
          },
          {
            sortKey: 'assigned',
            title: 'Assigned',
            field: 'assigned',
          },
        ]"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
.ar-items__wrapper {
  min-height: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

</style>
