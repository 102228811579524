<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { useNotification } from '@kyvg/vue3-notification'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { deepClone, apiCallWithState } from '@/utils/common'
import { processError } from '@/helpers/errors'
import MetadataInfo from '@/components/creator/nft/ContractMetadataInfo.vue'

import {
  Radio,
  RadioGroup,
  TextInput,
  Select,
  LoadingIndicator,
} from '@/components/creator'

const props = defineProps({
  space: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(['update:modelValue', 'update:space'])
const { notify } = useNotification()
const dialog = ref(null)
const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiCollectionListDataURLGet,
  apiBlockchainsGet,
  apiNFTCollectionMetadataGet,
} = useActions([
  'apiCollectionListDataURLGet',
  'apiBlockchainsGet',
  'apiNFTCollectionMetadataGet',
])

const selectedTab = ref('url')
const collectionUrl = ref('')
const state = ref({
  requestInProgress: false,
  isLoading: false,
})
const blockchains = ref([])
const originalAcl = ref(null)
const acl = ref({
  contract: {
    address: '',
    blockchain: {
      id: '',
      name: '',
    },
    network_name: '',
  },
})
const originalContractMetadata = ref(null)
const contractMetadata = ref(null)

const tabs = ref([
  { id: 'url', title: 'Collection url' },
  { id: 'address', title: 'Smart contract address' },
])

const searchRequired = ref(false)

const loadMetadata = async (acl, metadata) => {
  const requestData = {
    blockchainId: acl.contract.blockchain.id,
    networkName: acl.contract.network_name,
    address: acl.contract.address,
  }

  try {
    metadata.value = deepClone(acl)
    const apiResponse = await apiCallWithState(apiNFTCollectionMetadataGet, requestData, state, 'isLoading')
    if (!apiResponse || !apiResponse.content) {
      notify({ type: 'warn', text: 'Could not get collection metadata' })
      return
    }

    metadata.value = apiResponse.content
  } catch (error) {
    console.error('Failed to load metadata:', error)
    notify({ type: 'warn', text: 'Failed to load metadata' })
  }
}

const searchCollection = async () => {
  try {
    const requestData = { tokenUrl: collectionUrl.value }
    const apiResponse = await apiCallWithState(apiCollectionListDataURLGet, requestData, state, 'isLoading')

    acl.value.contract = deepClone(apiResponse.content.contract)
    contractMetadata.value = apiResponse.content
  } catch (error) {
    processError(error)
    contractMetadata.value = null
  } finally {
    searchRequired.value = false
  }
}

const fetchContractData = async () => {
  try {
    const requestDataMetadata = {
      blockchainId: acl.value.contract.blockchain.id,
      networkName: acl.value.contract.network_name,
      address: acl.value.contract.address,
    }
    const apiResponse = await apiCallWithState(apiNFTCollectionMetadataGet, requestDataMetadata, state, 'isLoading')

    acl.value.contract = deepClone(apiResponse.content.contract)
    contractMetadata.value = apiResponse.content
  } catch (error) {
    processError(error)
    contractMetadata.value = null
  } finally {
    searchRequired.value = false
  }
}

const aclModified = computed(() => {
  return (
    acl.value?.contract?.address !== originalAcl.value?.contract?.address ||
    acl.value?.contract?.blockchain?.id !== originalAcl.value?.contract?.blockchain?.id ||
    acl.value?.contract?.network_name !== originalAcl.value?.contract?.network_name
  )
})

const doSearch = async () => {
  if (selectedTab.value === 'url') searchCollection()
  else fetchContractData()
}

const buttonSearch = {
  title: 'Search',
  variant: 'primary',
  onClick: doSearch,
}

const dialogButtons = computed(() => {
  const buttons = [[]]

  if (!searchRequired.value && aclModified.value && contractMetadata.value) buttons[0].push(DialogButtons.Save)
  if (searchRequired.value) buttons[0].push(buttonSearch)

  return buttons
})

const inputIcons = computed(() => (collectionUrl.value ? ['mdi-close-box'] : []))

const blockchainItems = computed(() => blockchains.value ? blockchains.value.map(blockchain => ({ value: blockchain.id, text: blockchain.title })) : [])

const blockchainNetworks = computed(() => {
  if (!acl.value?.contract?.blockchain?.id) return []
  const blockchainIdx = blockchains.value.findIndex(blockchain => blockchain.id === acl.value.contract.blockchain.id)
  return blockchains.value[blockchainIdx].networks.map(network => ({ value: network.name, text: network.title }))
})

const onIconClick = () => {
  collectionUrl.value = ''
  searchRequired.value = false

  resetMetadata()
}
const collectionUrlChanged = () => {
  searchRequired.value = collectionUrl.value.trim() !== ''
  if (!searchRequired.value) resetMetadata()
}

const collectionUrlKeyPress = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault()
    event.stopPropagation()
    if (searchRequired.value) searchCollection()
  }
}

const resetMetadata = () => {
  acl.value = deepClone(originalAcl.value)
  contractMetadata.value = deepClone(originalContractMetadata.value)
}

const loadBlockchains = async () => {
  const apiResponse = await apiCallWithState(apiBlockchainsGet, null, state, 'isLoading')
  blockchains.value = apiResponse.content
}

defineExpose({
  show: async (someVal) => {
    if (props.space.acl?.length > 0) {
      originalAcl.value = deepClone(props.space.acl[0])
      acl.value = deepClone(props.space.acl[0])

      searchRequired.value = false
      collectionUrl.value = ''

      loadMetadata(acl.value, contractMetadata)
      loadMetadata(originalAcl.value, originalContractMetadata)
    } else {
      acl.value = {
        contract: {
          address: '',
          blockchain: {
            id: '',
            name: '',
          },
          network_name: '',
        },
      }
      originalAcl.value = deepClone(acl.value)
    }

    const result = await dialog.value.show()
    if (result === 'save') emit('update:space', { ...props.space, acl: [acl.value] })
  },
})

onMounted(async () => {
  loadBlockchains()
})
</script>

<template>
  <Dialog
    title="Private Access for specific NFT holders"
    ref="dialog"
    :buttons="dialogButtons"
    class="dlg-profile-provide-access"
  >
    <div class="selector-label">Choose option</div>
    <div class="wrapper">
      <RadioGroup :inline="true" v-model="selectedTab">
        <div>
          <Radio
            v-for="tab in tabs"
            :key="tab.id"
            :value="tab.id"
            class="radio-group__item"
          >
            <template v-slot:label>
              <div class="option-item">
                <span>{{ tab.title }}</span>
              </div>
            </template>
          </Radio>
        </div>
      </RadioGroup>

      <div v-if="selectedTab === 'url'">
        <TextInput
          v-model="collectionUrl"
          type="text"
          label="Enter the collection url"
          :icons="inputIcons"
          @iconClick="onIconClick"
          @input="collectionUrlChanged"
          @keypress="collectionUrlKeyPress"
        />
        <LoadingIndicator :state="state" statusMessage="" all />
        <MetadataInfo v-if="contractMetadata?.contract?.address" :contractMetadata="contractMetadata" />
      </div>

      <div v-else-if="selectedTab === 'address'">
        <TextInput
          v-model="acl.contract.address"
          type="text"
          label="Enter smart contract address"
          @input="searchRequired = true"
        />
        <Select
          place-holder="Blockchain"
          :items="blockchainItems"
          v-model="acl.contract.blockchain.id"
          @update:modelValue="searchRequired = true"
        />
        <LoadingIndicator :state="state" all />
        <Select
          place-holder="Network"
          :items="blockchainNetworks"
          v-model="acl.contract.network_name"
          :disabled="!acl.contract.blockchain.id"
          @update:modelValue="searchRequired = true"
        />
        <MetadataInfo v-if="contractMetadata?.contract?.address" :contractMetadata="contractMetadata" />
      </div>
    </div>
  </Dialog>
</template>

<style scoped lang="scss">
.wrapper {
  width: 374px;
  margin-bottom: 10px;
}
.radio-group__item {
  padding-right: 20px;
}
.g-dlg-close {
  z-index: 10;
}
.selector-label {
  font: normal 14px Inter;
  margin-bottom: 10px;
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* Adjust spacing between rows */
  font: normal 12px Inter;
}
.key {
  white-space: nowrap;
  /* padding-right: 10px; */
  flex-shrink: 0; /* Prevent the key from shrinking */
}
.value {
  max-width: 40%; /* Adjust based on your layout needs */
  min-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.dots {
  flex-grow: 1; /* Allows the dots to grow, pushing the value to the right */
  flex-shrink: 1;
  position: relative;
  margin: 0 8px;
}

.dots::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-image: repeating-linear-gradient(
    to right,
    #fff 0,
    #fff 1px,
    transparent 1px,
    transparent 6px
  );
  /* Adjust the spacing between dots by changing the last value */
}
</style>
