<script setup>
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { useRoute } from 'vue-router'
import { ref, onMounted, computed } from 'vue'
import ItemsTable from '@/components/ItemsTable.vue'
import { LoadingIndicator } from '@/components/creator'
import { searchData } from '@/helpers/tables'
import _sortBy from 'lodash-es/sortBy'
import { processError } from '@/helpers/errors'

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const store = useStore()
const { useActions } = useVuex(null, store)
const { apiUsersList } = useActions([
  'apiUsersList',
])

const route = useRoute()

const dataRaw = ref([])
const data = computed(() => {
  let data = dataRaw.value.slice()

  if (route.query.search) {
    data = searchData(data, ['full_name', 'email'], route.query.search)
  }
  if (route.query.sort === 'full_name' || !route.query.sort) {
    data = _sortBy(data, (item) => item.full_name)
  } else if (route.query.sort === 'email') {
    data = _sortBy(data, (item) => item.email)
  } else if (route.query.sort === 'role') {
    data = _sortBy(data, (item) => item.role?.toLowerCase())
  } else if (route.query.sort === 'updated_at') {
    data = _sortBy(data, (item) => item.updated_at)
  }

  if (route.query.order === 'desc') data.reverse()

  return data
})

async function loadUsersList () {
  try {
    state.value.requestInProgress = true
    state.value.isLoading = true

    dataRaw.value = (await apiUsersList()).content
  } catch (error) {
    processError(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isLoading = false
  }
}

onMounted(async () => {
  await loadUsersList()
})
</script>

<template>
  <LoadingIndicator loading :state="state" />
  <ItemsTable
    :items="data"
    :columns="[
      {
        sortKey: 'full_name',
        title: 'Full name',
        field: 'full_name',
        type: 'link',
        options: { routePath: 'UsersItem' },
      },
      {
        sortKey: 'email',
        title: 'Email',
        field: 'email',
      },
      {
        sortKey: 'role',
        title: 'Role',
        field: 'role',
      },
      {
        sortKey: 'updated_at',
        title: 'Last updated',
        field: 'updated_at',
        type: 'timestamp',
      }
    ]"
  />
</template>

<style scoped lang="scss">
</style>
