<script setup>
const logo = require('@/images/logo.svg')
</script>

<template>
  <router-link class="link" :to="{ name: 'Creator' }">
    <img class="img" :src="logo" alt="W3rlds" />
  </router-link>
</template>

<style scoped lang="scss">
.link,
.img {
  width: 110px;
  height: 24px;
  display: block;
}
</style>
